import { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";

import 'react-circular-progressbar/dist/styles.css';

export default function AnyListChecklists({ company, setTab, setIsWorkflowReport, setCompanyDashboardTab, }) {

	const dispatch = useDispatch();
	const navigation = useNavigate();

	const user = useSelector(state => state.user);
	const modal = useSelector(state => state.modal);

	let [isEdit, setIsEdit] = useState(false);
	let [isShown, setIsShow] = useState(true);

	useEffect(() => {
		let anylistHowTo = localStorage.getItem('anylistHowTo');
		if (!anylistHowTo) setIsShow(false);
	}, [])

	const showPercent = (value, type) => {
		let color;
		if (value > 0) {
			if (type == 'Module Risk') color = value > 67 ? 'red' : value > 33 ? 'yellow' : 'green';
			else if (type == 'Module Complete') color = value > 67 ? 'green' : value > 33 ? 'yellow' : 'red';
		}
		else color = 'c28'
		return <h4 className={`${color} fs2rem lh1 mr2rem`}>{value || 0}%<br /><span className={`${value > 0 ? 'c15 ' : 'c28 '} fs0-75rem w4rem db fw300`}>{type}</span></h4>
	};

	const navigateSection = async (tab, sectionNo) => {
		await setTab(tab);
		setTimeout(() => {
			let element = document.getElementById(sectionNo);
			if (element) element.scrollIntoView({ behavior: 'instant' }, true);
		}, 1000);
	};

	const checklistModuleTooltip = (sections) => {
		return (
			<div className={`cp module-tooltip-box`}>
				<div className='hover-list'><img src={require('../../../../images/hover-list-icon.png')} className="pa" alt="list-img" /></div>
				{(sections && sections.length > 0) &&
					<span className="tooltip-area">
						{sections.map((section, idx) => {
							let completeness = section?.['stats']?.['completionPercent'] ? section?.['stats']?.['completionPercent'] : 0;

							let riskColour;
							if (section?.['stats']?.['riskPercent'] < 38) riskColour = 7;
							else if (section?.['stats']?.['riskPercent'] > 62) riskColour = 9;
							else riskColour = 8;

							let hexColour = riskColour == 8 ? "#ddb64044" : riskColour == 9 ? "#dd404044" : "#40dda144";

							return <button type='button' key={idx} onClick={async () => { await navigateSection(section['moduleName'], section['sectionNo']); setCompanyDashboardTab('Main Dashboard') }} style={{ background: `linear-gradient(90deg, ${hexColour} 0%, ${hexColour} ${completeness}%, #f0f0f8 ${completeness}%)` }} data-scroll-to={section['sectionNo']} className="overlay-box tal w100 tac cp c3-hover"><span>{section['sectionName']}</span></button>
						})}
					</span>
				}
			</div>
		)
	};

	const setModuleTab = async (module) => {
		if (module['isActive']) {
			await setTab(module?.['type']);
			setCompanyDashboardTab('Main Dashboard');
		}
	};

	const setChecklitsReportTab = async (moduleName, type) => {
		if (type == 'workflow-report') await setIsWorkflowReport(true);
		await setTab(`${moduleName}-report`);
		setCompanyDashboardTab('Main Dashboard');
	};

	const editChecklistModule = async (module) => window.open(`/createChecklist?companyId=${company['_id']};cName=${company['cName']}#${module?.['_id']}`, '_blank');

	const checklistModules = (modules) => {
		return <>{modules.length > 0
			? Object.values(modules).map(module => {
				let showRisk = true;
				if (module?.['riskScoring'] == false) showRisk = false;
				else showRisk = true;

				return <div className={`bo7 w25 keyfeature-wihte-item bg1 new-shadow br15px p2rem mx1rem my1-5rem pr bsbb ${module?.['createdBy']?.['role'] == 'Advisor' && ' advisor-bg'} ${module?.['createdBy']?.['role'] == 'Investor' && ' investor-bg'}`}>
					<div className='right-info-hyper-icon right-info-dashboard-area'>
						<div className='info-icon cp pr ml0-5rem'>
							<svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
								<circle cx="13.5" cy="13.5" r="13.5" transform="matrix(1 0 0 -1 0 27)" fill="#59B8FD" />
								<path d="M12.1581 11.0172C12.1652 10.5566 12.5413 10.1875 13.0019 10.1875C13.4624 10.1875 13.8386 10.5602 13.8456 11.0172L14.1269 18.5969C14.1445 18.8852 14.039 19.1664 13.8351 19.3844C13.6171 19.6164 13.3148 19.75 13.0019 19.75C12.689 19.75 12.3867 19.6164 12.1687 19.3844C11.9648 19.1664 11.8593 18.8852 11.8769 18.5969L12.1581 11.0172Z" fill="white" />
								<path d="M13 6.25C13.6213 6.25 14.125 6.75368 14.125 7.375C14.125 7.99632 13.6213 8.5 13 8.5C12.3787 8.5 11.875 7.99632 11.875 7.375C11.875 6.75368 12.3787 6.25 13 6.25Z" fill="white" />
							</svg>
							<div className='shadow overlay-box' >
								<p className='pl1rem py0-5rem fs1rem c15 fw400 tti'>{module['moduleDescription'] || module?.['sections']?.[0]?.['moduleDescription']}</p>
							</div>
						</div>
					</div>
					{(module['isActive'] && module?.['sections']) && checklistModuleTooltip(module['sections'])}
					<span className={`tooltip-area style-two pa df aic jcsb w90 ${!module['isActive'] && ' op0-5'}`}>
						<div className="percentage-area df jcc aic">
							{showPercent(module?.['stats']['completionPercent'], 'Module Complete')}
							{showRisk && showPercent(module?.['stats']['riskPercent'], 'Module Risk')}
						</div>
						<span className={`df bg1 br50px bw1px bss fs0-75rem px1rem py0-5rem mb1rem bsbb aic ${module['isActive'] ? 'bo3 c3' : 'bo12 c12'}`}>{module['isActive'] ? 'Activated' : 'Deactivated'}</span>
					</span>
					<div className={`df w100 ${!module['isActive'] && ' op0-5'}`}>
						<Link to={module['isActive'] && `#${module?.['type']}`} onClick={async () => setModuleTab(module)} className='df aic w100'>
							<i className='icon-diligenstia mr1rem'><img className="br5px w50px" src={module?.['imageURL'] || require(`../../../../images/checklist-logo.png`)} alt="" /></i>
							<span className={`fs1rem fw500 bg1 c15 lh2 title-box-feature tal ${module['isActive'] && ' cp c4-hover'}`} style={{ background: 'transparent' }}>{module['type']}</span>
						</Link>
					</div>
					<div className='report-bottom-area'>
						{/* Module Report */}
						<Link to={(module?.['stats'].completionPercent > 0 && module['isActive']) && `#${module['type']}-report`} onClick={() => { (module?.['stats'].completionPercent > 0 && module['isActive']) && setChecklitsReportTab(module['type'], 'module-report') }} className={`w2rem h2rem df jcc mr0-5rem aic br100 c1 report-btn pr ${(module?.['stats'].completionPercent > 0 && module['isActive']) ? ' bg3 bg4-hover' : ' bg12'}`}>R
							<div className='overlay-box'>
								<p className='c15 df aic'>
									Module Report
									<i className='icon ml0-5rem'>
										<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M11.1659 7.57457V10.6723C11.1659 12.3866 9.77122 13.7812 8.05696 13.7812H6.37657C5.23568 13.7812 4.25418 13.2147 3.68373 12.2268L1.77282 8.91715C1.58918 8.5978 1.69863 8.18893 2.01661 8.00543C2.73387 7.59183 3.61212 7.81624 4.0161 8.51635L4.50071 9.35517V4.7025C4.50071 4.15516 4.94565 3.70986 5.49252 3.70986C6.0394 3.70986 6.48516 4.15516 6.48516 4.7025V6.49204C6.65337 6.36068 6.86477 6.28236 7.09416 6.28236C7.42275 6.28236 7.71453 6.44282 7.8953 6.68954C8.07593 6.44282 8.36747 6.28236 8.69579 6.28236C9.10548 6.28236 9.45802 6.53185 9.6094 6.88688C9.7748 6.7358 9.9947 6.6436 10.2357 6.6436C10.7486 6.6436 11.1659 7.06125 11.1659 7.57457ZM5.90232 2.44038V0.628906C5.90232 0.402391 5.71868 0.21875 5.49217 0.21875C5.26565 0.21875 5.08201 0.402391 5.08201 0.628906V2.44035C5.08201 2.66686 5.26565 2.8505 5.49217 2.8505C5.71868 2.8505 5.90232 2.66689 5.90232 2.44038ZM3.46769 4.87501C3.46769 4.64849 3.28405 4.46485 3.05753 4.46485H1.24609C1.01958 4.46485 0.835938 4.64849 0.835938 4.87501C0.835938 5.10152 1.01958 5.28516 1.24609 5.28516H3.05753C3.28405 5.28516 3.46769 5.10152 3.46769 4.87501ZM10.1492 4.87501C10.1492 4.64849 9.96558 4.46485 9.73906 4.46485H7.92759C7.70108 4.46485 7.51744 4.64849 7.51744 4.87501C7.51744 5.10152 7.70108 5.28516 7.92759 5.28516H9.73906C9.96558 5.28516 10.1492 5.10152 10.1492 4.87501ZM4.23261 3.61648C4.39287 3.45639 4.39298 3.19668 4.23288 3.03641L3.45079 2.25354C3.2907 2.0933 3.03098 2.09316 2.87072 2.25326C2.71046 2.41336 2.71035 2.67307 2.87045 2.83333L3.65254 3.61621C3.73265 3.69638 3.83765 3.7365 3.94271 3.7365C4.04776 3.7365 4.15254 3.69646 4.23261 3.61648ZM7.3318 3.61621L8.11389 2.83333C8.27398 2.67307 8.27385 2.41336 8.11361 2.25326C7.95335 2.09314 7.69364 2.0933 7.53354 2.25354L6.75146 3.03641C6.59136 3.19668 6.5915 3.45639 6.75173 3.61648C6.83182 3.69649 6.93671 3.73647 7.04163 3.73647C7.14655 3.73647 7.25171 3.69641 7.3318 3.61621Z" fill="#505050" />
										</svg>
									</i>
								</p>
							</div>
						</Link>
						{/* Edit Module */}
						{module?.['createdBy']?.['_id'] == user['_id'] &&
							<button className='cp w2rem h2rem df jcc mr0-5rem aic br100 c1 report-btn pr bg3 bg4-hover' onClick={() => editChecklistModule(module)}>
								<i class="c1 fa fa-edit" style={{ fontSize: '16px' }} />
								<div className='overlay-box'>
									<p className='c15 df aic'>
										Edit Checklist
										<i className='icon ml0-5rem'>
											<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M11.1659 7.57457V10.6723C11.1659 12.3866 9.77122 13.7812 8.05696 13.7812H6.37657C5.23568 13.7812 4.25418 13.2147 3.68373 12.2268L1.77282 8.91715C1.58918 8.5978 1.69863 8.18893 2.01661 8.00543C2.73387 7.59183 3.61212 7.81624 4.0161 8.51635L4.50071 9.35517V4.7025C4.50071 4.15516 4.94565 3.70986 5.49252 3.70986C6.0394 3.70986 6.48516 4.15516 6.48516 4.7025V6.49204C6.65337 6.36068 6.86477 6.28236 7.09416 6.28236C7.42275 6.28236 7.71453 6.44282 7.8953 6.68954C8.07593 6.44282 8.36747 6.28236 8.69579 6.28236C9.10548 6.28236 9.45802 6.53185 9.6094 6.88688C9.7748 6.7358 9.9947 6.6436 10.2357 6.6436C10.7486 6.6436 11.1659 7.06125 11.1659 7.57457ZM5.90232 2.44038V0.628906C5.90232 0.402391 5.71868 0.21875 5.49217 0.21875C5.26565 0.21875 5.08201 0.402391 5.08201 0.628906V2.44035C5.08201 2.66686 5.26565 2.8505 5.49217 2.8505C5.71868 2.8505 5.90232 2.66689 5.90232 2.44038ZM3.46769 4.87501C3.46769 4.64849 3.28405 4.46485 3.05753 4.46485H1.24609C1.01958 4.46485 0.835938 4.64849 0.835938 4.87501C0.835938 5.10152 1.01958 5.28516 1.24609 5.28516H3.05753C3.28405 5.28516 3.46769 5.10152 3.46769 4.87501ZM10.1492 4.87501C10.1492 4.64849 9.96558 4.46485 9.73906 4.46485H7.92759C7.70108 4.46485 7.51744 4.64849 7.51744 4.87501C7.51744 5.10152 7.70108 5.28516 7.92759 5.28516H9.73906C9.96558 5.28516 10.1492 5.10152 10.1492 4.87501ZM4.23261 3.61648C4.39287 3.45639 4.39298 3.19668 4.23288 3.03641L3.45079 2.25354C3.2907 2.0933 3.03098 2.09316 2.87072 2.25326C2.71046 2.41336 2.71035 2.67307 2.87045 2.83333L3.65254 3.61621C3.73265 3.69638 3.83765 3.7365 3.94271 3.7365C4.04776 3.7365 4.15254 3.69646 4.23261 3.61648ZM7.3318 3.61621L8.11389 2.83333C8.27398 2.67307 8.27385 2.41336 8.11361 2.25326C7.95335 2.09314 7.69364 2.0933 7.53354 2.25354L6.75146 3.03641C6.59136 3.19668 6.5915 3.45639 6.75173 3.61648C6.83182 3.69649 6.93671 3.73647 7.04163 3.73647C7.14655 3.73647 7.25171 3.69641 7.3318 3.61621Z" fill="#505050" />
											</svg>
										</i>
									</p>
								</div>
							</button>}
						{/* Workflow Report */}
						<Link to={(module?.['stats'].completionPercent > 0 && module['isActive']) && `#${module['type']}`} onClick={() => { (module?.['stats'].completionPercent > 0 && module['isActive']) && setChecklitsReportTab(module['type'], 'workflow-report') }} className={`w2rem h2rem df jcc aic br100 c1 report-btn pr ${(module?.['stats'].completionPercent > 0 && module['isActive']) ? ' bg3 bg4-hover' : ' bg12'}`}>WF
							<div className='overlay-box'>
								<p className='c15 df aic'>
									Workflow Report
									<i className='icon ml0-5rem'>
										<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M11.1659 7.57457V10.6723C11.1659 12.3866 9.77122 13.7812 8.05696 13.7812H6.37657C5.23568 13.7812 4.25418 13.2147 3.68373 12.2268L1.77282 8.91715C1.58918 8.5978 1.69863 8.18893 2.01661 8.00543C2.73387 7.59183 3.61212 7.81624 4.0161 8.51635L4.50071 9.35517V4.7025C4.50071 4.15516 4.94565 3.70986 5.49252 3.70986C6.0394 3.70986 6.48516 4.15516 6.48516 4.7025V6.49204C6.65337 6.36068 6.86477 6.28236 7.09416 6.28236C7.42275 6.28236 7.71453 6.44282 7.8953 6.68954C8.07593 6.44282 8.36747 6.28236 8.69579 6.28236C9.10548 6.28236 9.45802 6.53185 9.6094 6.88688C9.7748 6.7358 9.9947 6.6436 10.2357 6.6436C10.7486 6.6436 11.1659 7.06125 11.1659 7.57457ZM5.90232 2.44038V0.628906C5.90232 0.402391 5.71868 0.21875 5.49217 0.21875C5.26565 0.21875 5.08201 0.402391 5.08201 0.628906V2.44035C5.08201 2.66686 5.26565 2.8505 5.49217 2.8505C5.71868 2.8505 5.90232 2.66689 5.90232 2.44038ZM3.46769 4.87501C3.46769 4.64849 3.28405 4.46485 3.05753 4.46485H1.24609C1.01958 4.46485 0.835938 4.64849 0.835938 4.87501C0.835938 5.10152 1.01958 5.28516 1.24609 5.28516H3.05753C3.28405 5.28516 3.46769 5.10152 3.46769 4.87501ZM10.1492 4.87501C10.1492 4.64849 9.96558 4.46485 9.73906 4.46485H7.92759C7.70108 4.46485 7.51744 4.64849 7.51744 4.87501C7.51744 5.10152 7.70108 5.28516 7.92759 5.28516H9.73906C9.96558 5.28516 10.1492 5.10152 10.1492 4.87501ZM4.23261 3.61648C4.39287 3.45639 4.39298 3.19668 4.23288 3.03641L3.45079 2.25354C3.2907 2.0933 3.03098 2.09316 2.87072 2.25326C2.71046 2.41336 2.71035 2.67307 2.87045 2.83333L3.65254 3.61621C3.73265 3.69638 3.83765 3.7365 3.94271 3.7365C4.04776 3.7365 4.15254 3.69646 4.23261 3.61648ZM7.3318 3.61621L8.11389 2.83333C8.27398 2.67307 8.27385 2.41336 8.11361 2.25326C7.95335 2.09314 7.69364 2.0933 7.53354 2.25354L6.75146 3.03641C6.59136 3.19668 6.5915 3.45639 6.75173 3.61648C6.83182 3.69649 6.93671 3.73647 7.04163 3.73647C7.14655 3.73647 7.25171 3.69641 7.3318 3.61621Z" fill="#505050" />
										</svg>
									</i>
								</p>
							</div>
						</Link>
					</div>
				</div>
			})
			: <p class="w100 tac fs2rem">No Checklist Created!</p>
		}</>
	};

	return (
		<>{isShown
			? <div className='sectionWrapper py1rem fs0-75rem pr anylist-builder-tab'>
				<a href={`/createChecklist?companyId=${company['_id']};cName=${company['cName']}`} target='_blank' className="df cp bg1 br50px mr0-5rem bw2px bss pr1rem p0-5rem bsbb aic mr1rem cp add-btn-dashboard pa t0 r0 audit-log-btn" style={{ width: '206px', marginBottom: '1rem' }}>
					<span className='bg1 bsbb mr0-5rem br100 w40px h40px tac df jcc aic'>
						<svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
							<g clip-path="url(#clip0_3623_3962)">
								<path d="M19 0C8.52298 0 0 8.52298 0 19C0 29.477 8.52298 38 19 38C29.477 38 38 29.477 38 19C38 8.52298 29.477 0 19 0Z" fill="#4A67FF" />
								<path d="M27.3098 20.5805H20.5805V27.3098C20.5805 28.1839 19.8714 28.893 18.9973 28.893C18.1232 28.893 17.4141 28.1839 17.4141 27.3098V20.5805H10.6848C9.8107 20.5805 9.10156 19.8714 9.10156 18.9973C9.10156 18.1232 9.8107 17.4141 10.6848 17.4141H17.4141V10.6848C17.4141 9.8107 18.1232 9.10156 18.9973 9.10156C19.8714 9.10156 20.5805 9.8107 20.5805 10.6848V17.4141H27.3098C28.1839 17.4141 28.893 18.1232 28.893 18.9973C28.893 19.8714 28.1839 20.5805 27.3098 20.5805Z" fill="#FAFAFA" />
							</g>
							<defs>
								<clipPath id="clip0_3623_3962">
									<rect width="38" height="38" fill="white" />
								</clipPath>
							</defs>
						</svg>
					</span>
					<span className='c15'>Add/Edit Checklist</span>
				</a>
				<div className='mt1rem bss px1rem py2rem br25px w100 keyfeature-box-portfolio anylist-checklist df fww mb3rem pr bsbb'>
					<h3 className={`fs1-25rem c15 w100 pl2rem ${!isEdit && ' mb1rem'}`}>AnyList Checklists</h3>
					{/* Create Checklist Button */}
					<a href={`/createChecklist?companyId=${company['_id']};cName=${company['cName']}`} target='_blank' className="cp bo7 w25 keyfeature-wihte-item bg1 new-shadow br15px p2rem mx1rem my1-5rem pr bsbb" style={{ backgroundColor: '#4A67FF', borderColor: '#4A67FF50' }}>
						<div className='df aic'>
							<i className="fa fa-plus-circle cp mr2rem c1" style={{ fontSize: '48px' }} />
							<h3 className='c1 fs1-25rem fw400'>Create New Checklist Module</h3>
						</div>
					</a>
					{/* Checklist Modules */}
					{(company?.['modules'] && Object.values(company['modules']).length > 0) && checklistModules(Object.values(company['modules']).filter(item => (item?.['category'] == "internal" && !item['isHealthBoost'])))}
				</div>
			</div>
			: <div className='sectionWrapper p1rem fs0-75rem pr anylist-builder-tab mt2rem'>
				<div className='bss p2rem br25px w100 keyfeature-box keyfeature-box-portfolio anylist-checklist df fww mb3rem pr bsbb'>
					<h2 class="w100 tac c15 fs1-75rem fw500">How does it work?</h2>
					<h6 class="w100 tac c15 fs1-25rem fw500 mb1rem"><span class="c3">AnyList Checklist Builder</span></h6>
					<div className='step-top-box df w100 jcc'>
						<img src={require('../../../../images/step-modal-img.png')} className="w60" style={{ objectFit: 'fill' }} />
					</div>
					<div className='step-box-images df jcsb mt1rem mb2rem mla mra'>
						<div className='step-img df jcc px1rem'>
							<img src={require('../../../../images/company-dashboard/anylist-checklist-step-1.png')} alt="ste image" />
						</div>
						<div className='step-img df jcc px1rem'>
							<img src={require('../../../../images/company-dashboard/anylist-checklist-step-2.png')} alt="ste image" />
						</div>
						<div className='step-img df jcc px1rem'>
							<img src={require('../../../../images/company-dashboard/anylist-checklist-step-3.png')} alt="ste image" />
						</div>
					</div>
					<div className='checklist-area aic df bg1 py0-5rem w70 mla mra br45px mb1rem jcsb bsbb'>
						<div className='checklist-inner w100 pr df aic jcsb'>
							<div className='checklist-box w20 pr'><i className='icon pa'><img className='w1rem' src={require('../../../../images/advisor-dashboard/check-icon.png')} alt='' /></i><span className='c12 pl1-5rem db'>Quick and easy for you</span></div>
							<div className='checklist-box w20 pr'><i className='icon pa'><img className='w1rem' src={require('../../../../images/advisor-dashboard/check-icon.png')} alt='' /></i><span className='c12 pl1-5rem db'>Quick and easy for your target user</span></div>
							<div className='checklist-box w20 pr'><i className='icon pa'><img className='w1rem' src={require('../../../../images/advisor-dashboard/cross-icon.png')} alt='' /></i><span className='c12 pl1-5rem db'>Reusable by the target company</span></div>
							<div className='checklist-box w30 pr'><i className='icon pa'><img className='w1rem' src={require('../../../../images/advisor-dashboard/cross-icon.png')} alt='' /></i><span className='c12 pl1-5rem db'>Company gains value from completion - risks & recommendations</span></div>
						</div>
					</div>
					<div className='df jcc w100 mt1rem'>
						<button onClick={() => { setIsShow(true), localStorage.setItem('anylistHowTo', true) }} className='cp tac td250 dashboard-btn c1 bg4 bg3-hover br30px fs0-75rem fw500 mr0-5rem w110 td270 w200px p1rem ml2rem df aic jcc'>Next</button>
					</div>
				</div>
			</div>
		}
		</>
	)
}
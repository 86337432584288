import React from 'react'
import ModalWrapper from '../../common/Modal/ModalWrapper'
import KeyFeatureCard from '../../card/KeyFeatureCard'
import InvestorLeadMatching from '../../landingPage/homePage/InvestorLeadMatching'
import { useDispatch } from 'react-redux'
import { toggleModal } from '../../../reducers/modalReducer'

const OpenSeeMoreLandingPageModal = ({ data, handleClose }) => {
    const dispatch = useDispatch()
    return (
        <>
            <ModalWrapper
                modalType={'openSeeMore'}
                modalCardClasses={'maw1200px'}
                onCloseHandler={handleClose}
            >
                {
                    !!data && Object.keys(data).length ?
                        <div className="keyfeature-box-appstore mt2rem br20px p2rem shadow pr">
                            <div>
                                <h1 className="fs1-25rem c56 w100 pl1rem">{data?.heading}</h1>
                                <p className="subs-btn pl1rem c56 df aic fs1rem mb1rem">{data?.subHeading}</p>
                            </div>
                            <div className="px1rem">
                                {
                                    data.modules && Object.keys(data.modules).length ? <>
                                        {
                                            data['modulesTitle'] && <h2 className="c59 mt2rem mb1rem">{data['modulesTitle']}</h2>
                                        }
                                        <div className="dg gtcr3 gg1-5rem sm-gtcr1">
                                            {Object.values(data['modules']).map((item, ind) => {
                                                return <div className="pr">
                                                    <KeyFeatureCard key={`landing-Page-carding-${ind}`} item={item} />
                                                    {
                                                        item['isClarence'] && <div className='pa' style={{
                                                            top: "0px",
                                                            right: "-204px",
                                                            zIndex: 0,
                                                            maxWidth: '216px'
                                                        }}>
                                                            <img
                                                                src={require('../../../../images/landingpage/datarepoClearance.png')}
                                                                alt='clarenceLogo'
                                                                style={{ maxHeight: "180px" }}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            })}
                                        </div>
                                    </> : null
                                }

                                {
                                    data['proPlusAppStore'] && Object.keys(data['proPlusAppStore']).length ? <>
                                        {
                                            data['proPlusAppStoreTitle'] && <h2 className="c59 mt2rem mb1rem">{data['proPlusAppStoreTitle']}</h2>
                                        }
                                        <div className="dg gtcr3 gg1-5rem sm-gtcr1">
                                            {Object.values(data['proPlusAppStore']).map((item, ind) => {
                                                return <KeyFeatureCard key={`landing-Page-card-${ind}`} item={item} />
                                            })}
                                        </div>
                                    </> : null
                                }

                                {
                                    data['cardContent'] && Object.keys(data['cardContent']).length ?
                                        <div className='df w100 smfdrr bg1 pt3rem pb2rem px4rem bsbb br20px' >
                                            <div style={{ width: "52%", height: '100%', paddingRight: "3%" }}>
                                                <div className='df w100 mb2rem aic'>
                                                    <div style={{ maxWidth: "50px" }} className="df aic">
                                                        {data['cardContent']?.icon}
                                                    </div>
                                                    <div className="w90 fw500 c15 pl1rem df aic" style={{ fontSize: '26px', lineHeight: '37.32px' }}>
                                                        {data['cardContent']?.title}
                                                    </div>
                                                </div>
                                                {/* custom-list */}
                                                <ul class="mt0-5rem pl2rem">
                                                    {data['cardContent']?.points?.map((objpoints, index) => {
                                                        return (
                                                            <li
                                                                style={{ lineHeight: '24px', paddingTop: "12px", paddingLeft: "20px", borderLeft: '2px solid #D3D9FC', ...data['cardContent']?.liStyle }}
                                                                className="df fdc c14 fs1-25rem fw400 w80"
                                                                key={`${objpoints}-${index}`}
                                                            >
                                                                <div className="pr">
                                                                    <div className="circle"></div>
                                                                    {objpoints}
                                                                </div>
                                                                <div>{data['cardContent']?.subMenu && data['cardContent']?.subMenu?.index === index &&
                                                                    data['cardContent']?.subMenu?.points?.map((str, i, arr) => {
                                                                        return (
                                                                            <div className="fs1rem c14 fs1rem fw400 df aic pl2rem"
                                                                                style={{ lineHeight: '30px' }}>{str}</div>
                                                                        )
                                                                    })
                                                                }
                                                                </div>
                                                            </li>
                                                        )
                                                    })}
                                                </ul >
                                                {data['cardContent']?.notePoint &&
                                                    <div className="mt1rem c15 op80" style={{ border: '1px solid gray', padding: "10px", borderRadius: '10px' }}>{data['cardContent']?.notePoint}</div>
                                                }
                                            </div>
                                            <div className="df aic jce" style={{ width: '45%' }}>
                                                <img src={data['cardContent']?.image} className="w100" style={{ objectFit: 'fill' }} />
                                            </div>
                                        </div>
                                        : null
                                }

                                {
                                    data['investerLeadMatchingCard'] ? <InvestorLeadMatching /> : null
                                }
                            </div>

                            <div className="df jcc aic mt3rem mb1rem">
                                <div
                                    onClick={() => dispatch(toggleModal({ open: "welcome2MinuteTest", historyData: null }))}
                                    className={`w100 fs1rem bg4 br35px pr cp c1 arrowBtnAnimation df jcsb aic py0-5rem px1-5rem bsbb my0-5rem xlmra`}
                                    style={{ border: '1px solid #FC7785', maxWidth: '350px' }}>
                                    <div className="df fdc aifs jcfs">
                                        <p className="asfs">Free 2-Minute Test</p>
                                        <p className="fs12px fw400 asfs">No registration or credit card required</p>
                                    </div>
                                    <svg width='13' height='13' viewBox='0 0 21 18' fill='none' xmlns='http:www.w3.org/2000/svg' style={{ marginLeft: '10px', stroke: '#fff' }}>
                                        <path d='M1.39612 10.331H15.818L11.43 14.7191C10.1696 15.9795 12.0602 17.87 13.3206 16.6096L17.3169 12.6071L19.9847 9.93519C20.5017 9.41507 20.5017 8.57507 19.9847 8.05496L13.3206 1.38311C13.0671 1.12258 12.7181 0.976392 12.3545 0.979416C11.1542 0.979567 10.5652 2.4415 11.43 3.2738L15.8284 7.66187H1.32711C-0.521408 7.75359 -0.383387 10.4231 1.39612 10.331Z' fill='#fff' />
                                    </svg>
                                </div>
                            </div>

                            {
                                (!data['cardContent'] || !data['hideIntelligentsia']) ? <div className='pa sm-pr intelligentsiaLandingPage' style={{ bottom: "-8px", right: "-36px", zIndex: 1, maxWidth: "350px" }}>
                                    <img
                                        src={require('../../../../images/landingpage/intelligentsia.png')}
                                        alt='clarenceLogo'
                                        style={{ maxWidth: "350px" }}
                                    />
                                </div> : null
                            }

                        </div> : null
                }
            </ModalWrapper>

        </>
    )
}

export default OpenSeeMoreLandingPageModal
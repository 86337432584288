import React, { useState } from 'react'

// third-parties
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
// Components
import LeadAllocation from '../../../../components/revenueLeadAllocation/LeadAllocation'
import LeadMatricReport from '../../../../components/revenueLeadAllocation/LeadMatricReport';

const RevenueLeadsAllocation = ({ profileClass, companies, advisors, getAuditLogs, setRefresh, getAdvisors, user }) => {

    const [step, setStep] = useState("modules")

    return (
        <>
            <TabContext value={step}>
                <TabList
                    onChange={(e, value) => setStep(value)}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        "& .MuiTabs-flexContainer": {
                            justifyContent: "center",
                        },
                    }}
                    style={{ visibility: user?.role ? "visible" : "hidden" }}>
                    <Tab className="fs1rem fw600 mt1rem mb1rem" label={`Modules`} value="modules" />
                    <Tab className="fs1rem fw600 mt1rem mb1rem" label={`Lead Matrics Report`} value="reports" />
                </TabList>
                <TabPanel className="w100" value="modules">
                    <LeadAllocation profileClass={profileClass} companies={companies} advisors={advisors} getAuditLogs={getAuditLogs} setRefresh={setRefresh} getAdvisors={getAdvisors} />
                </TabPanel >
                <TabPanel className="w100" value="reports">
                    <LeadMatricReport profileClass={profileClass} companies={companies} advisors={advisors} getAuditLogs={getAuditLogs} setRefresh={setRefresh} getAdvisors={getAdvisors} />
                </TabPanel >
            </TabContext>

        </>
    )
}

export default RevenueLeadsAllocation

import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { setToken } from '../config';
import * as ROUTES from '../constants/routes';
import { setLoginType } from '../reducers/loginReducer';
import { getRequest, postRequest, showMessage } from '../config';
import { toggleModal } from '../reducers/modalReducer';
import AnimatedPage from '../components/AnimatedPage';


const Login = () => {

    const dispatch = useDispatch();
    const navigation = useNavigate();
    const modal = useSelector((state) => state.modal);

    const [searchParams, setSearchParams] = useSearchParams();
    const isBillingsearch = searchParams.get("billing");

    let [isLogin, setIsLogin] = useState(false);
    let [isLoading, setIsLoading] = useState(false);
    let [operation, setOperation] = useState('login');
    let [passwordType, setPasswordType] = useState(true);
    let [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
    let [isValidNewPassword, setIsValidNewPassword] = useState(false);
    let [formData, setFormData] = useState({ email: '', password: '' });
    let [newPassword, setNewPassword] = useState({ password: '', confirmPassword: '' });
    let [passwordError, setPasswordError] = useState({ password: '', confirmPassword: '' });
    let [newPasswordType, setNewPasswordType] = useState({ passwordType: true, confirmPasswordType: true });

    const PASSWORD_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/;

    useEffect(() => {
        window.scroll(0, 0);
        if (location.pathname.includes('reset_password')) setOperation('setPassword');
    }, []);

    useEffect(() => resetStates(), [operation]);
    useEffect(() => {
        if (formData['email'] !== '' && formData['password'] !== '') setIsLogin(true);
    }, [formData]);

    const handleOperation = (link) => {
        if (link == 'refresh') location.pathname = '/login';
        else setOperation(link);
    };

    const resetStates = () => {
        setIsLogin(false);
        setIsLoading(false);
        setPasswordType(true);
        setForgotPasswordEmail('');
        setFormData({ email: '', password: '' });
        setNewPassword({ password: '', confirmPassword: '' });
        setNewPasswordType({ passwordType: true, confirmPasswordType: true });
    };

    const restrictedForWeb = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
        const isMobile = mobileRegex.test(userAgent)
        return isMobile
    }

    const login = async (e) => {
        if (restrictedForWeb()) {
            return showMessage('You can Login/Register on Laptop')
        }

        if (formData['email'] && formData['password']) {
            setIsLoading(true);
            const response = await postRequest('/user/auth', formData);
            if (response && response['code'] == 200) {
                if (response['body']['token']) {
                    showMessage(response['message']);
                    loginWithToken(response['body']);
                    dispatch(toggleModal({ open: '' }));
                } else if (response['body']['hash']) {
                    let data = response['body'];
                    dispatch(setLoginType({ smsEnabled: data.smsEnabled, twoFaEnabled: data.twoFaEnabled, emailEnabled: data.emailEnabled, hash: data.hash, isBillingRoute: isBillingsearch }));
                }
                resetStates();
            } else setIsLoading(false);
        } else showMessage('Enter your email and password');
    };

    const loginWithToken = async (data) => {
        for (const key of ['token'])    //role
            document.cookie = `${key}=${data[key]};path=/`;

        setToken(data['token']);
        localStorage.setItem('token', data['token']);
        localStorage.removeItem('anylistHowTo');
        if (data['role'] == 'Advisor') navigation(ADVISOR);
        else if (data['role'] == 'Admin') setTimeout(() => navigation('/admin'), 100);
        else if (data['role'] == 'Investor') setTimeout(() => navigation(INVESTOR), 100);
        else setTimeout(() => navigation(DASHBOARD), 100);
    };

    const handleResetPassword = async () => {
        if (!forgotPasswordEmail || forgotPasswordEmail == '') return showMessage('Please provide email');

        setIsLoading(true);

        let response = await postRequest('/user/forgotPassword', { email: forgotPasswordEmail });
        if (response && response['code'] == 200) {
            resetStates();
            dispatch(toggleModal({ open: "resendForgotPasswordEmail", historyData: { email: forgotPasswordEmail } }))
            showMessage(response['message']);
        }

        setIsLoading(false);
    };

    const handlePasswordBlur = (type) => {
        let errors = { ...passwordError };
        const password = newPassword?.['password'];
        const confirmPassword = newPassword?.['confirmPassword'];

        if (type === 'password') {
            if (password === '' || password?.length < 8) errors['password'] = 'Password must be at least 8 characters long';
            else if (!PASSWORD_PATTERN.test(password)) errors['password'] = 'Password must contain atleast one capital letter, one special character and a number';
            else errors['password'] = '';
        }

        if (type === 'confirmPassword' || (password && confirmPassword)) {
            if (password === '' || password?.length < 8) errors['password'] = 'Password must be at least 8 characters long';
            else if (password !== confirmPassword) errors['confirmPassword'] = "Password and Confirm Password don't match";
            else if (!PASSWORD_PATTERN.test(password)) errors['password'] = 'Password must contain atleast one capital letter, one special character and a number';
            else { errors['confirmPassword'] = ''; }
        }

        setPasswordError(errors);

        if (!errors['password'] && !errors['confirmPassword']) setIsValidNewPassword(true);
    };

    const handleSetPassword = async () => {
        if (passwordError['password'] == '' && passwordError['confirmPassword'] == '') {
            if (!location.pathname.includes('reset_password')) return showMessage('Invalid request try link from email');

            setIsLoading(true);
            let params = location.pathname.split('/');
            let key = params[params.length - 1];
            let response = await postRequest(`/user/setPassword`, { key, password: newPassword?.['password'] });
            if (response && response['code'] == 200) {
                resetStates();
                showMessage(response['message']);
                dispatch(toggleModal({ open: "passwordUpdatedSuccessfully" }))
            }
            setIsLoading(false);
        }
    };

    return (
        <>
            <AnimatedPage>
                <div className="df bg1 pr brne bscov h100vh login-page" style={{ backgroundImage: `url(${require('../../images/landingpage/form-background.png')})` }}>
                    <div className="login-container">
                        {operation == 'login' ? (
                            <>
                                <div className={`tslp tty0`}>
                                    <p className="fw500 fs2-125rem c15" style={{ lineHeight: '40.2px' }}>Login to your</p>
                                    <p className="fw500 fs2-125rem c4" style={{ lineHeight: '40.2px' }}>Diligentsia account</p>
                                </div>
                                <div className="pt1rem pb3rem pr8rem">
                                    <div className="pt0-5rem pb0-5rem pr">
                                        <input
                                            id='email'
                                            type='email'
                                            style={{ paddingLeft: '50px' }}
                                            value={formData?.['email'] || ''}
                                            className='pl1rem lpins w100 br10px p1rem custom-input'
                                            placeholder='Email - your company email used to register'
                                            onChange={(e) => {
                                                if (restrictedForWeb()) {
                                                    return showMessage('You can Login/Register on Laptop')
                                                }
                                                setFormData({ ...formData, email: e['target']['value'] })
                                            }
                                            }
                                        />
                                        <svg style={{ top: '30px', left: '20px' }} className="pa" width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.1875 0.375H1.8125C1.36495 0.375 0.935725 0.55279 0.619257 0.869257C0.30279 1.18572 0.125 1.61495 0.125 2.0625V9.9375C0.125 10.3851 0.30279 10.8143 0.619257 11.1307C0.935725 11.4472 1.36495 11.625 1.8125 11.625H14.1875C14.6351 11.625 15.0643 11.4472 15.3807 11.1307C15.6972 10.8143 15.875 10.3851 15.875 9.9375V2.0625C15.875 1.61495 15.6972 1.18572 15.3807 0.869257C15.0643 0.55279 14.6351 0.375 14.1875 0.375ZM13.9456 1.5L8.24187 6.045C8.07875 6.19688 7.92125 6.19688 7.72438 6.045L2.05437 1.5H13.9456ZM14.1875 10.5H1.8125C1.66332 10.5 1.52024 10.4407 1.41475 10.3352C1.30926 10.2298 1.25 10.0867 1.25 9.9375V2.29875L6.99312 6.87187C7.26606 7.13682 7.63087 7.28597 8.01125 7.28813C8.36973 7.28661 8.71398 7.1477 8.97312 6.9L14.75 2.29875V9.9375C14.75 10.0867 14.6907 10.2298 14.5852 10.3352C14.4798 10.4407 14.3367 10.5 14.1875 10.5Z" fill="black" />
                                        </svg>
                                    </div>
                                    <div className="pt0-5rem pb0-5rem pr">
                                        <input
                                            id='password'
                                            style={{ paddingLeft: '50px' }}
                                            className='pl1rem lpins w100 br10px p1rem custom-input'
                                            type={passwordType ? 'password' : 'text'}
                                            placeholder='Password'
                                            value={formData?.['password'] || ''}
                                            onChange={(e) => {
                                                if (restrictedForWeb()) {
                                                    return showMessage('You can Login/Register on Laptop')
                                                }
                                                setFormData({ ...formData, password: e['target']['value'] })
                                            }
                                            }
                                        />
                                        {!passwordType ? (
                                            <svg className="pa cp" onClick={() => setPasswordType(!passwordType)} width="27" height="21" viewBox="0 0 27 21" fill="none" xmlns="http:www.w3.org/2000/svg" style={{ top: '50%', transform: 'translateY(-50%)', right: "12px", }}>
                                                <path d="M26.8887 11.0394C26.8432 11.1372 26.1704 13.1688 24 15.5C19 21 9.45935 22.4011 3.5 16.0003C1 13.5003 0.156749 11.1372 0.111239 11.0394C0.0380833 10.8821 0 10.7087 0 10.5328C0 10.357 0.0380833 10.1836 0.111239 10.0263C0.156749 9.92845 1.5 7.00036 3.5 5.00036C8.5 0.000362396 17 -0.999638 23.5 5.00036C25.4204 6.72473 26.8432 9.92845 26.8887 10.0263C26.9619 10.1836 26.9999 10.357 26.9999 10.5328C26.9999 10.7087 26.9619 10.8821 26.8887 11.0394ZM22.088 6.87801C17.0809 1.50015 8.5 2.50015 4.95721 6.82959C3.58301 8.29409 2.67851 9.77977 2.26217 10.5341C2.67128 11.277 3.55522 12.7304 4.91191 14.1876C9.93318 19.5808 18.0417 18.5001 22.0429 14.236C23.4174 12.7712 24.3219 11.2851 24.7378 10.5318C24.3287 9.78878 23.4447 8.33515 22.088 6.87801ZM13.5 16.1214C10.6309 16.1214 8 13.6132 8 10.5318C8 7.4502 10.6309 4.94436 13.5 4.94436C16.369 4.94436 19 7.41856 19 10.5001C19 13.5816 16.369 16.1214 13.5 16.1214Z" fill="#404040" />
                                            </svg>
                                        ) : (
                                            <svg className="pa cp" onClick={() => setPasswordType(!passwordType)} width="27" height="21" viewBox="0 0 27 21" fill="none" xmlns="http:www.w3.org/2000/svg" style={{ top: '50%', transform: 'translateY(-50%)', right: "12px" }}>
                                                <path d="M23.2877 2.2875C23.7377 1.8375 23.7377 1.1625 23.2877 0.7125C22.8377 0.2625 22.1627 0.2625 21.7127 0.7125L19.9127 2.5125C18.0002 1.5 15.8627 0.9375 13.5002 0.9375C7.6502 0.9375 2.47519 4.3125 0.225195 9.7125L0.112695 10.05C0.000195317 10.3875 0.000195317 10.6125 0.112695 10.95L0.225195 11.2875C1.3502 13.875 3.03769 15.9 5.06269 17.3625L3.71269 18.7125C3.26269 19.1625 3.26269 19.8375 3.71269 20.2875C3.93769 20.5125 4.16269 20.625 4.50019 20.625C4.83769 20.625 5.06269 20.5125 5.28769 20.2875L23.2877 2.2875ZM2.36269 10.5C4.27519 6 8.55019 3.1875 13.5002 3.1875C15.1877 3.1875 16.7627 3.525 18.2252 4.0875L16.5377 5.775C15.6377 5.2125 14.6252 4.875 13.5002 4.875C10.4627 4.875 7.87519 7.4625 7.87519 10.5C7.87519 11.625 8.21269 12.6375 8.77519 13.5375L6.63769 15.675C4.83769 14.4375 3.26269 12.75 2.36269 10.5ZM26.7752 9.825C25.9877 8.025 24.9752 6.45 23.6252 5.2125L22.0502 6.7875C23.0627 7.8 23.9627 9.15 24.6377 10.6125C22.6127 15.1125 18.4502 17.925 13.5002 17.925C12.7127 17.925 11.9252 17.8125 11.2502 17.7L9.3377 19.5C10.6877 19.8375 12.0377 20.0625 13.5002 20.0625C19.3502 20.0625 24.5252 16.6875 26.7752 11.2875L26.8877 10.95C27.0002 10.725 27.0002 10.3875 26.8877 10.05L26.7752 9.825Z" fill="#404040" />
                                                <path d="M19.1254 10.4999C19.1254 10.2749 19.1254 10.0499 19.0129 9.7124L12.7129 16.0124C12.9379 16.0124 13.1629 16.1249 13.5004 16.1249C16.5379 16.1249 19.1254 13.5374 19.1254 10.4999Z" fill="#404040" />
                                            </svg>
                                        )}
                                        <svg style={{ top: '50%', transform: 'translateY(-50%)', left: '20px' }} className="pa" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.0078 12.8672C13.3961 12.8672 13.7109 12.5524 13.7109 12.1641C13.7109 11.7757 13.3961 11.4609 13.0078 11.4609C12.6195 11.4609 12.3047 11.7757 12.3047 12.1641C12.3047 12.5524 12.6195 12.8672 13.0078 12.8672Z" fill="black" />
                                            <path d="M16.1719 12.7266C16.5602 12.7266 16.875 12.4118 16.875 12.0234V9.42188C16.875 7.87106 15.6133 6.60938 14.0625 6.60938H13.2174V4.12966C13.2174 1.85256 11.3249 0 8.9987 0C6.67248 0 4.77995 1.85256 4.77995 4.12966V6.60938H3.9375C2.38669 6.60938 1.125 7.87106 1.125 9.42188V15.1875C1.125 16.7383 2.38669 18 3.9375 18H14.0625C15.6133 18 16.875 16.7383 16.875 15.1875C16.875 14.7992 16.5602 14.4844 16.1719 14.4844C15.7835 14.4844 15.4688 14.7992 15.4688 15.1875C15.4688 15.9629 14.8379 16.5938 14.0625 16.5938H3.9375C3.16209 16.5938 2.53125 15.9629 2.53125 15.1875V9.42188C2.53125 8.64647 3.16209 8.01562 3.9375 8.01562H14.0625C14.8379 8.01562 15.4688 8.64647 15.4688 9.42188V12.0234C15.4688 12.4118 15.7835 12.7266 16.1719 12.7266ZM11.8112 6.60938H6.1862V4.12966C6.1862 2.62796 7.44789 1.40625 8.9987 1.40625C10.5495 1.40625 11.8112 2.62796 11.8112 4.12966V6.60938Z" fill="black" />
                                            <path d="M7.69922 12.8672C8.08754 12.8672 8.40234 12.5524 8.40234 12.1641C8.40234 11.7757 8.08754 11.4609 7.69922 11.4609C7.31089 11.4609 6.99609 11.7757 6.99609 12.1641C6.99609 12.5524 7.31089 12.8672 7.69922 12.8672Z" fill="black" />
                                            <path d="M5.0625 12.8672C5.45083 12.8672 5.76562 12.5524 5.76562 12.1641C5.76562 11.7757 5.45083 11.4609 5.0625 11.4609C4.67417 11.4609 4.35938 11.7757 4.35938 12.1641C4.35938 12.5524 4.67417 12.8672 5.0625 12.8672Z" fill="black" />
                                            <path d="M10.3359 12.8672C10.7243 12.8672 11.0391 12.5524 11.0391 12.1641C11.0391 11.7757 10.7243 11.4609 10.3359 11.4609C9.94761 11.4609 9.63281 11.7757 9.63281 12.1641C9.63281 12.5524 9.94761 12.8672 10.3359 12.8672Z" fill="black" />
                                        </svg>
                                    </div>
                                    <div className='df aic jcsb'>
                                        <button
                                            disabled={!isLogin}
                                            style={{ width: '50%', height: '50px', lineHeight: '20px', cursor: `${!isLogin ? 'not-allowed' : 'pointer'}` }}
                                            className={`${!isLogin && 'login-disabled '} registerButtonAnimation shadow rainbow c1 bg4 br35px mt1rem mb1rem mr2-5rem cp fw600 fs1-25rem pr zi0 br10px`}
                                            onClick={() => login()}
                                        >
                                            {isLoading ? <i class="fa fa-spinner fa-spin"></i> : 'Login'}
                                        </button>
                                    </div>
                                    <div className='df jcsb mt0-5rem'>
                                        <Link className='fs1-25rem cp fw600 tdu c4 ml0-5rem' to={ROUTES.REGISTER}>Create New Account </Link>
                                        <span className='fs1-25rem cp fw600 tdu c0 ml0-5rem' onClick={() => handleOperation('forgotPassword')}>
                                            Forgot Password?
                                        </span>
                                    </div>
                                </div>
                            </>
                        ) : operation == 'forgotPassword' ? (
                            <>
                                <div className={`tslp tty0`}>
                                    <p className="fw500 fs2-125rem c15" style={{ lineHeight: '40.2px' }}>Forgot Password?</p>
                                    <p className='c15 maw600px my1-5rem'>Enter the email address linked to your account and we’ll send you a link to reset your password.</p>
                                </div>
                                <div className="pt1rem pb3rem pr8rem">
                                    <div className="pt0-5rem pb0-5rem pr df aic">
                                        <input
                                            id='forgotPasswordEmail'
                                            type='email'
                                            style={{ paddingLeft: '50px' }}
                                            value={forgotPasswordEmail || ''}
                                            className='pl1rem lpins w100 br10px p1rem custom-input'
                                            placeholder='Email'
                                            onChange={(e) => setForgotPasswordEmail(e['target']['value'])}
                                        />
                                        <svg style={{ left: '20px' }} className="pa" width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.1875 0.375H1.8125C1.36495 0.375 0.935725 0.55279 0.619257 0.869257C0.30279 1.18572 0.125 1.61495 0.125 2.0625V9.9375C0.125 10.3851 0.30279 10.8143 0.619257 11.1307C0.935725 11.4472 1.36495 11.625 1.8125 11.625H14.1875C14.6351 11.625 15.0643 11.4472 15.3807 11.1307C15.6972 10.8143 15.875 10.3851 15.875 9.9375V2.0625C15.875 1.61495 15.6972 1.18572 15.3807 0.869257C15.0643 0.55279 14.6351 0.375 14.1875 0.375ZM13.9456 1.5L8.24187 6.045C8.07875 6.19688 7.92125 6.19688 7.72438 6.045L2.05437 1.5H13.9456ZM14.1875 10.5H1.8125C1.66332 10.5 1.52024 10.4407 1.41475 10.3352C1.30926 10.2298 1.25 10.0867 1.25 9.9375V2.29875L6.99312 6.87187C7.26606 7.13682 7.63087 7.28597 8.01125 7.28813C8.36973 7.28661 8.71398 7.1477 8.97312 6.9L14.75 2.29875V9.9375C14.75 10.0867 14.6907 10.2298 14.5852 10.3352C14.4798 10.4407 14.3367 10.5 14.1875 10.5Z" fill="black" />
                                        </svg>
                                    </div>
                                    <div className='df aic jcsb mt1-5rem'>
                                        <button
                                            style={{ width: '50%', height: '50px', lineHeight: '20px' }}
                                            className={`registerButtonAnimation shadow rainbow c1 bg4 br35px mt1rem mb1rem mr2-5rem cp fw600 fs1-25rem pr zi0 br10px`}
                                            onClick={() => handleResetPassword()}
                                        >
                                            {isLoading ? <i class="fa fa-spinner fa-spin"></i> : 'Reset Password'}
                                        </button>
                                    </div>
                                    <div className='df jcsb mt0-5rem'>
                                        <span className='c15 fs1rem'>
                                            Don’t have an account?
                                            <Link to={ROUTES.REGISTER} className='fs1rem cp fw600 tdu c3 ml0-5rem'>Create New Account </Link>
                                        </span>
                                    </div>
                                </div>
                            </>
                        ) : operation == 'setPassword' ? (
                            <>
                                <div className={`tslp tty0`}>
                                    <p className="fw500 fs2-125rem c15" style={{ lineHeight: '40.2px' }}>Set New Password</p>
                                </div>
                                <div className="pt1rem pb3rem pr8rem">
                                    <div className="pt0-5rem pb1rem pr">
                                        <TextField
                                            id='newPassword'
                                            className='pl1rem lpins w100 br10px p1rem custom-input'
                                            type={newPasswordType['passwordType'] ? 'password' : 'text'}
                                            placeholder='New Password'
                                            value={newPassword?.['password'] || ''}
                                            onChange={(e) => { setNewPassword({ ...newPassword, password: e['target']['value'] }), setIsValidNewPassword(false), setPasswordError({ password: '', confirmPassword: '' }) }}
                                            onBlur={(e) => handlePasswordBlur('password')}
                                            required
                                            error={!!passwordError['password']}
                                            helperText={passwordError['password']}
                                        />
                                        {!newPasswordType['passwordType'] ? (
                                            <svg className="pa cp" onClick={() => setNewPasswordType({ ...newPasswordType, passwordType: !newPasswordType['passwordType'] })} width="27" height="21" viewBox="0 0 27 21" fill="none" xmlns="http:www.w3.org/2000/svg" style={{ top: '50%', transform: 'translateY(-50%)', right: "12px", }}>
                                                <path d="M26.8887 11.0394C26.8432 11.1372 26.1704 13.1688 24 15.5C19 21 9.45935 22.4011 3.5 16.0003C1 13.5003 0.156749 11.1372 0.111239 11.0394C0.0380833 10.8821 0 10.7087 0 10.5328C0 10.357 0.0380833 10.1836 0.111239 10.0263C0.156749 9.92845 1.5 7.00036 3.5 5.00036C8.5 0.000362396 17 -0.999638 23.5 5.00036C25.4204 6.72473 26.8432 9.92845 26.8887 10.0263C26.9619 10.1836 26.9999 10.357 26.9999 10.5328C26.9999 10.7087 26.9619 10.8821 26.8887 11.0394ZM22.088 6.87801C17.0809 1.50015 8.5 2.50015 4.95721 6.82959C3.58301 8.29409 2.67851 9.77977 2.26217 10.5341C2.67128 11.277 3.55522 12.7304 4.91191 14.1876C9.93318 19.5808 18.0417 18.5001 22.0429 14.236C23.4174 12.7712 24.3219 11.2851 24.7378 10.5318C24.3287 9.78878 23.4447 8.33515 22.088 6.87801ZM13.5 16.1214C10.6309 16.1214 8 13.6132 8 10.5318C8 7.4502 10.6309 4.94436 13.5 4.94436C16.369 4.94436 19 7.41856 19 10.5001C19 13.5816 16.369 16.1214 13.5 16.1214Z" fill="#404040" />
                                            </svg>
                                        ) : (
                                            <svg className="pa cp" onClick={() => setNewPasswordType({ ...newPasswordType, passwordType: !newPasswordType['passwordType'] })} width="27" height="21" viewBox="0 0 27 21" fill="none" xmlns="http:www.w3.org/2000/svg" style={{ top: '50%', transform: 'translateY(-50%)', right: "12px" }}>
                                                <path d="M23.2877 2.2875C23.7377 1.8375 23.7377 1.1625 23.2877 0.7125C22.8377 0.2625 22.1627 0.2625 21.7127 0.7125L19.9127 2.5125C18.0002 1.5 15.8627 0.9375 13.5002 0.9375C7.6502 0.9375 2.47519 4.3125 0.225195 9.7125L0.112695 10.05C0.000195317 10.3875 0.000195317 10.6125 0.112695 10.95L0.225195 11.2875C1.3502 13.875 3.03769 15.9 5.06269 17.3625L3.71269 18.7125C3.26269 19.1625 3.26269 19.8375 3.71269 20.2875C3.93769 20.5125 4.16269 20.625 4.50019 20.625C4.83769 20.625 5.06269 20.5125 5.28769 20.2875L23.2877 2.2875ZM2.36269 10.5C4.27519 6 8.55019 3.1875 13.5002 3.1875C15.1877 3.1875 16.7627 3.525 18.2252 4.0875L16.5377 5.775C15.6377 5.2125 14.6252 4.875 13.5002 4.875C10.4627 4.875 7.87519 7.4625 7.87519 10.5C7.87519 11.625 8.21269 12.6375 8.77519 13.5375L6.63769 15.675C4.83769 14.4375 3.26269 12.75 2.36269 10.5ZM26.7752 9.825C25.9877 8.025 24.9752 6.45 23.6252 5.2125L22.0502 6.7875C23.0627 7.8 23.9627 9.15 24.6377 10.6125C22.6127 15.1125 18.4502 17.925 13.5002 17.925C12.7127 17.925 11.9252 17.8125 11.2502 17.7L9.3377 19.5C10.6877 19.8375 12.0377 20.0625 13.5002 20.0625C19.3502 20.0625 24.5252 16.6875 26.7752 11.2875L26.8877 10.95C27.0002 10.725 27.0002 10.3875 26.8877 10.05L26.7752 9.825Z" fill="#404040" />
                                                <path d="M19.1254 10.4999C19.1254 10.2749 19.1254 10.0499 19.0129 9.7124L12.7129 16.0124C12.9379 16.0124 13.1629 16.1249 13.5004 16.1249C16.5379 16.1249 19.1254 13.5374 19.1254 10.4999Z" fill="#404040" />
                                            </svg>
                                        )}
                                        <svg style={{ top: '50%', transform: 'translateY(-50%)', left: '20px' }} className="pa" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.0078 12.8672C13.3961 12.8672 13.7109 12.5524 13.7109 12.1641C13.7109 11.7757 13.3961 11.4609 13.0078 11.4609C12.6195 11.4609 12.3047 11.7757 12.3047 12.1641C12.3047 12.5524 12.6195 12.8672 13.0078 12.8672Z" fill="black" />
                                            <path d="M16.1719 12.7266C16.5602 12.7266 16.875 12.4118 16.875 12.0234V9.42188C16.875 7.87106 15.6133 6.60938 14.0625 6.60938H13.2174V4.12966C13.2174 1.85256 11.3249 0 8.9987 0C6.67248 0 4.77995 1.85256 4.77995 4.12966V6.60938H3.9375C2.38669 6.60938 1.125 7.87106 1.125 9.42188V15.1875C1.125 16.7383 2.38669 18 3.9375 18H14.0625C15.6133 18 16.875 16.7383 16.875 15.1875C16.875 14.7992 16.5602 14.4844 16.1719 14.4844C15.7835 14.4844 15.4688 14.7992 15.4688 15.1875C15.4688 15.9629 14.8379 16.5938 14.0625 16.5938H3.9375C3.16209 16.5938 2.53125 15.9629 2.53125 15.1875V9.42188C2.53125 8.64647 3.16209 8.01562 3.9375 8.01562H14.0625C14.8379 8.01562 15.4688 8.64647 15.4688 9.42188V12.0234C15.4688 12.4118 15.7835 12.7266 16.1719 12.7266ZM11.8112 6.60938H6.1862V4.12966C6.1862 2.62796 7.44789 1.40625 8.9987 1.40625C10.5495 1.40625 11.8112 2.62796 11.8112 4.12966V6.60938Z" fill="black" />
                                            <path d="M7.69922 12.8672C8.08754 12.8672 8.40234 12.5524 8.40234 12.1641C8.40234 11.7757 8.08754 11.4609 7.69922 11.4609C7.31089 11.4609 6.99609 11.7757 6.99609 12.1641C6.99609 12.5524 7.31089 12.8672 7.69922 12.8672Z" fill="black" />
                                            <path d="M5.0625 12.8672C5.45083 12.8672 5.76562 12.5524 5.76562 12.1641C5.76562 11.7757 5.45083 11.4609 5.0625 11.4609C4.67417 11.4609 4.35938 11.7757 4.35938 12.1641C4.35938 12.5524 4.67417 12.8672 5.0625 12.8672Z" fill="black" />
                                            <path d="M10.3359 12.8672C10.7243 12.8672 11.0391 12.5524 11.0391 12.1641C11.0391 11.7757 10.7243 11.4609 10.3359 11.4609C9.94761 11.4609 9.63281 11.7757 9.63281 12.1641C9.63281 12.5524 9.94761 12.8672 10.3359 12.8672Z" fill="black" />
                                        </svg>
                                    </div>
                                    <div className="pt0-5rem pb0-5rem pr">
                                        <TextField
                                            id='confirmNewPassword'
                                            className='pl1rem lpins w100 br10px p1rem custom-input'
                                            type={newPasswordType['confirmPasswordType'] ? 'password' : 'text'}
                                            placeholder='Confirm New Password'
                                            value={newPassword?.['confirmPassword'] || ''}
                                            onChange={(e) => { setNewPassword({ ...newPassword, confirmPassword: e['target']['value'] }), setIsValidNewPassword(false), setPasswordError({ password: '', confirmPassword: '' }) }}
                                            onBlur={(e) => handlePasswordBlur('confirmPassword')}
                                            required
                                            error={!!passwordError['confirmPassword']}
                                            helperText={passwordError['confirmPassword']}
                                        />
                                        {!newPasswordType['confirmPasswordType'] ? (
                                            <svg className="pa cp" onClick={() => setNewPasswordType({ ...newPasswordType, confirmPasswordType: !newPasswordType['confirmPasswordType'] })} width="27" height="21" viewBox="0 0 27 21" fill="none" xmlns="http:www.w3.org/2000/svg" style={{ top: '50%', transform: 'translateY(-50%)', right: "12px", }}>
                                                <path d="M26.8887 11.0394C26.8432 11.1372 26.1704 13.1688 24 15.5C19 21 9.45935 22.4011 3.5 16.0003C1 13.5003 0.156749 11.1372 0.111239 11.0394C0.0380833 10.8821 0 10.7087 0 10.5328C0 10.357 0.0380833 10.1836 0.111239 10.0263C0.156749 9.92845 1.5 7.00036 3.5 5.00036C8.5 0.000362396 17 -0.999638 23.5 5.00036C25.4204 6.72473 26.8432 9.92845 26.8887 10.0263C26.9619 10.1836 26.9999 10.357 26.9999 10.5328C26.9999 10.7087 26.9619 10.8821 26.8887 11.0394ZM22.088 6.87801C17.0809 1.50015 8.5 2.50015 4.95721 6.82959C3.58301 8.29409 2.67851 9.77977 2.26217 10.5341C2.67128 11.277 3.55522 12.7304 4.91191 14.1876C9.93318 19.5808 18.0417 18.5001 22.0429 14.236C23.4174 12.7712 24.3219 11.2851 24.7378 10.5318C24.3287 9.78878 23.4447 8.33515 22.088 6.87801ZM13.5 16.1214C10.6309 16.1214 8 13.6132 8 10.5318C8 7.4502 10.6309 4.94436 13.5 4.94436C16.369 4.94436 19 7.41856 19 10.5001C19 13.5816 16.369 16.1214 13.5 16.1214Z" fill="#404040" />
                                            </svg>
                                        ) : (
                                            <svg className="pa cp" onClick={() => setNewPasswordType({ ...newPasswordType, confirmPasswordType: !newPasswordType['confirmPasswordType'] })} width="27" height="21" viewBox="0 0 27 21" fill="none" xmlns="http:www.w3.org/2000/svg" style={{ top: '50%', transform: 'translateY(-50%)', right: "12px" }}>
                                                <path d="M23.2877 2.2875C23.7377 1.8375 23.7377 1.1625 23.2877 0.7125C22.8377 0.2625 22.1627 0.2625 21.7127 0.7125L19.9127 2.5125C18.0002 1.5 15.8627 0.9375 13.5002 0.9375C7.6502 0.9375 2.47519 4.3125 0.225195 9.7125L0.112695 10.05C0.000195317 10.3875 0.000195317 10.6125 0.112695 10.95L0.225195 11.2875C1.3502 13.875 3.03769 15.9 5.06269 17.3625L3.71269 18.7125C3.26269 19.1625 3.26269 19.8375 3.71269 20.2875C3.93769 20.5125 4.16269 20.625 4.50019 20.625C4.83769 20.625 5.06269 20.5125 5.28769 20.2875L23.2877 2.2875ZM2.36269 10.5C4.27519 6 8.55019 3.1875 13.5002 3.1875C15.1877 3.1875 16.7627 3.525 18.2252 4.0875L16.5377 5.775C15.6377 5.2125 14.6252 4.875 13.5002 4.875C10.4627 4.875 7.87519 7.4625 7.87519 10.5C7.87519 11.625 8.21269 12.6375 8.77519 13.5375L6.63769 15.675C4.83769 14.4375 3.26269 12.75 2.36269 10.5ZM26.7752 9.825C25.9877 8.025 24.9752 6.45 23.6252 5.2125L22.0502 6.7875C23.0627 7.8 23.9627 9.15 24.6377 10.6125C22.6127 15.1125 18.4502 17.925 13.5002 17.925C12.7127 17.925 11.9252 17.8125 11.2502 17.7L9.3377 19.5C10.6877 19.8375 12.0377 20.0625 13.5002 20.0625C19.3502 20.0625 24.5252 16.6875 26.7752 11.2875L26.8877 10.95C27.0002 10.725 27.0002 10.3875 26.8877 10.05L26.7752 9.825Z" fill="#404040" />
                                                <path d="M19.1254 10.4999C19.1254 10.2749 19.1254 10.0499 19.0129 9.7124L12.7129 16.0124C12.9379 16.0124 13.1629 16.1249 13.5004 16.1249C16.5379 16.1249 19.1254 13.5374 19.1254 10.4999Z" fill="#404040" />
                                            </svg>
                                        )}
                                        <svg style={{ top: '50%', transform: 'translateY(-50%)', left: '20px' }} className="pa" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.0078 12.8672C13.3961 12.8672 13.7109 12.5524 13.7109 12.1641C13.7109 11.7757 13.3961 11.4609 13.0078 11.4609C12.6195 11.4609 12.3047 11.7757 12.3047 12.1641C12.3047 12.5524 12.6195 12.8672 13.0078 12.8672Z" fill="black" />
                                            <path d="M16.1719 12.7266C16.5602 12.7266 16.875 12.4118 16.875 12.0234V9.42188C16.875 7.87106 15.6133 6.60938 14.0625 6.60938H13.2174V4.12966C13.2174 1.85256 11.3249 0 8.9987 0C6.67248 0 4.77995 1.85256 4.77995 4.12966V6.60938H3.9375C2.38669 6.60938 1.125 7.87106 1.125 9.42188V15.1875C1.125 16.7383 2.38669 18 3.9375 18H14.0625C15.6133 18 16.875 16.7383 16.875 15.1875C16.875 14.7992 16.5602 14.4844 16.1719 14.4844C15.7835 14.4844 15.4688 14.7992 15.4688 15.1875C15.4688 15.9629 14.8379 16.5938 14.0625 16.5938H3.9375C3.16209 16.5938 2.53125 15.9629 2.53125 15.1875V9.42188C2.53125 8.64647 3.16209 8.01562 3.9375 8.01562H14.0625C14.8379 8.01562 15.4688 8.64647 15.4688 9.42188V12.0234C15.4688 12.4118 15.7835 12.7266 16.1719 12.7266ZM11.8112 6.60938H6.1862V4.12966C6.1862 2.62796 7.44789 1.40625 8.9987 1.40625C10.5495 1.40625 11.8112 2.62796 11.8112 4.12966V6.60938Z" fill="black" />
                                            <path d="M7.69922 12.8672C8.08754 12.8672 8.40234 12.5524 8.40234 12.1641C8.40234 11.7757 8.08754 11.4609 7.69922 11.4609C7.31089 11.4609 6.99609 11.7757 6.99609 12.1641C6.99609 12.5524 7.31089 12.8672 7.69922 12.8672Z" fill="black" />
                                            <path d="M5.0625 12.8672C5.45083 12.8672 5.76562 12.5524 5.76562 12.1641C5.76562 11.7757 5.45083 11.4609 5.0625 11.4609C4.67417 11.4609 4.35938 11.7757 4.35938 12.1641C4.35938 12.5524 4.67417 12.8672 5.0625 12.8672Z" fill="black" />
                                            <path d="M10.3359 12.8672C10.7243 12.8672 11.0391 12.5524 11.0391 12.1641C11.0391 11.7757 10.7243 11.4609 10.3359 11.4609C9.94761 11.4609 9.63281 11.7757 9.63281 12.1641C9.63281 12.5524 9.94761 12.8672 10.3359 12.8672Z" fill="black" />
                                        </svg>
                                    </div>
                                    <div className='df aic jcsb'>
                                        <button
                                            style={{ width: '50%', height: '50px', lineHeight: '20px' }}
                                            className={`registerButtonAnimation shadow rainbow c1 bg4 br35px mt1rem mb1rem mr2-5rem cp fw600 fs1-25rem pr zi0 br10px ${!isValidNewPassword && 'login-disabled'}`}
                                            onClick={() => handleSetPassword()}
                                        >
                                            {isLoading ? <i class="fa fa-spinner fa-spin"></i> : 'Set Password'}
                                        </button>
                                    </div>
                                    <div className='df jcsb mt0-5rem'>
                                        <Link to={ROUTES.REGISTER} className='fs1-25rem cp fw600 tdu c4 ml0-5rem'>Create New Account </Link>
                                        <span className='fs1-25rem cp fw600 tdu c0 ml0-5rem' onClick={() => handleOperation('refresh')}>Login</span>
                                    </div>
                                </div>
                            </>
                        ) : null}
                    </div>
                    <div className="pr bscov brnr df jce aie form-right-image" style={{ backgroundImage: `url(${require('../../images/landingpage/not-sure-bg.png')})`, width: "40%", backgroundSize: '1005' }}>
                        <img
                            src={require('../../images/loginGirl.png')}
                            className="pa loginGirl"
                            style={{ width: '90%', left: '-40%', bottom: '0px' }}
                        />
                        <img
                            src={require('../../images/landingpage/book-call.png')}
                            className="pa book-call"
                            style={{ right: "110px", bottom: '150px', width: '50%', cursor: 'pointer' }}
                            onClick={() => window.open('https://koalendar.com/e/meet-with-mark-bernstein-koaSc6kO?embed=true', '_blank')}
                        />
                    </div>
                    <img
                        src={require('../../images/landingpage/circle.png')}
                        className="ar pa circle"
                        style={{ top: '-32px', height: '8%', left: '-15px' }}
                    />
                </div >
            </AnimatedPage>
        </>
    );

}

export default Login;
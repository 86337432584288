import React from 'react'
import ModalWrapper from '../../common/Modal/ModalWrapper'

const ChooseAuthMethodModal = ({ modalType, smsEnabled, loginWithSMS, twoFaEnabled, loginWithTwoFA, emailEnabled, loginWithEmail }) => {
    return (
        <ModalWrapper modalType={modalType} modalCardClasses={"bg38"}>
            <h1 className='c15 fs2rem tac lh1 fw600 mb1rem'>
                Verify it's You
            </h1>
            <h2 className='c15 op60 fs1rem ttu w100 tac mb2rem'>
                Choose a way to log in securely
            </h2>
            <div className='df gtcr3-md gg1rem tac jcc mb2rem'>
                {smsEnabled && (
                    <div
                        className={`bg1 px2rem py0-5rem br30px df fdc jcc aic pr cp`}
                        style={{ minHeight: '230px' }}
                        onClick={() => loginWithSMS()}
                    >
                        <img src={require(`../../../../images/profilePage/sms_auth.svg`)} alt={`google_authIcon`} />
                        <div className='tac c15'>
                            <h3 className='fw800 tac'>SMS</h3>
                            Authentication
                        </div>
                    </div>
                )}

                {twoFaEnabled && (
                    <div
                        className={`bg1 px2rem py0-5rem br30px df fdc jcc aic pr cp`}
                        style={{ minHeight: '230px' }}
                        onClick={() => loginWithTwoFA()}
                    >
                        <img src={require(`../../../../images/profilePage/google_auth.svg`)} alt={`google_authIcon`} />
                        <div className='tac c15'>
                            <h3 className='fw800 tac'>Google</h3>
                            Authentication
                        </div>
                    </div>
                )}

                {emailEnabled && (
                    <div
                        className={`bg1 px2rem py0-5rem br30px df fdc jcc aic pr cp`}
                        style={{ minHeight: '230px' }}
                        onClick={() => loginWithEmail()}
                    >
                        <img src={require(`../../../../images/profilePage/email_auth.svg`)} alt={`google_authIcon`} />
                        <div className='tac c15'>
                            <h3 className='fw800 tac'>Email</h3>
                            Authentication
                        </div>
                    </div>
                )}

            </div>
        </ModalWrapper>
    )
}

export default ChooseAuthMethodModal
import React from 'react';
import ModalWrapper from '../../common/Modal/ModalWrapper';
import Button from '../../common/Button';
import { toggleModal } from '../../../reducers/modalReducer';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const PasswordUpdated = ({ modalType, modalData, user }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const onCloseHandler = () => {
        dispatch(toggleModal({ open: "", historyData: null }))
    };

    return (
        <ModalWrapper modalType={modalType} onCloseHandler={onCloseHandler}>
            <div className='df fdc jcc aic'>
                <div className='maw100px mb2rem'>
                    <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_5984_45475)">
                            <path d="M11.1204 48.0918C11.1204 60.425 17.2478 71.3271 26.6242 77.9196C28.2766 79.0819 28.3645 81.4953 26.7773 82.7448L26.7326 82.7799C25.6963 83.5959 24.2431 83.6501 23.1636 82.892C12.2175 75.2045 5.0625 62.4831 5.0625 48.0918C5.0625 24.7488 24.3845 5.79883 47.6806 5.59961V11.6589C27.728 11.8567 11.1204 28.093 11.1204 48.0918Z" fill="#1ED688" />
                            <path d="M48.7944 0.180886L59.9272 7.79587C60.5234 8.20383 60.5234 9.08346 59.9272 9.49142L48.7944 17.1064C48.1125 17.573 47.1875 17.0844 47.1875 16.259V1.02903C47.1875 0.202859 48.1125 -0.284934 48.7944 0.180886Z" fill="#1AB975" />
                            <path d="M84.8178 47.9084C84.8178 35.5751 78.6897 24.673 69.3132 18.0805C67.6609 16.9182 67.573 14.5048 69.1601 13.2553L69.2048 13.2202C70.2412 12.4043 71.6943 12.3501 72.7739 13.1081C83.7207 20.7956 90.875 33.517 90.875 47.9084C90.875 71.2514 71.5859 90.2013 48.2891 90.4005V84.3412C68.2417 84.1434 84.8178 67.9079 84.8178 47.9084Z" fill="#1ED688" />
                            <path d="M47.6766 11.0022C38.2598 11.1532 30.7734 19.0089 30.7734 28.4257V38.8832H34.8188V28.3418C34.8188 22.6514 42.003 15.165 47.6934 15.0307C53.5516 14.8964 61.0716 22.3157 61.0716 28.1571V38.8999H65.1169V28.1571C65.1169 18.6061 57.2612 10.8511 47.6766 11.0022Z" fill="url(#paint0_linear_5984_45475)" />
                            <path d="M47.7321 13.957C40.2624 14.0913 34.3203 21.1077 34.3203 29.5341V38.8837H37.5096V28.3423C37.5096 22.652 41.9914 17.8513 47.6817 17.717C53.5399 17.5827 58.3574 22.3163 58.3574 28.1409V38.8837H61.5466V29.2823C61.5634 20.7552 55.336 13.8227 47.7321 13.957Z" fill="url(#paint1_linear_5984_45475)" />
                            <path d="M47.9453 11.002V17.733C53.7028 17.733 58.3692 22.4162 58.3692 28.1569V38.8997H65.1003V28.1569C65.117 18.6898 57.4124 11.002 47.9453 11.002Z" fill="url(#paint2_linear_5984_45475)" />
                            <path d="M58.3708 28.1564V38.8993H65.1018V28.1564C65.1018 27.0654 65.0011 25.9911 64.7997 24.9336H57.8672C58.2029 25.9575 58.3708 27.0318 58.3708 28.1564Z" fill="url(#paint3_linear_5984_45475)" />
                            <path d="M37.5213 28.3587C37.5213 27.1669 37.7227 26.0255 38.0752 24.9512H31.1259C30.9077 26.0758 30.7734 27.234 30.7734 28.4258V38.8833H37.5045V28.3587H37.5213Z" fill="url(#paint4_linear_5984_45475)" />
                            <path d="M68.4317 79.745H28.497C25.461 79.745 23 77.2939 23 74.2707V43.8396C23 40.8161 25.4612 38.3652 28.497 38.3652H68.4319C71.4679 38.3652 73.9289 40.8163 73.9289 43.8396V74.2707C73.9289 77.2939 71.4677 79.745 68.4317 79.745Z" fill="url(#paint5_linear_5984_45475)" />
                            <path d="M23 65.9512V74.2567C23 77.2876 25.4612 79.7444 28.497 79.7444H68.4319C71.4679 79.7444 73.9289 77.2874 73.9289 74.2567V65.9512H23Z" fill="url(#paint6_linear_5984_45475)" />
                            <path d="M47.1503 95.8196L36.0175 88.2046C35.4213 87.7967 35.4213 86.917 36.0175 86.5091L47.1503 78.8941C47.8314 78.4276 48.7572 78.9161 48.7572 79.7415V94.9715C48.7572 95.7977 47.8314 96.2862 47.1503 95.8196Z" fill="#1AB975" />
                            <path d="M64.3224 54.2362L48.6355 66.998L44.2269 70.6677C43.1882 71.5312 41.8734 72 40.5361 72C40.3135 72 40.0908 71.9873 39.8661 71.9612C38.3062 71.7773 36.8706 70.0503 35.9288 68.7878L31.3501 62.1379C29.8148 60.0831 29.3029 56.5464 31.351 55.0026C32.7032 53.1724 36.8121 52.9471 38.3482 55.0026L42.0387 60.2493L42.0759 60.219L58.4038 47.0709C60.3753 45.4313 63.2984 45.7048 64.9327 47.6834C66.5671 49.6621 66.2939 52.5959 64.3224 54.2362Z" fill="url(#paint7_linear_5984_45475)" />
                            <path d="M65.1097 54.0345L48.4054 68C45.1829 66.428 42.3489 64.2553 40 61.5474L58.4406 46.1747C60.662 44.3761 63.9558 44.6761 65.7974 46.8466C67.639 49.017 67.3311 52.2352 65.1097 54.0345Z" fill="url(#paint8_linear_5984_45475)" />
                            <path d="M63.5596 54.1122L48.5448 66.6807L44.7595 69.8496C43.8676 70.5952 42.7387 71 41.5905 71C41.3993 71 41.2081 70.989 41.0152 70.9665C39.6758 70.8077 38.4432 70.0906 37.6345 69.0004L31.7958 61.1445C30.4775 59.3701 30.8339 56.8513 32.5924 55.5183C34.3521 54.1877 36.8475 54.5474 38.1664 56.3224L42.1079 61.6272L42.1399 61.601L58.4777 47.9247C60.1704 46.5089 62.6803 46.7451 64.0836 48.4537C65.4869 50.1623 65.2523 52.6957 63.5596 54.1122Z" fill="#1ED688" />
                            <path d="M63.5508 54.2276L48.4442 67C45.9735 65.7424 43.8008 64.0042 42 61.838L58.4378 47.9397C60.1409 46.5009 62.6661 46.7409 64.078 48.4773C65.4899 50.2136 65.2539 52.7881 63.5508 54.2276Z" fill="#35E298" />
                        </g>
                        <defs>
                            <linearGradient id="paint0_linear_5984_45475" x1="35.6453" y1="9.68617" x2="57.0012" y2="47.1283" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#EAF6FF" />
                                <stop offset="0.2729" stop-color="#E5F1FB" />
                                <stop offset="0.5557" stop-color="#D9E6EF" />
                                <stop offset="0.8419" stop-color="#C4D2DC" />
                                <stop offset="1" stop-color="#B6C4CF" />
                            </linearGradient>
                            <linearGradient id="paint1_linear_5984_45475" x1="47.9503" y1="46.208" x2="47.9503" y2="3.21418" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#EAF6FF" />
                                <stop offset="0.2729" stop-color="#E5F1FB" />
                                <stop offset="0.5557" stop-color="#D9E6EF" />
                                <stop offset="0.8419" stop-color="#C4D2DC" />
                                <stop offset="1" stop-color="#B6C4CF" />
                            </linearGradient>
                            <linearGradient id="paint2_linear_5984_45475" x1="60.8884" y1="24.9433" x2="67.0363" y2="24.9433" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#596C76" stop-opacity="0" />
                                <stop offset="0.179" stop-color="#596C76" stop-opacity="0.035" />
                                <stop offset="0.3646" stop-color="#596C76" stop-opacity="0.141" />
                                <stop offset="0.5532" stop-color="#596C76" stop-opacity="0.316" />
                                <stop offset="0.7438" stop-color="#596C76" stop-opacity="0.563" />
                                <stop offset="0.9342" stop-color="#596C76" stop-opacity="0.876" />
                                <stop offset="1" stop-color="#596C76" />
                            </linearGradient>
                            <linearGradient id="paint3_linear_5984_45475" x1="61.4892" y1="31.9683" x2="61.4892" y2="39.4629" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#596C76" stop-opacity="0" />
                                <stop offset="0.179" stop-color="#596C76" stop-opacity="0.035" />
                                <stop offset="0.3646" stop-color="#596C76" stop-opacity="0.141" />
                                <stop offset="0.5532" stop-color="#596C76" stop-opacity="0.316" />
                                <stop offset="0.7438" stop-color="#596C76" stop-opacity="0.563" />
                                <stop offset="0.9342" stop-color="#596C76" stop-opacity="0.876" />
                                <stop offset="1" stop-color="#596C76" />
                            </linearGradient>
                            <linearGradient id="paint4_linear_5984_45475" x1="34.4275" y1="31.9691" x2="34.4275" y2="39.4637" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#596C76" stop-opacity="0" />
                                <stop offset="0.179" stop-color="#596C76" stop-opacity="0.035" />
                                <stop offset="0.3646" stop-color="#596C76" stop-opacity="0.141" />
                                <stop offset="0.5532" stop-color="#596C76" stop-opacity="0.316" />
                                <stop offset="0.7438" stop-color="#596C76" stop-opacity="0.563" />
                                <stop offset="0.9342" stop-color="#596C76" stop-opacity="0.876" />
                                <stop offset="1" stop-color="#596C76" />
                            </linearGradient>
                            <linearGradient id="paint5_linear_5984_45475" x1="35.0017" y1="45.6481" x2="57.4793" y2="68.2189" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#FFDA2D" />
                                <stop offset="1" stop-color="#FDBF00" />
                            </linearGradient>
                            <linearGradient id="paint6_linear_5984_45475" x1="48.4646" y1="73.2078" x2="48.4646" y2="79.9944" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#FF9100" stop-opacity="0" />
                                <stop offset="1" stop-color="#FF9100" />
                            </linearGradient>
                            <linearGradient id="paint7_linear_5984_45475" x1="60.5" y1="65.5" x2="58.7171" y2="51.1669" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#FFDA2D" />
                                <stop offset="1" stop-color="#FDBF00" />
                            </linearGradient>
                            <linearGradient id="paint8_linear_5984_45475" x1="70.1608" y1="63.4975" x2="52.0826" y2="33.1002" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#FFDA2D" />
                                <stop offset="1" stop-color="#FDBF00" />
                            </linearGradient>
                            <clipPath id="clip0_5984_45475">
                                <rect width="96" height="96" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div>
                    <h2 className='c15 tac mb1rem'>Password Updated</h2>
                    <p className='c15 tac op70 mb1-5rem fs13px'>
                        {
                            modalData && modalData.message ? modalData.message : "Your password has been reset successfully. You can now login to your account."
                        }
                    </p>
                    <div className='df jcc mb2rem'>
                        <Button
                            btnType="warning"
                            style={{ width: '210px' }}
                            text={modalData && modalData.message ? "Ok" : "Login"}
                            onClick={() => {
                                if (!modalData) { navigate("/login") }
                                onCloseHandler()
                            }}
                        />
                    </div>
                </div>
            </div>
        </ModalWrapper>
    );
};

export default PasswordUpdated;

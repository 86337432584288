import React, { forwardRef } from 'react';

const InputField = forwardRef(({ prefixIcon, afterIcon, error, customClassName, ...props }, ref) => {

    return (
        <>
            <div className='df aic'>
                {prefixIcon ? <>{prefixIcon}</> : null}
                <input
                    ref={ref}
                    {...props}
                    className={`w100 bg1 bs1px brss bo42 mt0-9rem py0-75rem br8px ${prefixIcon ? "pl3rem" : "pl1rem"} ${customClassName}`}
                />
                {afterIcon ? <>{afterIcon}</> : null}
            </div>
            {error && <p className="errorform">{error.message}</p>}
        </>
    );
});

export default InputField;

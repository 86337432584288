import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Workflows from './Workflows';
import MainDashboard from './MainDashboard';
import AnyListChecklists from './AnyListChecklists';


export default function YourFirm({ company, companyId }) {

    const spinner = document.getElementById("e1jygW4piZH1");

    let user = useSelector((state) => state.user);

    let [firmTab, setFirmTab] = useState('Main Dashboard');

    return (
        <>
            {spinner.classList.remove("op0")}
            {company && Object.values(company).length > 0 && spinner.classList.add("op0")}
            <div className="dashboard-firm-tab">
                <div className='tabs-navlist tabs-dashboard df aic jcc my2rem'>{
                    ['Main Dashboard', 'AnyList Checklists', 'Workflows'].map(item => {
                        return <div className="df fdc">
                            {item == 'AnyList Checklists' && <span className="c4 fs0-75rem" style={{ marginTop: '-15px' }}>Internal Used Only</span>}
                            <button className={`fs1-25rem fw500 c15 cp ${firmTab == item && ' active'}`} onClick={() => setFirmTab(item)}>{item}</button>
                        </div>
                    })
                }
                </div>
                <div className='dashboard-tools-area'>
                    {firmTab != 'Main Dashboard' &&
                        <div class="w100 df jcsb px3rem mb1rem">
                            <p class="fs0-75rem fw300 c15"><i class="fa fa-circle mr0-5rem" style={{ fontSize: '20px', color: user['role'] == 'Investor' ? '#AAFED680' : '#ffe50063' }} /> Created by {user['role']}</p>
                            <a href={`/createChecklist?companyId=${company['_id']};cName=${company['cName']}`} target='_blank' className="df aic cp bg1 br50px bw2px bss bo16 p0-5rem bsbb mr2rem audit-log-btn">
                                <span className='bg1 bsbb mr0-5rem br100 w30px h30px df jcc aic tac'>
                                    <svg width="28" height="28" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_3623_3962)">
                                            <path d="M19 0C8.52298 0 0 8.52298 0 19C0 29.477 8.52298 38 19 38C29.477 38 38 29.477 38 19C38 8.52298 29.477 0 19 0Z" fill="#4A67FF" />
                                            <path d="M27.3098 20.5805H20.5805V27.3098C20.5805 28.1839 19.8714 28.893 18.9973 28.893C18.1232 28.893 17.4141 28.1839 17.4141 27.3098V20.5805H10.6848C9.8107 20.5805 9.10156 19.8714 9.10156 18.9973C9.10156 18.1232 9.8107 17.4141 10.6848 17.4141H17.4141V10.6848C17.4141 9.8107 18.1232 9.10156 18.9973 9.10156C19.8714 9.10156 20.5805 9.8107 20.5805 10.6848V17.4141H27.3098C28.1839 17.4141 28.893 18.1232 28.893 18.9973C28.893 19.8714 28.1839 20.5805 27.3098 20.5805Z" fill="#FAFAFA" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_3623_3962">
                                                <rect width="38" height="38" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </span>
                                <span className='c15'>Add/Edit Checklist</span>
                            </a>
                        </div>
                    }
                    <div className='box-dashbord-container mla mra mb6rem'>
                        <div className=" bsbb keyfeature-box-core-dashboard">
                            {firmTab == 'Main Dashboard' && <MainDashboard company={company} companyId={companyId} />}
                            {firmTab == 'AnyList Checklists' && <AnyListChecklists company={company} companyId={companyId} />}
                            {firmTab == 'Workflows' && <Workflows company={company} companyId={companyId} />}
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";

import Clients from './Clients';
import Team from './Team';
import AnyListBuilder from './AnyListBuilder';
import QualifiedLeads from './QualifiedLeads';
import PermissionsClient from './PermissionsClient';
import { getRequest, postRequest } from "../../../config";
import CheckoutForm from '../../../components/CheckoutForm';
import { toggleModal } from "../../../reducers/modalReducer";
import RevenueLeadsAllocation from './RevenueLeadsAllocation';
import HeadingSubheadingContent from "../../../components/HeadingSubheadingContent";

import "react-circular-progressbar/dist/styles.css";
import InfoIcon from "../../../../images/info-icon-tab.svg";


export default function ClientsIndex({ company, companyId, getQualifiedLeads, qualifiedLeads, promptScore, noClients, advisors, getAdvisors, investors, handleAddAdvisor, handleAdvisor, setSelectedData, showEditButton,
    handleEditRoute, hasValidAdvisor, sectionsWithChecklist }) {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    let user = useSelector((state) => state.user);
    let modal = useSelector((state) => state.modal);
    let companies = useSelector((state) => state.companies);

    let [plan, setPlan] = useState("Pro");
    let [tab, setTab] = useState("Client");
    let [refresh, setRefresh] = useState(false);
    let [auditLogs, setAuditLogs] = useState([]);
    let [selectedCompany, setSelectedCompany] = useState({});
    let [selectedHowItWorks, setSelectedHowItWorks] = useState({});
    let [profileClass, setProfileClass] = useState("client-profile");

    const spinner = document.getElementById("e1jygW4piZH1");

    const HOW_TO_POPUPS = {
        'add-client': {
            howItWorks: {
                subTitle: <div className=''><span className="c3">Add Client</span></div>,
                stepImage: require('../../../../images/step-modal-img.png'),
                checkList: [<div className='checklist-box df jcc'><img className='w3rem' src={require('../../../../images/advisor-dashboard/chase1.png')} alt='' /> <span className='fs1rem mx1rem'>Chase your colleagues to get activated and to start creating value!</span></div>],
                arrayImages: [require('../../../../images/advisor-dashboard/add-client-1.png'), require('../../../../images/advisor-dashboard/add-client-2.png'), require('../../../../images/advisor-dashboard/add-client-3.png')],
            }
        },
        'client-benchmarking': {
            howItWorks: {
                subTitle: <div className=''><span className="c3">Client Benchmarking</span></div>,
                stepImage: require('../../../../images/step-modal-img.png'),
                arrayImages: [require('../../../../images/advisor-dashboard/client-benchmarking-1.png'), require('../../../../images/advisor-dashboard/client-benchmarking-2.png'), require('../../../../images/advisor-dashboard/client-benchmarking-3.png')],
            }
        },
        'team': {
            howItWorks: {
                subTitle: <div className=''><span className="c3">Add Team Member</span></div>,
                stepImage: require('../../../../images/step-modal-img.png'),
                checkList: [<div className='checklist-box df jcc'><img className='w3rem' src={require('../../../../images/advisor-dashboard/chase1.png')} alt='' /> <span className='fs1rem mx1rem'>Chase your colleagues to get activated and to start creating value!</span></div>],
                arrayImages: [require('../../../../images/advisor-dashboard/add-client-step-1.png'), require('../../../../images/advisor-dashboard/add-client-step-2.png'), require('../../../../images/advisor-dashboard/add-client-step-3.png')],
            }
        },
        'revenue-lead': {
            howItWorks: {
                subTitle: <div className=''><span className="c3">Revenue Generation Leads</span></div>,
                stepImage: require('../../../../images/step-modal-img.png'),
                checkList: [<div className='checklist-box'><i className='icon'><img className='w1rem' src={require('../../../../images/advisor-dashboard/check-icon.png')} alt='' /></i><span className='c12'>Your cards served to your clients?</span></div>, <div className='checklist-box'><i className='icon'><img className='w1rem' src={require('../../../../images/advisor-dashboard/check-icon.png')} alt='' /></i><span className='c12'>Your cards served to unaffiliated companies?*</span></div>, <div className='checklist-box'><i className='icon'><img className='w1rem' src={require('../../../../images/advisor-dashboard/cross-icon.png')} alt='' /></i><span className='c12'>Other advisors’ cards served to <b>your</b> clients? No.</span></div>],
                arrayImages: [require('../../../../images/advisor-dashboard/revenue-generation-leads-1.png'), require('../../../../images/advisor-dashboard/revenue-generation-leads-2.png'), require('../../../../images/advisor-dashboard/revenue-generation-leads-3.png')],
                bottomSection: <div className='img-text df aic w90 mla mra br45px c12 tac jcc mt1rem'>*= Companies subscribing to the platform directly i.e not through advisors. The more of your own clients you add, the more often your own business cards will be served by our algorithm to those unaffiliated companies. </div>,
            }
        },
        'qualified-leads': {
            howItWorks: {
                subTitle: <div className=''><span className="c3">Revenue Generation Leads</span></div>,
                stepImage: require('../../../../images/step-modal-img.png'),
                checkList: [<div className='checklist-box'><i className='icon'><img className='w1rem' src={require('../../../../images/advisor-dashboard/check-icon.png')} alt='' /></i><span className='c12'>Your cards served to your clients?</span></div>, <div className='checklist-box'><i className='icon'><img className='w1rem' src={require('../../../../images/advisor-dashboard/check-icon.png')} alt='' /></i><span className='c12'>Your cards served to unaffiliated companies?*</span></div>, <div className='checklist-box'><i className='icon'><img className='w1rem' src={require('../../../../images/advisor-dashboard/cross-icon.png')} alt='' /></i><span className='c12'>Other advisors’ cards served to <b>your</b> clients? No.</span></div>],
                arrayImages: [require('../../../../images/investor-dashboard/qualified-leads-1.png'), require('../../../../images/investor-dashboard/qualified-leads-2.png'), require('../../../../images/investor-dashboard/qualified-leads-3.png')],
                bottomSection: <div className='img-text df jcc aic bg1 px1rem py0-5rem w80 mla mra br45px c12 fs1-25rem'>To keep standards up, this module is only available to Diligentsia Gold certified companies <span className='icon mx1rem'><img className='w4rem' src={require('../../../../images/advisor-dashboard/banner-certification-4.png')} alt='' /></span></div>,
            }
        },
        'website-widget': {
            howItWorks: {
                subTitle: <div className=''><span className="c3">Investor Applicant Qualification Web Site Widget</span></div>,
                stepImage: require('../../../../images/step-modal-img.png'),
                arrayImages: [require('../../../../images/advisor-dashboard/website-widget-step-1.png'), require('../../../../images/advisor-dashboard/website-widget-step-2.png'), require('../../../../images/advisor-dashboard/website-widget-step-3.png')],
            }
        },
        'anylist-builder': {
            howItWorks: {
                subTitle: <div className=''><span className="c3">AnyList Checklist Builder</span></div>,
                stepImage: require('../../../../images/step-modal-img.png'),
                checkList: [<div className='checklist-box'><i className='icon'><img className='w1rem' src={require('../../../../images/advisor-dashboard/check-icon.png')} alt='' /></i><span className='c12'>Quick and easy for you</span></div>, <div className='checklist-box'><i className='icon'><img className='w1rem' src={require('../../../../images/advisor-dashboard/check-icon.png')} alt='' /></i><span className='c12'>Quick and easy for your target user</span></div>, <div className='checklist-box'><i className='icon'><img className='w1rem' src={require('../../../../images/advisor-dashboard/check-icon.png')} alt='' /></i><span className='c12'>Reusable by the target company</span></div>, <div className='checklist-box'><i className='icon'><img className='w1rem' src={require('../../../../images/advisor-dashboard/check-icon.png')} alt='' /></i><span className='c12'>Company gains value from completion - risks & recommendations</span></div>],
                arrayImages: [require('../../../../images/advisor-dashboard/checklist-builder-1.png'), require('../../../../images/advisor-dashboard/checklist-builder-2.png'), require('../../../../images/advisor-dashboard/checklist-builder-3.png')],
            }
        },

    };

    useEffect(() => {
        let isAnylistBuilder = localStorage.getItem('isAnylistBuilder');
        if (isAnylistBuilder) {
            setTab('AnyListBuilder');
            localStorage.removeItem('isAnylistBuilder');
        }

        if (user?.role) setProfileClass(user["role"] == "Advisor" ? "advisor-profile" : user["role"] == "Investor" ? "investor-profile" : "client-profile");
    }, []);

    useEffect(() => {
        getAdvisors();
        if (user?.role) setProfileClass(user["role"] == "Advisor" ? "advisor-profile" : user["role"] == "Investor" ? "investor-profile" : "client-profile");
    }, [refresh]);

    const saveInvestorMember = async (leadId, memberId) => {
        let response = await postRequest(`/company/saveInvestorMember`, { leadId, memberId });
        if (response["code"] == 200 && response["body"]) getQualifiedLeads(user?.['companyId']?.['_id']);
    };

    const getAuditLogs = async (endpoint, id, tab) => {
        let data;
        if (tab == "permissions") data = { endpoint, firmId: id };
        else if (tab == "allocation") data = { endpoint, firmId: id };
        else if (tab == "advisors") data = { endpoint, userId: id };
        else data = { endpoint };

        const res = await postRequest("/log", data);
        if (res["code"] == 200) {
            await setAuditLogs(res["body"]);
            dispatch(toggleModal({ open: "advisorAuditLogs" }));
        }
    };

    const handleHowItWorks = async (item) => {
        await setSelectedHowItWorks(item);
        dispatch(toggleModal({ open: 'howItWorksModal' }));
    };

    const onModalClose = () => {
        dispatch(toggleModal({ open: '' }));
        setSelectedHowItWorks({});
    };


    return (
        <>
            {spinner.classList.remove("op0")}
            {user?.role && spinner.classList.add("op0")}
            {/* For Company Subscription */}
            <CheckoutForm plan={plan} companyId={selectedCompany['_id']} />

            <div className="container pb2rem df fdc">
                {user?.role &&
                    <HeadingSubheadingContent wrapperClass=" tac"
                        heading={`${user["role"] == "Investor" ? "Portfolio " : "Client "} Index`}
                        subheading={tab == "QualifiedLeads" ? 'DG Qualified Leads' : `${user["role"] == "Investor" ? "Portfolio" : tab === 'RevenueLeadsAllocation' ? "Revenue Leads Allocation" : tab === 'AnyListBuilder' ? "AnyList Builder" : tab} Status`} />
                }
                <div className="df fdc aic advisor-index mt1rem">
                    <TabContext value={tab}>
                        <TabList onChange={(e, value) => setTab(value)} style={{ visibility: user?.role ? "visible" : "hidden" }}>
                            <Tab className="fs1rem fw600" label={`${user["role"] == "Investor" ? "Portfolio" : "Clients"}`} value="Client" />
                            <Tab className="fs1rem fw600" label={<div class="df aic cp"><span className="pr" onClick={() => handleHowItWorks(HOW_TO_POPUPS['team'])} style={{ zIndex: '99' }}><InfoIcon /></span> <span class="ml0-5rem">{user["role"] == "Investor" ? "Investor" : "Advisor"} Team</span></div>} value="Team" />
                            <Tab className="fs1rem fw600" label="Team Permissions" value="Permissions" />
                            {user["role"] == "Advisor" && !user["createdBy"] && <Tab className="fs1rem fw600" label={<div class="df aic cp"><span className="pr" onClick={() => handleHowItWorks(HOW_TO_POPUPS['revenue-lead'])} style={{ zIndex: '99' }}><InfoIcon /></span>  <span class="ml0-5rem">Revenue Leads Allocation</span></div>} value="RevenueLeadsAllocation" />}
                            {user["role"] == "Investor" && <Tab className="fs1rem fw600" label={<div class="df aic cp"><span className="pr" onClick={() => handleHowItWorks(HOW_TO_POPUPS['qualified-leads'])} style={{ zIndex: '99' }}><InfoIcon /></span> <span class="ml0-5rem">Leads</span></div>} value="QualifiedLeads" />}
                            <Tab className="fs1rem fw600" label={<div class="df aic cp"><span className="pr" onClick={() => handleHowItWorks(HOW_TO_POPUPS['website-widget'])} style={{ zIndex: '99' }}><InfoIcon /></span> <span class="ml0-5rem">Web Site Widget</span></div>} value="WebSiteWidget" />
                            <Tab className="fs1rem fw600" label={<div class="df aic cp"><span className="pr" onClick={() => handleHowItWorks(HOW_TO_POPUPS['anylist-builder'])} style={{ zIndex: '99' }}><InfoIcon /></span> <span class="ml0-5rem">AnyList Builder</span></div>} value="AnyListBuilder" />
                        </TabList>
                        <TabPanel className="w100" value="Client">
                            <Clients companies={companies} setSelectedCompany={setSelectedCompany} noClients={noClients} setPlan={setPlan} handleHowItWorks={handleHowItWorks} HOW_TO_POPUPS={HOW_TO_POPUPS} />
                        </TabPanel>
                        <TabPanel className="w100" value="Team">
                            <Team profileClass={profileClass} companies={companies} user={user} team={user['role'] == 'Investor' ? investors : advisors} getAdvisors={getAdvisors} getAuditLogs={getAuditLogs} auditLogs={auditLogs}
                                handleAddAdvisor={handleAddAdvisor} handleAdvisor={handleAdvisor} setSelectedData={setSelectedData} showEditButton={showEditButton} handleEditRoute={handleEditRoute} hasValidAdvisor={hasValidAdvisor} />
                        </TabPanel>
                        <TabPanel className="w100" value="Permissions">
                            <PermissionsClient setRefresh={setRefresh} profileClass={profileClass} companies={companies} user={user} advisors={user["role"] == "Advisor" ? advisors : investors} getAuditLogs={getAuditLogs} auditLogs={auditLogs} sectionsWithChecklist={sectionsWithChecklist} />
                        </TabPanel>
                        <TabPanel className="w100" value="RevenueLeadsAllocation">
                            <RevenueLeadsAllocation setRefresh={setRefresh} getAdvisors={getAdvisors} profileClass={profileClass} companies={companies} user={user} advisors={user["role"] == "Advisor" ? advisors : investors} getAuditLogs={getAuditLogs} auditLogs={auditLogs} />
                        </TabPanel>
                        <TabPanel className="w100" value="QualifiedLeads">
                            <QualifiedLeads profileClass={profileClass} companies={companies} user={user} promptScore={promptScore} qualifiedLeads={qualifiedLeads} investors={investors} saveInvestorMember={saveInvestorMember} />
                        </TabPanel>
                        <TabPanel className="w100" value="AnyListBuilder">
                            <AnyListBuilder company={company} companyId={companyId} profileClass={profileClass} companies={companies} user={user} advisors={investors} getAdvisors={getAdvisors} getAuditLogs={getAuditLogs} auditLogs={auditLogs} />
                        </TabPanel>
                    </TabContext>
                </div>
            </div>

            {/* Advisor/Investor Audit Logs */}
            <Modal open={modal["open"] == "advisorAuditLogs"} onClose={() => dispatch(toggleModal({ open: "" }))} className="backdrop td500 zi4 pf t0 l0 w100 h110vh df jcc aic">
                <div className="mah70 bg1 shadow w100 bsbb df fdc m1rem maw700px br5px">
                    <div className='top-header py1rem px2rem df jcsb aic' style={{ backgroundColor: user['role'] == 'Investor' ? '#AAFED680' : '#FFF6A680', borderRadius: '8px 8px 0 0' }}>
                        <h4 className='fs1-5rem c15 fw500 ttc'>Audit Logs</h4>
                        <img className="w60px" src={require(`../../../../images/logo-icon.svg`)} alt="TODO" />
                    </div>
                    <div className="px3rem py2rem h50" style={{ overflow: 'auto' }}>
                        <div className="shadow bg5 br5px p1rem dg gg0-5rem my0-5rem fs0-75rem">
                            <p className="df fw500 fs1rem ttc">Super {user['role']}:</p>
                            <p className="df fs0-75rem">
                                <span className="fw500 mr0-5rem">Name : </span>{" "}
                                {user?.['companyId']?.['creatorId'] && user['companyId']['creatorId']?.['firstName'] + " " + user['companyId']['creatorId']?.['lastName'] || '-'}
                            </p>
                            <p className="df fs0-75rem">
                                <span className="fw500 mr0-5rem">Email : </span>{" "}
                                {user?.['companyId']?.['creatorId'] && user['companyId']['creatorId']?.['email'] || '-'}
                            </p>
                            <p className="df fs0-75rem">
                                <span className="fw500 mr0-5rem">Title : </span>{" "}
                                {user?.['companyId']?.['creatorId'] && user['companyId']['creatorId']?.['title'] || '-'}
                            </p>
                        </div>
                        <div className="ma oya dg gg0-5rem mb2rem w100">
                            {auditLogs && auditLogs.length > 0 && (
                                auditLogs.map((log) => {
                                    return (
                                        <div className="df my0-5rem">
                                            {log?.["userId"]?.["profile"] ? (
                                                <img className={`ass ml0-5rem br100 w2rem h2rem ${profileClass}`} src={log?.["userId"]?.["profile"]}
                                                    data-tooltip={log?.["userId"]?.["firstName"] ? log?.["userId"]?.["firstName"] + " " + log?.["userId"]?.["lastName"] : "Register to show your name here"} />
                                            ) : (
                                                <span className={`ass w2rem h2rem fw600 mr0-5rem df jcc aic br100 fs0-75rem ${profileClass}`}
                                                    data-tooltip={log?.["userId"]?.["firstName"] ? log?.["userId"]?.["firstName"] + " " + log?.["userId"]?.["lastName"] : "Register to show your name here"}>
                                                    {log?.["userId"]?.["firstName"]?.[0] || "?"}{" "}
                                                    {log?.["userId"]?.["lastName"]?.[0] || "?"}
                                                </span>
                                            )}
                                            <div className="w100">
                                                <span className="fs0-75rem ml1rem">
                                                    <b>{log["payload"]['hasBusinessCard'] ? "Business card interaction" : log["message"]}</b> at{" "}
                                                    <b>{new Date(log.timestamp).toLocaleString()}</b> by{" "}
                                                    <b>
                                                        {log?.["userId"]?.["firstName"]}{" "}
                                                        {log?.["userId"]?.["lastName"]}
                                                    </b>
                                                </span>
                                                {/* Advisor/Investor Logs */}
                                                {log["payload"]?.["permissions"] &&
                                                    log["payload"]?.["action"] && (
                                                        <div className="shadow bg5 br5px p1rem dg gg0-5rem my0-5rem fs0-75rem">
                                                            <p className="df fw500 fs1rem ttc">{user['role']} {log["payload"]["action"]}:</p>
                                                            <p className="df fs0-75rem">
                                                                <span className="fw500 mr0-5rem">Name : </span>{" "}
                                                                {log?.["payload"]?.["firstName"] && log["payload"]["firstName"] + " " + log["payload"]["lastName"]}
                                                            </p>
                                                            <p className="df fs0-75rem">
                                                                <span className="fw500 mr0-5rem">Email : </span>{" "}
                                                                {log?.["payload"]?.["email"] ? log["payload"]?.["email"] : "-"}
                                                            </p>
                                                            <p className="df fs0-75rem">
                                                                <span className="fw500 mr0-5rem">Title : </span>{" "}
                                                                {log?.["payload"]?.["title"] ? log["payload"]?.["title"] : "-"}
                                                            </p>
                                                        </div>
                                                    )}
                                                {/* Client Permissions Logs */}
                                                {log["payload"]?.["sectionNo"] && log['endpoint']?.includes('advisorPermissions') &&
                                                    log["payload"]?.["advisor"] && (
                                                        <div className="shadow bg5 br5px p1rem dg gg0-5rem my0-5rem fs0-75rem">
                                                            <p className="df fw500 fs1rem"> Changes to {`${user['role']}s`} Permissions:</p>
                                                            <p className="df fs0-75rem">
                                                                <span className="fw500 mr0-5rem">{user['role']} : </span>{" "}
                                                                {log?.["payload"]?.["advisor"] && log["payload"]["advisor"]["firstName"] + " " + log["payload"]["advisor"]["lastName"]}
                                                            </p>
                                                            <p className="df fs0-75rem">
                                                                <span className="fw500 mr0-5rem">Title : </span>{" "}
                                                                {log?.["payload"]?.["advisor"] ? log["payload"]?.["advisor"]["title"] : "-"}
                                                            </p>
                                                            <p className="df fs0-75rem">
                                                                <span className="fw500 mr0-5rem">Client : </span>{" "}
                                                                {log?.["payload"]?.["company"] ? log?.["payload"]?.["company"]["cName"] : "-"}
                                                            </p>
                                                            <p className="df fs0-75rem">
                                                                <span className="fw500 mr0-5rem">
                                                                    Permissions{" "}
                                                                    {log["payload"]["status"] ? "Added" : "Removed"}{" "}
                                                                    :{" "}
                                                                </span>
                                                                {log["payload"]["sectionNo"]}
                                                            </p>
                                                        </div>
                                                    )}

                                                {/* Client Allocation Logs */}
                                                {log["payload"]?.["sectionNo"] && log['endpoint']?.includes('allocateRevenueLead') &&
                                                    log["payload"]?.["advisor"] && (
                                                        <div className="shadow bg5 br5px p1rem dg gg0-5rem my0-5rem fs0-75rem">
                                                            <p className="df fw500 fs1rem"> Changes to {`${user['role']}s`} Allocation:</p>
                                                            <p className="df fs0-75rem">
                                                                <span className="fw500 mr0-5rem">{user['role']} : </span>{" "}
                                                                {log?.["payload"]?.["advisor"] && log["payload"]["advisor"]["firstName"] + " " + log["payload"]["advisor"]["lastName"]}
                                                            </p>
                                                            <p className="df fs0-75rem">
                                                                <span className="fw500 mr0-5rem">Title : </span>{" "}
                                                                {log?.["payload"]?.["advisor"] ? log["payload"]?.["advisor"]["title"] : "-"}
                                                            </p>
                                                            <p className="df fs0-75rem">
                                                                <span className="fw500 mr0-5rem">Client : </span>{" "}
                                                                {log?.["payload"]?.["company"] ? log?.["payload"]?.["company"]["cName"] : "-"}
                                                            </p>
                                                            <p className="df fs0-75rem">
                                                                <span className="fw500 mr0-5rem">
                                                                    Allocation{" "}
                                                                    {log["payload"]["status"] ? "Added" : "Removed"}{" "}
                                                                    :{" "}
                                                                </span>
                                                                {log["payload"]["sectionNo"]}
                                                            </p>
                                                        </div>
                                                    )}

                                                {/* Client Interaction with bussiness card Logs */}
                                                {log["payload"]?.["hasBusinessCard"] && log['endpoint']?.includes('allocateRevenueLead') &&
                                                    log["payload"]?.["advisor"] && (
                                                        <div className="shadow bg5 br5px p1rem dg gg0-5rem my0-5rem fs0-75rem">
                                                            <p className="df fw500 fs1rem"> {`Interaction with `}
                                                                {log?.["payload"]?.["advisor"] && log?.["payload"]?.["advisor"]?.["firstName"] + " " + log?.["payload"]?.["advisor"]?.["lastName"] + "" + `' s ` + log?.['payload']?.['clickedOn'] + " " + ` in `}
                                                                Business Card:</p>

                                                            <p className="df fs0-75rem">
                                                                <span className="fw500 mr0-5rem">Advisor Title : </span>{" "}
                                                                {log?.["payload"]?.["advisor"] ? log["payload"]?.["advisor"]?.["title"] : "-"}
                                                            </p>
                                                            <p className="df fs0-75rem">
                                                                <span className="fw500 mr0-5rem">Client : </span>{" "}
                                                                {log?.["payload"]?.["company"] ? log?.["payload"]?.["company"]?.["cName"] : "-"}
                                                            </p>

                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    );
                                })
                            )}
                        </div>
                    </div>
                    <svg className="pa cp" onClick={() => dispatch(toggleModal({ open: "" }))} style={{ left: '50%', transform: 'translateX(-50%)', bottom: '40px' }} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.8" x="4" y="4" width="52" height="52" rx="26" fill="#151313" />
                        <path d="M48.5626 11.4375C44.8913 7.76665 40.214 5.26685 35.1221 4.2542C30.0302 3.24156 24.7523 3.76155 19.9559 5.74843C15.1595 7.73531 11.06 11.0998 8.17569 15.4166C5.29142 19.7333 3.75195 24.8084 3.75195 30C3.75195 35.1917 5.29142 40.2667 8.17569 44.5835C11.06 48.9002 15.1595 52.2647 19.9559 54.2516C24.7523 56.2385 30.0302 56.7585 35.1221 55.7458C40.214 54.7332 44.8913 52.2334 48.5626 48.5625C51.0004 46.1249 52.9342 43.231 54.2535 40.0461C55.5729 36.8611 56.252 33.4474 56.252 30C56.252 26.5526 55.5729 23.1389 54.2535 19.954C52.9342 16.769 51.0004 13.8751 48.5626 11.4375ZM39.3751 36.6375C39.5624 36.8129 39.7117 37.0249 39.8138 37.2603C39.9159 37.4958 39.9686 37.7496 39.9686 38.0063C39.9686 38.2629 39.9159 38.5168 39.8138 38.7522C39.7117 38.9876 39.5624 39.1996 39.3751 39.375C39.1999 39.5488 38.9921 39.6863 38.7636 39.7796C38.5352 39.8729 38.2906 39.9202 38.0438 39.9188C37.5519 39.9167 37.0805 39.7214 36.7313 39.375L30.0001 32.6438L23.3626 39.375C23.0133 39.7214 22.5419 39.9167 22.0501 39.9188C21.8033 39.9202 21.5587 39.8729 21.3302 39.7796C21.1018 39.6863 20.894 39.5488 20.7188 39.375C20.3696 39.0237 20.1736 38.5485 20.1736 38.0531C20.1736 37.5578 20.3696 37.0826 20.7188 36.7313L27.3563 30L20.7188 23.3625C20.4116 23.0038 20.2511 22.5424 20.2694 22.0705C20.2876 21.5986 20.4832 21.151 20.8171 20.8171C21.1511 20.4832 21.5987 20.2875 22.0706 20.2693C22.5425 20.2511 23.0039 20.4116 23.3626 20.7188L30.0001 27.3563L36.6376 20.7188C36.8111 20.539 37.0184 20.3952 37.2476 20.2956C37.4768 20.196 37.7233 20.1424 37.9732 20.1381C38.223 20.1337 38.4713 20.1786 38.7038 20.2702C38.9363 20.3618 39.1484 20.4983 39.3282 20.6719C39.5079 20.8455 39.6517 21.0528 39.7514 21.2819C39.851 21.5111 39.9045 21.7576 39.9089 22.0075C39.9132 22.2573 39.8683 22.5056 39.7767 22.7381C39.6851 22.9706 39.5486 23.1828 39.3751 23.3625L32.6438 30L39.3751 36.6375Z" fill="white" />
                    </svg>
                </div>
            </Modal>

            {/* How It Works Popup */}
            <Modal id="howItWorksModal" open={modal['open'] == 'howItWorksModal'} onClose={() => onModalClose()} className="backdrop td500 zi4 pf t0 l0 w100vw  df jcc aic advisor-dashboard-seemore" >
                <div className="poppins-font-style mah100 bg20 shadow w100 px4rem py2rem df fdc m1rem pr br10px pr" style={{ outline: 'none', zoom: '0.9', maxWidth: '1350px' }}>
                    <div className='df fww w100'>
                        <h2 className='w100 tac c15 fs1-75rem fw500'>How does it work?</h2>
                        {selectedHowItWorks?.['howItWorks']?.['subTitle'] && <h6 className='w100 tac c15 fs1-25rem fw500 mb1rem'>{selectedHowItWorks?.['howItWorks']?.['subTitle']}</h6>}
                        <div className='step-top-box df w100 jcc'>
                            <img src={selectedHowItWorks?.howItWorks?.stepImage} className="w70" style={{ objectFit: 'fill' }} />
                        </div>
                        <div className='step-box-images df jcsb mt1rem mb2rem mla mra'>
                            {(selectedHowItWorks?.['howItWorks']?.['arrayImages'] && selectedHowItWorks?.['howItWorks']?.['arrayImages'].length > 0) && <>{
                                selectedHowItWorks?.['howItWorks']?.['arrayImages'].map(image => (
                                    <div className='step-img df jcc px1rem'><img className='' src={image} alt="ste image" /></div>
                                ))
                            }</>}
                        </div>
                        {selectedHowItWorks?.['howItWorks']?.['checkList'] &&
                            <div className='checklist-area aic df bg1 py0-5rem w90 mla mra br45px mb1rem jcsb bsbb'>
                                {(selectedHowItWorks?.['howItWorks']?.['checkList'] && selectedHowItWorks?.['howItWorks']?.['checkList'].length > 0) && <>{
                                    selectedHowItWorks?.['howItWorks']?.['checkList'].map(array => (
                                        <div className='checklist-inner w100 pr'>{array}</div>
                                    ))
                                }</>}
                            </div>
                        }
                        <div className='df jcc w100'>
                            {selectedHowItWorks?.['howItWorks']?.['bottomSection'] && <p className='fs1-25rem c12 mb1rem tac w100'>{selectedHowItWorks?.['howItWorks']?.['bottomSection']}</p>}
                        </div>
                        <div className='df jcc w100'>
                            <button>{selectedHowItWorks?.['howItWorks']?.['btnContent']}</button>
                        </div>
                    </div>
                    <svg className="pa cp" onClick={() => onModalClose()} style={{ left: '50%', transform: 'translateX(-50%)', bottom: '-80px' }} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.8" x="4" y="4" width="52" height="52" rx="26" fill="#151313" />
                        <path d="M48.5626 11.4375C44.8913 7.76665 40.214 5.26685 35.1221 4.2542C30.0302 3.24156 24.7523 3.76155 19.9559 5.74843C15.1595 7.73531 11.06 11.0998 8.17569 15.4166C5.29142 19.7333 3.75195 24.8084 3.75195 30C3.75195 35.1917 5.29142 40.2667 8.17569 44.5835C11.06 48.9002 15.1595 52.2647 19.9559 54.2516C24.7523 56.2385 30.0302 56.7585 35.1221 55.7458C40.214 54.7332 44.8913 52.2334 48.5626 48.5625C51.0004 46.1249 52.9342 43.231 54.2535 40.0461C55.5729 36.8611 56.252 33.4474 56.252 30C56.252 26.5526 55.5729 23.1389 54.2535 19.954C52.9342 16.769 51.0004 13.8751 48.5626 11.4375ZM39.3751 36.6375C39.5624 36.8129 39.7117 37.0249 39.8138 37.2603C39.9159 37.4958 39.9686 37.7496 39.9686 38.0063C39.9686 38.2629 39.9159 38.5168 39.8138 38.7522C39.7117 38.9876 39.5624 39.1996 39.3751 39.375C39.1999 39.5488 38.9921 39.6863 38.7636 39.7796C38.5352 39.8729 38.2906 39.9202 38.0438 39.9188C37.5519 39.9167 37.0805 39.7214 36.7313 39.375L30.0001 32.6438L23.3626 39.375C23.0133 39.7214 22.5419 39.9167 22.0501 39.9188C21.8033 39.9202 21.5587 39.8729 21.3302 39.7796C21.1018 39.6863 20.894 39.5488 20.7188 39.375C20.3696 39.0237 20.1736 38.5485 20.1736 38.0531C20.1736 37.5578 20.3696 37.0826 20.7188 36.7313L27.3563 30L20.7188 23.3625C20.4116 23.0038 20.2511 22.5424 20.2694 22.0705C20.2876 21.5986 20.4832 21.151 20.8171 20.8171C21.1511 20.4832 21.5987 20.2875 22.0706 20.2693C22.5425 20.2511 23.0039 20.4116 23.3626 20.7188L30.0001 27.3563L36.6376 20.7188C36.8111 20.539 37.0184 20.3952 37.2476 20.2956C37.4768 20.196 37.7233 20.1424 37.9732 20.1381C38.223 20.1337 38.4713 20.1786 38.7038 20.2702C38.9363 20.3618 39.1484 20.4983 39.3282 20.6719C39.5079 20.8455 39.6517 21.0528 39.7514 21.2819C39.851 21.5111 39.9045 21.7576 39.9089 22.0075C39.9132 22.2573 39.8683 22.5056 39.7767 22.7381C39.6851 22.9706 39.5486 23.1828 39.3751 23.3625L32.6438 30L39.3751 36.6375Z" fill="white" />
                    </svg>
                </div>
            </Modal>
        </>
    );
}
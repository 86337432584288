import React from 'react';
import ModalWrapper from '../../../common/Modal/ModalWrapper';
import Button from '../../../common/Button';
import { toggleModal } from '../../../../reducers/modalReducer';
import { useDispatch } from 'react-redux';
import Heading from '../../../common/Heading';
import Paragraph from '../../../common/Paragraph';

const CreateGroupInfo = ({ modalType }) => {

    const dispatch = useDispatch();
    const onCloseHandler = () => {
        dispatch(toggleModal({ open: "" }))
    };

    return (
        <ModalWrapper modalType={modalType} onCloseHandler={onCloseHandler}>
            <div className=''>
                <Heading text="Create Group" />
                <Paragraph text="We recommend that prior to creating a group of companies (you will be asked to identify a parent, and then attach subsidiary and associated companies to it, with their corresponding % ownerships), you FIRST CREATE ALL THE COMPANIES in the group​." />
                <div className='maw180px mxa mb2rem'>
                    <Button
                        btnType="warning"
                        text={"Let's Go!"}
                        onClick={() => dispatch(toggleModal({ open: 'createGroup' }))}
                    />
                </div>
            </div>
        </ModalWrapper>
    );
};

export default CreateGroupInfo;

import React from 'react'

const Paragraph = ({ customClass, text }) => {
    return (
        <p className={`c15 tac op70 mb1-5rem fs13px ${customClass}`} style={{ lineHeight: 1.8, maxWidth: "560px", marginLeft: 'auto', marginRight: 'auto' }}>
            {text}
        </p>
    )
}

export default Paragraph
import React from "react";
import PropTypes from "prop-types";

const Button = ({
    text,
    onClick,
    icon,
    prefixIcon,
    loading = false,
    btnType = "default",
    customClassName = "",
    disabled = false,
    type = "button",
    textClassName = "",
    ...props
}) => {
    const defaultClasses = "br50px py0-75rem cp px2-5rem sm-px16px db fs14px sm-fs12px fw400 tac";
    const btnTypeClasses = btnType === "warning" ? "bg4 c1" : "bg3";
    const disabledClass = (disabled ?? loading) ? "button-disabled op60" : "";

    const combinedClasses = `${defaultClasses} ${btnTypeClasses} ${disabledClass} ${customClassName}`;

    return (
        <button
            onClick={!loading && !disabled ? onClick : null}
            className={combinedClasses}
            type={type}
            {...props}
        >
            {prefixIcon && <span className="mr0-5rem df aic">{prefixIcon}</span>}
            <span className={textClassName}>{text}</span>
            {icon && <span className="ml0-5rem df aic">{icon}</span>}
        </button>
    );
};

Button.propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    icon: PropTypes.element,
    loading: PropTypes.bool,
    btnType: PropTypes.oneOf(["default", "warning"]),
    customClassName: PropTypes.string
};

export default Button;

import Switch from '@mui/material/Switch';
import { useEffect, useState } from "react";
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import { showMessage } from '../../config.js';

export default function ChecklistFeature({ stepTab, handleCreateChecklist, allCompaines, isAIChecklist, checklistObj, setChecklistObj }) {

    let [isDisabled, setIsDisabled] = useState(true);
    let [questionType, setQuestionType] = useState();
    let [totalQuestions, setTotalQuestions] = useState();
    let [isAllCompanies, setIsAllCompanies] = useState(false);
    let [descriptionCount, setDescriptionCount] = useState(0);
    let [accordion, setAccordion] = useState('checklistDetail');
    let [isGenricBespoked, setIsGenricBespoked] = useState(false);
    let [focusAreaCount, setFocusAreaCount] = useState({ focus1: 0, focus2: 0, focus3: 0 });

    useEffect(() => {
        if (allCompaines.length > 0 && checklistObj?.['companies']) {
            if (!(allCompaines.includes(({ _id }) => checklistObj?.['companies']?.[_id]))) setIsAllCompanies(true);
        }
        if (checklistObj?.['moduleDescription']) setDescriptionCount(checklistObj?.['moduleDescription'].length);

        checkBespoked();
    }, []);

    useEffect(() => {
        if (!checklistObj['moduleDescription'] || !totalQuestions || !questionType) setIsDisabled(true);
        else setIsDisabled(false);

    }, [checklistObj]);

    useEffect(() => {
        if (checklistObj?.['step'] > stepTab) {
            setTotalQuestions(checklistObj['totalQuestions']);
            setQuestionType(checklistObj['questionType']);
            setIsDisabled(false);
        }
    }, [stepTab]);

    const handleModuleDescription = (e) => {
        if (e.target.value.length <= 500) {
            setChecklistObj({ ...checklistObj, moduleDescription: e.target.value });
            setDescriptionCount(e.target.value.length);
        }
    };

    const handleAreaFocus = async (e) => {
        if (e.target.value.length <= 200) {
            let focusArea = { ...checklistObj['focusArea'], [e.target.name]: e.target.value }
            setChecklistObj({ ...checklistObj, focusArea });
            focusAreaCount = { ...focusAreaCount, [e.target.name]: e.target.value.length };
            setFocusAreaCount(focusAreaCount);
        }
    };

    const selectAllCompanies = async () => {
        let companies = {};
        allCompaines.map(company => { companies = { ...companies, [company['_id']]: company['cName'] } });
        setIsAllCompanies(false);
        setChecklistObj({ ...checklistObj, companies });
    };

    const selectSingleCompany = async (company) => {
        if (checklistObj?.['companies'] && checklistObj?.['companies']?.[company['_id']]) {
            delete checklistObj['companies'][company['_id']]
            setChecklistObj(checklistObj);

        }
        else {
            let companies = { ...checklistObj['companies'], [company['_id']]: company['cName'] }
            checklistObj = { ...checklistObj, companies };
            setChecklistObj(checklistObj);
        }

        if (Object.keys(checklistObj?.['companies']).length == 1) {
            await checkBespoked();
            setIsGenricBespoked(true);

        }
        else {
            await checkBespoked();
            setIsGenricBespoked(false);
        }
    };

    const checkBespoked = () => {
        if (checklistObj?.['companies'] && Object.keys(checklistObj['companies']).length == 1) {
            setChecklistObj({ ...checklistObj, bespoked: true });
            setIsGenricBespoked(true);
        }
        else {
            setChecklistObj({ ...checklistObj, bespoked: false });
            setIsGenricBespoked(false);
        }
    };

    const handleNextStep = async () => {
        if (!checklistObj['moduleDescription']) return showMessage('Please enter checklist detail');
        else if (!checklistObj['companies'] || Object.keys(checklistObj['companies']).length == 0) return showMessage('Please select your checklist for?');
        else handleCreateChecklist();
    };

    return (
        <>
            <div className='checklist-form-area df fww w100'>
                <div className='checklist-form w100 mb5rem'>
                    <Accordion expanded={accordion == 'checklistDetail'} onChange={() => { (accordion == 'checklistDetail') ? setAccordion('') : setAccordion('checklistDetail') }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography className='fw500'>1. {checklistObj['moduleName'] || 'Your'} checklist detail <span className={isDisabled ? 'c40' : 'c34'}>*</span></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='df fdc jcsb'>
                                <div className='description-box df fww'>
                                    <div className='left-box w60'>
                                        <div className='pr'>
                                            <label className='c15 mb1rem db w100'>Description <span className={isDisabled ? 'c40' : 'c34'}>*</span></label>
                                            <textarea value={checklistObj['moduleDescription'] || ''} rows='3' style={{ resize: 'none' }} name='moduleDescription' className='bss bw2px bo5 w100 br10px p1rem' onChange={(e) => handleModuleDescription(e)}
                                                placeholder='Your checklist description should be concise but comprehensive –​ this will appear as your checklist module description'></textarea>
                                            <span className={`pa b0 r0 mb1rem mr1rem ${descriptionCount >= 499 ? 'c4' : 'c16'}`}>{descriptionCount}/500</span>
                                        </div>
                                    </div>
                                    <div className='right-box w40 bsbb pr1rem pl4rem'>
                                        <div className='tip-box w100 p2rem bg20 w100 bsbb' style={{ marginTop: '-6px', borderRadius: '40px' }}>
                                            <h5 className='c15 fs0-75rem df aic mb1rem' style={{ fontSize: '14px' }}>
                                                <i className='icon mr1rem'><img className="db w2rem" src={require(`../../../images/tip-icon.svg`)} alt="" /></i>
                                                Example
                                            </h5>
                                            <h5 className='c15 fs0-75rem' style={{ fontSize: '14px' }}>Description:</h5>
                                            <p className='c15 fs0-75rem' style={{ fontSize: '12px', color: '#15131380' }}>
                                                A checklist for the creation of a business plan for the set up of a chain of doggy daycare centers in the UK. The focus of the checklist should include
                                                <br />1. Commercial considerations <br />2. Regulatory requirements <br />3. Financing requirements
                                            </p>
                                            <img className="db w80" style={{ marginTop: '-13px' }} src={require(`../../../images/example-img.png`)} alt="" />
                                        </div>
                                    </div>
                                    {/* AI Checklist Show Only */}
                                    {isAIChecklist && <>
                                        <div className='ai-box w100' style={{ marginTop: '-130px' }}>
                                            <h3 className='c15 fs1-2rem lh2 mb1rem'>b) Areas of your specific focus <span className='fs1rem c15 fsi' style={{ color: '#15131330' }}>(optional)</span></h3>
                                            <p className='fs1rem c15 mb1rem db w100 w100 '>To increase relevance​ you are welcome to add up to three areas of your specific focus</p>
                                            {['first', 'second', 'third'].map((item, idx) => {
                                                return <div className='pr mb-05rem'>
                                                    <label className='c15 mb1rem db w100'>0{idx + 1} - Area of focus</label>
                                                    <textarea value={checklistObj?.['focusArea']?.[`focus${idx + 1}`] || ''} onChange={(e) => handleAreaFocus(e)} rows='2' style={{ resize: 'none' }} name={`focus${idx + 1}`} className='bss bw2px bo5 w100 br10px p1rem'
                                                        placeholder={`Write your ${item} objective focus in your own words (up to 200 characters max)`}></textarea>
                                                    <span className={`pa b0 r0 mb1rem mr1rem ${focusAreaCount[`focus${idx + 1}`] >= 199 ? 'c4' : 'c16'}`}>{focusAreaCount[`focus${idx + 1}`]}/200</span>
                                                </div>
                                            })}
                                        </div>
                                    </>
                                    }
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={accordion == 'checklistFor'} onChange={() => { (accordion == 'checklistFor') ? setAccordion('') : setAccordion('checklistFor') }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography className='fw500'>2. Who is your checklist for? <span className={isDisabled ? 'c40' : 'c34'}>*</span></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='df fdc jcsb'>
                                <div className='description-box df fww mt0-5rem'>
                                    <div className='left-box w50'>
                                        {/* All my/our companies */}
                                        <div className='pr'>
                                            <input id='companiesAlready' name='companies' style={{ height: '19px', width: '19px', top: '4px' }} type='radio' className='cp pr'
                                                defaultChecked={allCompaines.includes(({ _id }) => checklistObj?.['companies']?.[_id])} onChange={() => selectAllCompanies()} />
                                            <label className='fw400 fs1-125rem ml1rem c15' for='companiesAlready' style={{ lineHeight: '22.39px' }}>All my/our companies already on the platform ​</label>
                                        </div>
                                        {/* One or more specific company */}
                                        {allCompaines.length > 0 && <>
                                            <div className='pr mt1rem'>
                                                <input id='specificCompany' name='companies' style={{ height: '19px', width: '19px', top: '4px' }} type='radio' className='cp pr'
                                                    checked={isAllCompanies} onChange={async () => { await setChecklistObj({ ...checklistObj, companies: {} }), setIsAllCompanies(true) }} />
                                                <label className='fw400 fs1-125rem ml1rem c15' for='specificCompany' style={{ lineHeight: '22.39px' }}>One or more specific company already on our platform:​ </label>
                                            </div>
                                            {isAllCompanies &&
                                                <div className='pr ml2rem nt0-5rem' style={{ height: '15vh', overflow: 'auto' }}>
                                                    {allCompaines.map(company => {
                                                        return (
                                                            <label for={company['_id']} className='df cp py0-5rem' onClick={() => selectSingleCompany(company)}>
                                                                <span className='checkbox w2rem h2rem pr'><input defaultChecked={checklistObj?.['companies']?.[company['_id']]} id={company['_id']} className='new-shadow bg1 bo5 bw2px bss cp td250 w2rem h2rem br8px' type='checkbox' /></span>
                                                                <span className='w100 fs1rem lh3 ml1rem usn'>{company['cName']}</span>
                                                            </label>
                                                        )
                                                    })}
                                                </div>
                                            }
                                        </>}
                                        {/* A new company */}
                                        {/* <div className='pr mt1rem'>
                                            <input id='companiesAlready' name='registerAs' style={{ height: '19px', width: '19px', top: '4px' }} type='radio' value='companiesAlready' className='pr'
                                                checked={registerAs == 'companiesAlready'}
                                            />
                                            <label className='fw400 fs1-125rem ml1rem c15' for='companiesAlready' style={{ lineHeight: '22.39px' }}>A new company not already on our platform <b>(M&A)</b></label>
                                        </div>
                                        <div className='pr mt0-5rem ml2rem'>
                                            <label className='c15 mb1rem db w100'>Add a UK company </label>
                                            <div className='df aic'>
                                                <span className='pa ml1rem mt0-5rem'>
                                                    <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0_4380_344)">
                                                            <path d="M23.3125 16.5312H7.6875V14.9688H8.46875V3.25C8.46875 3.03906 8.54688 2.84375 8.69531 2.69531C8.84375 2.54687 9.03906 2.46875 9.25 2.46875H20.1875C20.3906 2.46875 20.5937 2.55469 20.7422 2.69531C20.8906 2.84375 20.9688 3.03906 20.9688 3.25V7.15625H22.5312V14.9688H23.3125V16.5312ZM19.4062 14.9688H20.9688V8.71875H16.2812V14.9688H17.8438V10.2812H19.4062V14.9688ZM19.4062 7.15625V4.03125H10.0312V14.9688H14.7188V7.15625H19.4062ZM11.5938 8.71875H13.1562V10.2812H11.5938V8.71875ZM11.5938 11.8438H13.1562V13.4062H11.5938V11.8438ZM11.5938 5.59375H13.1562V7.15625H11.5938V5.59375Z" fill="#151313" />
                                                        </g>
                                                        <path d="M11.0312 11.8096C10.1867 10.1648 8.49009 9.00008 6.5 9V11.5728C7.90862 11.5729 9.05412 12.6816 9.05414 14.0903C9.05417 15.4818 7.8991 16.6814 6.5 16.6815V22C8.29372 20.1308 10.3582 18.0761 11.2615 15.6161C11.7131 14.3859 11.6289 12.9736 11.0312 11.8096Z" fill="#D80027" />
                                                        <path d="M1.74033 15.6161C2.64361 18.0761 4.70804 20.1308 6.50179 22V16.6815C5.10266 16.6814 3.94762 15.4818 3.94764 14.0903C3.94767 12.6817 5.09317 11.5729 6.50179 11.5728V9C4.5117 9.00008 2.81507 10.1648 1.97058 11.8096C1.37291 12.9736 1.28866 14.3859 1.74033 15.6161Z" fill="#B50525" />
                                                        <path d="M6.5 18.1094C8.74366 18.1094 10.5625 16.2905 10.5625 14.0469C10.5625 11.8032 8.74366 9.98438 6.5 9.98438C4.25634 9.98438 2.4375 11.8032 2.4375 14.0469C2.4375 16.2905 4.25634 18.1094 6.5 18.1094Z" fill="#F0F0F0" />
                                                        <path d="M3.27799 11.5742C2.95886 11.9894 2.71826 12.4679 2.57812 12.9878H4.69154L3.27799 11.5742Z" fill="#0052B4" />
                                                        <path d="M10.422 12.9878C10.2819 12.4679 10.0413 11.9894 9.72217 11.5742L8.30859 12.9878H10.422Z" fill="#0052B4" />
                                                        <path d="M2.57812 15.1074C2.71823 15.6273 2.95886 16.1058 3.27799 16.5209L4.69149 15.1074H2.57812Z" fill="#0052B4" />
                                                        <path d="M8.97217 10.8249C8.557 10.5057 8.07847 10.2651 7.55859 10.125V12.2384L8.97217 10.8249Z" fill="#0052B4" />
                                                        <path d="M4.02734 17.271C4.44251 17.5901 4.92104 17.8307 5.44092 17.9708V15.8574L4.02734 17.271Z" fill="#0052B4" />
                                                        <path d="M5.44087 10.125C4.92102 10.2651 4.44248 10.5057 4.02734 10.8249L5.44087 12.2384V10.125Z" fill="#0052B4" />
                                                        <path d="M7.55859 17.9708C8.07844 17.8307 8.55698 17.5901 8.97212 17.271L7.55859 15.8574V17.9708Z" fill="#0052B4" />
                                                        <path d="M8.30859 15.1074L9.72212 16.5209C10.0412 16.1058 10.2819 15.6272 10.422 15.1074H8.30859Z" fill="#0052B4" />
                                                        <path d="M10.5281 13.517H7.0299V10.0188C6.85646 9.99618 6.67961 9.98438 6.50003 9.98438C6.32041 9.98438 6.14359 9.99618 5.97015 10.0188V13.517V13.517H2.4719C2.44931 13.6905 2.4375 13.8673 2.4375 14.0469C2.4375 14.2265 2.44928 14.4033 2.47188 14.5768H5.9701L5.97012 18.075C6.14357 18.0976 6.32041 18.1094 6.5 18.1094C6.67961 18.1094 6.85643 18.0976 7.02988 18.075V14.5768L10.5281 14.5768C10.5507 14.4034 10.5625 14.2265 10.5625 14.0469C10.5625 13.8673 10.5507 13.6905 10.5281 13.517Z" fill="#D80027" />
                                                        <path d="M7.55859 15.1074L9.37141 16.9203C9.45476 16.8369 9.53431 16.7498 9.61021 16.6594L8.05818 15.1074H7.55859V15.1074Z" fill="#D80027" />
                                                        <path d="M5.44175 15.1074L3.62891 16.9202C3.71224 17.0036 3.7994 17.0831 3.88969 17.159L5.44172 15.607V15.1074H5.44175Z" fill="#D80027" />
                                                        <path d="M5.43833 12.9867V12.9866L3.62552 11.1738C3.54216 11.2572 3.46261 11.3443 3.38672 11.4346L4.93877 12.9867H5.43833Z" fill="#D80027" />
                                                        <path d="M7.55859 12.9872L9.37143 11.1743C9.28808 11.091 9.20094 11.0114 9.11062 10.9355L7.55859 12.4876V12.9872Z" fill="#D80027" />
                                                        <path d="M6.49791 9.55078C4.62547 9.55078 2.90638 10.7582 2.26757 12.5166C1.6241 14.2882 2.1878 16.321 3.63801 17.5203C5.09127 18.7223 7.20206 18.8819 8.81714 17.9049C10.4326 16.9277 11.2858 14.9931 10.906 13.1423C10.534 11.3298 9.02653 9.89137 7.19727 9.60502C6.96593 9.56881 6.73183 9.55078 6.49791 9.55078ZM10.1202 15.6821C9.35234 17.3835 7.43558 18.343 5.61388 17.9266C4.21194 17.6061 3.06507 16.5107 2.6742 15.1286C2.27433 13.7155 2.70792 12.1629 3.77659 11.1569C4.93273 10.0685 6.67549 9.77132 8.12654 10.4257C9.85889 11.2069 10.8172 13.1715 10.352 15.0175C10.2946 15.2454 10.2162 15.4676 10.1202 15.6821C10.1593 15.5946 10.0672 15.7995 10.1202 15.6821Z" fill="white" />
                                                        <defs>
                                                            <clipPath id="clip0_4380_344">
                                                                <rect width="18" height="18" fill="white" transform="translate(7)" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </span>
                                                <input
                                                    name='searchCompany'
                                                    id='searchCompany'
                                                    type='text'
                                                    placeholder='Search for company name/number'
                                                    className='pl3rem bss bw2px bo5 w100 br10px p1rem'
                                                />
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className='right-box w50 bsbb pr1rem pl4rem'>
                                        <div className='tip-box w100 p2rem bg20 w100 bsbb' style={{ marginTop: '-6px', borderRadius: '40px' }}>
                                            <h5 className='c15 fs0-75rem df aic mb1rem' style={{ fontSize: '14px' }}>
                                                <i className='icon mr1rem'>
                                                    <img className="db w2rem" src={require(`../../../images/tip-icon.svg`)} alt="" />
                                                </i>
                                                Top Tip
                                            </h5>
                                            <h5 className='c15 fs0-75rem' style={{ fontSize: '14px' }}>
                                                Option 1:
                                            </h5>
                                            <ul className='ml1rem mx0-5rem'>
                                                <li className='c15 fs0-75rem' style={{ fontSize: '12px', color: '#15131380' }}>If you choose this option the checklist will appear on the ​Dashboard of each company​</li>
                                                <li className='c15 fs0-75rem' style={{ fontSize: '12px', color: '#15131380' }}>Any checklist created will be generic, ie. not capable of​ being bespoked to that company’s business activities, ​stage of  development or objectives ​​</li>
                                            </ul>
                                            <h5 className='c15 fs0-75rem mt0-5rem' style={{ fontSize: '14px' }}>
                                                Option 2:
                                            </h5>
                                            <ul className='ml1rem mx0-5rem'>
                                                <li className='c15 fs0-75rem' style={{ fontSize: '12px', color: '#15131380' }}>If you choose a single company here, we can bespoke it​ to its business activities, stage of  development and​ objectives.  </li>
                                                <li className='c15 fs0-75rem' style={{ fontSize: '12px', color: '#15131380' }}>If you choose more than one company,​ a generic checklist, suitable for all, will be created. </li>
                                                <li className='c15 fs0-75rem' style={{ fontSize: '12px', color: '#15131380' }}>The ​Checklists will appear on the dashboards of the selected Companies.</li>
                                            </ul>
                                            {/* <h5 className='c15 fs0-75rem mt0-5rem' style={{ fontSize: '14px' }}>
                                                Option 3:
                                            </h5>
                                            <ul className='ml1rem mx0-5rem'>
                                                <li className='c15 fs0-75rem' style={{ fontSize: '12px', color: '#15131380' }}>You can choose later whether you want a generic or​ bespoked checklist for this company’s business​ activitiies, stage of development and objectives.​</li>
                                            </ul> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    {(isAIChecklist && isGenricBespoked) &&
                        <Accordion expanded={accordion == 'checklistType'} onChange={() => { (accordion == 'checklistType') ? setAccordion('') : setAccordion('checklistType') }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className='fw500'>3. Specify your checklist type</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className='df fdc jcsb'>
                                    <div className='description-box df fww'>
                                        <div className='left-box w40'>
                                            {/* <h5 className='c15 fs1rem df aic mb1rem'>External V Internal? <span className='c40'>*</span></h5>
                                        <div className='switch-area df aic w50'>
                                            <p className='c15'>External</p>
                                            <Switch
                                                name='externalInternal'
                                                className="success w100"
                                            />
                                            <p className='c15'>Internal</p>
                                        </div> */}
                                            <h5 className='c15 fs1rem df aic mt1rem'>Generic V Bespoked?</h5>
                                            <div className='switch-area df aic w50'>
                                                <p className='c15'>Generic</p>
                                                <Switch
                                                    name='genericBespoked'
                                                    className="success w100"
                                                    defaultChecked={checklistObj['bespoked']}
                                                    onChange={(e) => setChecklistObj({ ...checklistObj, bespoked: e.target.checked })}
                                                />
                                                <p className='c15'>Bespoked</p>
                                            </div>
                                        </div>
                                        <div className='right-box w60 bsbb pr1rem pl4rem'>
                                            {/* <div className='tip-box w100 p2rem bg20 w100 bsbb' style={{ marginTop: '-6px', borderRadius: '40px' }}>
                                            <h5 className='c15 fs0-75rem df aic mb1rem' style={{ fontSize: '14px' }}>
                                                <i className='icon mr1rem'>
                                                    <img className="db w2rem" src={require(`../../../images/tip-icon.svg`)} alt="" />
                                                </i>
                                                Top Tip
                                            </h5>
                                            <p className='c15 fs0-75rem' style={{ fontSize: '12px', color: '#15131380' }}>
                                                Select “Internal” if you want your checklist​ to focus on internal processes rather than​ diligencing specific areas of your business. ​Files added to internal process checklists​ will NOT be added to the Data Repository.​<br /><br />
                                                Select “External” if you want to diligence ​part of a business and want to support with​ files, risk scoring etc.​<br /><br />
                                                If you are not sure, we suggest you leave the​ default to “Internal”.
                                            </p>
                                        </div> */}
                                            <div className='tip-box w100 p2rem bg20 w100 bsbb mt2rem' style={{ marginTop: '2rem', borderRadius: '40px' }}>
                                                <h5 className='c15 fs0-75rem df aic mb1rem' style={{ fontSize: '14px' }}>
                                                    <i className='icon mr1rem'>
                                                        <img className="db w2rem" src={require(`../../../images/tip-icon.svg`)} alt="" />
                                                    </i>
                                                    Top Tip
                                                </h5>
                                                <p className='c15 fs0-75rem' style={{ fontSize: '12px', color: '#15131380' }}>
                                                    You can choose for your checklist to be “generic”​ i.e. can be applied to any/all of your companies​– it Is NOT tailored for a company’s industry, stage​ of development or objectives. ​<br /><br />
                                                    However, if you want your checklist to be​ tailored to a specific company’s circumstances ​then you should select “bespoked”.​ <br /><br />
                                                    If you selected “Multiple Companies” on the ​previous page, the default “Generic” will be ​applied here.​ Similarly, if you selected a new company, the checklist WILL be bespoked.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    }
                    <Accordion expanded={accordion == 'totalQuestions'} onChange={() => { (accordion == 'totalQuestions') ? setAccordion('') : setAccordion('totalQuestions') }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography className='fw500'>4. Specify number of questions <span className={isDisabled ? 'c40' : 'c34'}>*</span></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='description-box df fww'>
                                <div className='df w100 my1rem'>
                                    {[20, 50, 100].map((item, idx) => {
                                        return (
                                            <div className='pr objective-area w80px mr0-5rem'>
                                                <div className='pr mb1rem'>
                                                    <input
                                                        type='radio'
                                                        value={item}
                                                        className='cp pr'
                                                        id={`totalQuestions${idx}`}
                                                        name={`total-questions-${idx}`}
                                                        style={{ height: '19px', width: '19px', top: '4px' }}
                                                        checked={totalQuestions == item}
                                                        onChange={(e) => { setTotalQuestions(e.target.value), setChecklistObj({ ...checklistObj, totalQuestions: e.target.value }) }}
                                                    />
                                                    <label className='fw400 fs1-125rem ml1rem c15' for={`totalQuestions${idx}`} style={{ lineHeight: '22.39px' }}>{item}</label>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <h5 className='c15 fs1rem df aic mb1rem fw400 w100'>There will always be 10 sections​</h5>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={accordion == 'questionnaireStyle'} onChange={() => { (accordion == 'questionnaireStyle') ? setAccordion('') : setAccordion('questionnaireStyle') }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography className='fw500'>5. Questionnaire Style <span className={isDisabled ? 'c40' : 'c34'}>*</span></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='description-box df fww'>
                                <h5 className='c15 fs1rem df aic mb1rem fw400 w100'>Choose your style of questionnaire</h5>
                                <div className='df w100'>
                                    {['Objective', 'Quantitative', 'Subjective'].map(item => {
                                        return <>
                                            <div className={`pr objective-area w25 mr2rem ${item != 'Objective' && ' op50'}`}>
                                                <div className='pr mb1rem'>
                                                    <input
                                                        id={item}
                                                        type='radio'
                                                        className='pr'
                                                        checked={questionType == item}
                                                        style={{ height: '19px', width: '19px', top: '4px', cursor: item != 'Objective' ? 'not-allowed' : 'pointer' }}
                                                        onChange={(e) => { if (item == 'Objective') setQuestionType(item), setChecklistObj({ ...checklistObj, questionType: item }) }}
                                                    />
                                                    <label className='fw400 fs1-125rem ml1rem c15' for={item} style={{ lineHeight: '22.39px' }}>{item} {item != 'Objective' && <span className='c16 fs1rem'>(coming soon)</span>}</label>
                                                </div>
                                                <div className='bo3 bw1px bss bsbb p2rem br20px' style={{ backgroundColor: '#F6F7FE', minHeight: '345px' }}>
                                                    <div className='objective-box'>
                                                        <h4 className='fw400 fs1rem mb1rem'>01- Question</h4>
                                                        {item == 'Objective' && <>
                                                            <div className='checkbox-area df jcsb aic'>
                                                                {['Yes', 'No', 'N/A'].map(item => {
                                                                    return <label for={item} className='py0-5rem df cp mr1rem'>
                                                                        <span className='checkbox w2rem h2rem pr'><input disabled id={item} className='new-shadow bg1 bo5 bw2px bss cp td250 w2rem h2rem br8px' type='checkbox' /></span>
                                                                        <span className='w100 fs1rem lh3 ml1rem usn'>{item}</span>
                                                                    </label>
                                                                })}
                                                            </div>
                                                            <ul className='list-fit mt1rem'>
                                                                {['Quick and easy (companies more likely to complete)', 'Good for risk scoring', 'Will build up a less in depth picture', 'Risk/recommendations including Checklist Report'].map(item => {
                                                                    return <li className='df aic fs0-75rem mb0-5rem'>
                                                                        {(item == 'Will build up a less in depth picture')
                                                                            ? <i className='icon mr1rem'>
                                                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M2.02395 15.143C2.56575 13.7876 4.22715 11.336 6.03795 8.89156C4.32975 6.93316 2.77995 5.14936 2.37675 4.64536C1.56315 3.62836 1.61895 3.19276 3.14355 3.18556C4.23615 3.18016 4.61415 3.04876 5.33775 3.80656C5.76255 4.25116 6.67695 5.34016 7.72815 6.66676C9.40935 4.49956 10.9664 2.63116 11.6216 1.94356C12.5576 0.962556 13.0472 1.13356 14.462 1.14076C16.4348 1.15156 16.5086 1.71496 15.4538 3.03076C14.867 3.76516 12.4028 6.59476 9.87915 9.48556C11.1842 11.2658 12.3506 13.0082 12.7448 13.9964C13.2668 15.3032 12.1058 15.8738 11.1176 14.7218C10.574 14.0864 9.46695 12.8192 8.21595 11.3864C6.46275 13.3934 4.88235 15.2042 4.12995 16.0826C2.85195 17.573 1.34715 16.835 2.02395 15.143Z" fill="#ED1C24" />
                                                                                </svg>

                                                                            </i>
                                                                            : <i className='icon mr1rem'>
                                                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <g clip-path="url(#clip0_4428_3023)">
                                                                                        <path d="M7.14602 10.8459C7.05212 10.9467 6.90152 10.9698 6.78122 10.9035L1.31882 7.88012C1.23662 7.83452 1.15712 7.81532 1.08332 7.81532C0.936616 7.81532 0.812416 7.89092 0.730816 7.98392C0.607816 8.12402 0.527116 8.37272 0.704416 8.61272L6.95792 17.0742C7.12172 17.2956 7.37312 17.4141 7.64192 17.3985C7.91402 17.3829 8.14982 17.2368 8.28812 16.9971L17.3253 1.35422C17.4909 1.06772 17.3568 0.823519 17.1921 0.701419C17.0277 0.579919 16.7754 0.531619 16.5609 0.761119L7.14602 10.8459Z" fill="url(#paint0_linear_4428_3023)" />
                                                                                    </g>
                                                                                    <defs>
                                                                                        <linearGradient id="paint0_linear_4428_3023" x1="9.00152" y1="17.3999" x2="9.00152" y2="0.598097" gradientUnits="userSpaceOnUse">
                                                                                            <stop stop-color="#00E54E" />
                                                                                            <stop offset="0.18" stop-color="#00E54E" />
                                                                                            <stop offset="0.73" stop-color="#15D757" />
                                                                                            <stop offset="1" stop-color="#04C747" />
                                                                                        </linearGradient>
                                                                                        <clipPath id="clip0_4428_3023">
                                                                                            <rect width="18" height="18" fill="white" />
                                                                                        </clipPath>
                                                                                    </defs>
                                                                                </svg>
                                                                            </i>

                                                                        }
                                                                        {item}
                                                                    </li>
                                                                })}
                                                            </ul>
                                                        </>}
                                                        {item == 'Quantitative' && <>
                                                            <div className='checkbox-area df jcsb aic'>
                                                                {['1', '2', '3', '4', '5'].map(item => {
                                                                    return <label for={item} className='py0-5rem df fdc cp mr1rem'>
                                                                        <span className='w100 fs1rem lh3 usn tac mb0-5rem'>{item}</span>
                                                                        <span className='checkbox w2rem h2rem pr'><input disabled id={item} className='new-shadow bg1 bo5 bw2px bss cp td250 w2rem h2rem br8px' type='checkbox' /></span>
                                                                    </label>
                                                                })}
                                                            </div>
                                                            <div className='df jcsb aic w100'>
                                                                <p className='fs0-75rem c40'>Poor</p>
                                                                <p className='fs0-75rem c34'>Excellent</p>
                                                            </div>
                                                            <ul className='list-fit mt1rem'>
                                                                {['Richer than yes/no', 'Good for risk scoring (but capable of over-optimism)', 'Will build up a less in depth picture', 'Risk/recommendations including Checklist Report'].map(item => {
                                                                    return <li className='df aic fs0-75rem mb0-5rem'>
                                                                        {(item == 'Will build up a less in depth picture')
                                                                            ? <i className='icon mr1rem'>
                                                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M2.02395 15.143C2.56575 13.7876 4.22715 11.336 6.03795 8.89156C4.32975 6.93316 2.77995 5.14936 2.37675 4.64536C1.56315 3.62836 1.61895 3.19276 3.14355 3.18556C4.23615 3.18016 4.61415 3.04876 5.33775 3.80656C5.76255 4.25116 6.67695 5.34016 7.72815 6.66676C9.40935 4.49956 10.9664 2.63116 11.6216 1.94356C12.5576 0.962556 13.0472 1.13356 14.462 1.14076C16.4348 1.15156 16.5086 1.71496 15.4538 3.03076C14.867 3.76516 12.4028 6.59476 9.87915 9.48556C11.1842 11.2658 12.3506 13.0082 12.7448 13.9964C13.2668 15.3032 12.1058 15.8738 11.1176 14.7218C10.574 14.0864 9.46695 12.8192 8.21595 11.3864C6.46275 13.3934 4.88235 15.2042 4.12995 16.0826C2.85195 17.573 1.34715 16.835 2.02395 15.143Z" fill="#ED1C24" />
                                                                                </svg>
                                                                            </i>
                                                                            : <i className='icon mr1rem'>
                                                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <g clip-path="url(#clip0_4428_3023)">
                                                                                        <path d="M7.14602 10.8459C7.05212 10.9467 6.90152 10.9698 6.78122 10.9035L1.31882 7.88012C1.23662 7.83452 1.15712 7.81532 1.08332 7.81532C0.936616 7.81532 0.812416 7.89092 0.730816 7.98392C0.607816 8.12402 0.527116 8.37272 0.704416 8.61272L6.95792 17.0742C7.12172 17.2956 7.37312 17.4141 7.64192 17.3985C7.91402 17.3829 8.14982 17.2368 8.28812 16.9971L17.3253 1.35422C17.4909 1.06772 17.3568 0.823519 17.1921 0.701419C17.0277 0.579919 16.7754 0.531619 16.5609 0.761119L7.14602 10.8459Z" fill="url(#paint0_linear_4428_3023)" />
                                                                                    </g>
                                                                                    <defs>
                                                                                        <linearGradient id="paint0_linear_4428_3023" x1="9.00152" y1="17.3999" x2="9.00152" y2="0.598097" gradientUnits="userSpaceOnUse">
                                                                                            <stop stop-color="#00E54E" />
                                                                                            <stop offset="0.18" stop-color="#00E54E" />
                                                                                            <stop offset="0.73" stop-color="#15D757" />
                                                                                            <stop offset="1" stop-color="#04C747" />
                                                                                        </linearGradient>
                                                                                        <clipPath id="clip0_4428_3023">
                                                                                            <rect width="18" height="18" fill="white" />
                                                                                        </clipPath>
                                                                                    </defs>
                                                                                </svg>
                                                                            </i>
                                                                        }
                                                                        {item}
                                                                    </li>
                                                                })}
                                                            </ul>
                                                        </>}
                                                        {item == 'Subjective' && <>
                                                            <div className='pr mt1rem'>
                                                                <textarea
                                                                    disabled
                                                                    type='text'
                                                                    style={{ resize: 'none' }}
                                                                    placeholder='Enter your answer here 200 characters max'
                                                                    className='bg1 bss bw2px bo5 w100 br20px p1rem'
                                                                ></textarea>
                                                            </div>
                                                            <ul className='list-fit mt1rem'>
                                                                {['Good for in depth analysis', 'Time-consuming for company', 'Risk scoring not accurate', 'Risk/recommendations including Checklist Report'].map(item => {
                                                                    return <li className='df aic fs0-75rem mb0-5rem'>
                                                                        {(item == 'Time-consuming for company' || item == 'Risk scoring not accurate')
                                                                            ? <i className='icon mr1rem'>
                                                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M2.02395 15.143C2.56575 13.7876 4.22715 11.336 6.03795 8.89156C4.32975 6.93316 2.77995 5.14936 2.37675 4.64536C1.56315 3.62836 1.61895 3.19276 3.14355 3.18556C4.23615 3.18016 4.61415 3.04876 5.33775 3.80656C5.76255 4.25116 6.67695 5.34016 7.72815 6.66676C9.40935 4.49956 10.9664 2.63116 11.6216 1.94356C12.5576 0.962556 13.0472 1.13356 14.462 1.14076C16.4348 1.15156 16.5086 1.71496 15.4538 3.03076C14.867 3.76516 12.4028 6.59476 9.87915 9.48556C11.1842 11.2658 12.3506 13.0082 12.7448 13.9964C13.2668 15.3032 12.1058 15.8738 11.1176 14.7218C10.574 14.0864 9.46695 12.8192 8.21595 11.3864C6.46275 13.3934 4.88235 15.2042 4.12995 16.0826C2.85195 17.573 1.34715 16.835 2.02395 15.143Z" fill="#ED1C24" />
                                                                                </svg>

                                                                            </i>
                                                                            : <i className='icon mr1rem'>
                                                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <g clip-path="url(#clip0_4428_3023)">
                                                                                        <path d="M7.14602 10.8459C7.05212 10.9467 6.90152 10.9698 6.78122 10.9035L1.31882 7.88012C1.23662 7.83452 1.15712 7.81532 1.08332 7.81532C0.936616 7.81532 0.812416 7.89092 0.730816 7.98392C0.607816 8.12402 0.527116 8.37272 0.704416 8.61272L6.95792 17.0742C7.12172 17.2956 7.37312 17.4141 7.64192 17.3985C7.91402 17.3829 8.14982 17.2368 8.28812 16.9971L17.3253 1.35422C17.4909 1.06772 17.3568 0.823519 17.1921 0.701419C17.0277 0.579919 16.7754 0.531619 16.5609 0.761119L7.14602 10.8459Z" fill="url(#paint0_linear_4428_3023)" />
                                                                                    </g>
                                                                                    <defs>
                                                                                        <linearGradient id="paint0_linear_4428_3023" x1="9.00152" y1="17.3999" x2="9.00152" y2="0.598097" gradientUnits="userSpaceOnUse">
                                                                                            <stop stop-color="#00E54E" />
                                                                                            <stop offset="0.18" stop-color="#00E54E" />
                                                                                            <stop offset="0.73" stop-color="#15D757" />
                                                                                            <stop offset="1" stop-color="#04C747" />
                                                                                        </linearGradient>
                                                                                        <clipPath id="clip0_4428_3023">
                                                                                            <rect width="18" height="18" fill="white" />
                                                                                        </clipPath>
                                                                                    </defs>
                                                                                </svg>
                                                                            </i>
                                                                        }
                                                                        {item}
                                                                    </li>
                                                                })}
                                                            </ul>
                                                        </>}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    })}
                                </div>

                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={accordion == 'riskScoring'} onChange={() => { (accordion == 'riskScoring') ? setAccordion('') : setAccordion('riskScoring') }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography className='fw500'>6. Include risk scoring?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='df fdc jcsb'>
                                <div className='description-box df fww mt0-5rem'>
                                    <div className='left-box w50'>
                                        <div className='pr'>
                                            <input
                                                id='Include'
                                                name='include'
                                                style={{ height: '19px', width: '19px', top: '4px' }}
                                                type='radio'
                                                className='cp pr'
                                                checked={checklistObj['riskScoring']}
                                                onChange={() => setChecklistObj({ ...checklistObj, riskScoring: true })}
                                            />
                                            <label className='fw400 fs1-125rem ml1rem c15' for='Include' style={{ lineHeight: '22.39px' }}>Include ​</label>
                                        </div>
                                        <div className='pr mt1rem'>
                                            <input
                                                id='NotInclude'
                                                name='not-include'
                                                style={{ height: '19px', width: '19px', top: '4px' }}
                                                type='radio'
                                                className='cp pr'
                                                checked={!checklistObj['riskScoring']}
                                                onChange={() => setChecklistObj({ ...checklistObj, riskScoring: false })}
                                            />
                                            <label className='fw400 fs1-125rem ml1rem c15 fw500' for='NotInclude' style={{ lineHeight: '22.39px' }}>Do <span className='c4'>NOT</span> include​ </label>
                                        </div>
                                    </div>
                                    <div className='right-box w50 bsbb pr1rem pl4rem'>
                                        <div className='tip-box w100 p2rem bg20 w100 bsbb' style={{ marginTop: '-6px', borderRadius: '40px' }}>
                                            <h5 className='c15 fs0-75rem df aic' style={{ fontSize: '14px' }}>
                                                Example
                                            </h5>
                                            <img className="db mb1rem" src={require(`../../../images/module-risk.png`)} alt="" />
                                            <h5 className='c15 fs0-75rem df aic mb1rem' style={{ fontSize: '14px' }}>
                                                <i className='icon mr1rem'>
                                                    <img className="db w2rem" src={require(`../../../images/tip-icon.svg`)} alt="" />
                                                </i>
                                                Top Tip
                                            </h5>
                                            <ul className='list-fit mt1rem'>
                                                <li className='df aic fs0-75rem mb0-5rem'>
                                                    <i className='icon mr1rem'>
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clip-path="url(#clip0_4428_3023)">
                                                                <path d="M7.14602 10.8459C7.05212 10.9467 6.90152 10.9698 6.78122 10.9035L1.31882 7.88012C1.23662 7.83452 1.15712 7.81532 1.08332 7.81532C0.936616 7.81532 0.812416 7.89092 0.730816 7.98392C0.607816 8.12402 0.527116 8.37272 0.704416 8.61272L6.95792 17.0742C7.12172 17.2956 7.37312 17.4141 7.64192 17.3985C7.91402 17.3829 8.14982 17.2368 8.28812 16.9971L17.3253 1.35422C17.4909 1.06772 17.3568 0.823519 17.1921 0.701419C17.0277 0.579919 16.7754 0.531619 16.5609 0.761119L7.14602 10.8459Z" fill="url(#paint0_linear_4428_3023)" />
                                                            </g>
                                                            <defs>
                                                                <linearGradient id="paint0_linear_4428_3023" x1="9.00152" y1="17.3999" x2="9.00152" y2="0.598097" gradientUnits="userSpaceOnUse">
                                                                    <stop stop-color="#00E54E" />
                                                                    <stop offset="0.18" stop-color="#00E54E" />
                                                                    <stop offset="0.73" stop-color="#15D757" />
                                                                    <stop offset="1" stop-color="#04C747" />
                                                                </linearGradient>
                                                                <clipPath id="clip0_4428_3023">
                                                                    <rect width="18" height="18" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </i>
                                                    Risk scoring is good for checklists involving: questions​ designed to assess risk e.g. “Do you have any legal disputes outstanding?”
                                                </li>
                                                <li className='df aic fs0-75rem mb0-5rem'>
                                                    <i className='icon mr1rem'>
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M2.02395 15.143C2.56575 13.7876 4.22715 11.336 6.03795 8.89156C4.32975 6.93316 2.77995 5.14936 2.37675 4.64536C1.56315 3.62836 1.61895 3.19276 3.14355 3.18556C4.23615 3.18016 4.61415 3.04876 5.33775 3.80656C5.76255 4.25116 6.67695 5.34016 7.72815 6.66676C9.40935 4.49956 10.9664 2.63116 11.6216 1.94356C12.5576 0.962556 13.0472 1.13356 14.462 1.14076C16.4348 1.15156 16.5086 1.71496 15.4538 3.03076C14.867 3.76516 12.4028 6.59476 9.87915 9.48556C11.1842 11.2658 12.3506 13.0082 12.7448 13.9964C13.2668 15.3032 12.1058 15.8738 11.1176 14.7218C10.574 14.0864 9.46695 12.8192 8.21595 11.3864C6.46275 13.3934 4.88235 15.2042 4.12995 16.0826C2.85195 17.573 1.34715 16.835 2.02395 15.143Z" fill="#ED1C24" />
                                                        </svg>

                                                    </i>
                                                    Risk scoring is less effective for internal checklists designed​ to track workflow process  e.g. “Have you reviewed​ the business plan?”
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div >
            <div className='bottom-btn-area df aic w100 jcsb mt5rem bsbb'>
                <p className='c15 fs1rem' style={{ color: '#15131350' }}>2/6 Choose Checklist Features <span class="fs0-75rem">( * = fields requiring mandatory completion)</span></p>
                <button disabled={isDisabled} onClick={() => handleNextStep()} style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }} className={`shadow c1 fw400 fs1rem pr br38px w200px h3rem df aic jcc ${isDisabled ? ' bg16' : ' bg4 bg3-hover'}`}>
                    Ok, next
                    <i className='icon pr ml0-5rem' style={{ top: '3px' }}>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.42954 17.0154H21.1966L16.7035 22.1842C15.413 23.6688 17.3488 25.8957 18.6394 24.4111L22.7314 19.6964L25.463 16.5491C25.9924 15.9365 25.9924 14.9471 25.463 14.3344L18.6394 6.47557C18.3798 6.16869 18.0225 5.99649 17.6502 6.00005C16.4212 6.00023 15.818 7.72225 16.7035 8.70263L21.2073 13.8714H6.35888C4.46611 13.9794 4.60744 17.1238 6.42954 17.0154Z" fill="white" />
                        </svg>
                    </i>
                </button>
            </div>
        </>
    )
}
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { toggleModal } from "../../reducers/modalReducer";

export default function Introduction() {

    const dispatch = useDispatch();

    const modal = useSelector(state => state.modal);

    const handleLetsGo = () => {
        localStorage.removeItem('testType');
        dispatch(toggleModal({ open: 'addCompany' }));
    };

    return (
        <>
            <div className='df fww w100 mb8rem'>
                <div className='w60'>
                    <div className='df mt1rem'>
                        <img className="" src={require(`../../../images/demo-checklist/smile-emoji.png`)} alt="" />
                        <div className='df fdc ml0-5rem'>
                            <p class="w100 c15 fw300 mb0-5rem">Welcome to the demo checklist creation process.</p>
                            <p class="w100 c15 fw500">Demo purpose:</p>
                            <p class="w100 c15 fw300">To  quickly show you the power of a small part of our platform features​</p>
                        </div>
                    </div>
                    <p class="w100 c15 fw500 mt1rem">Demo process:</p>
                    <ol class="c15 fw500">
                        <li>Add a demo company <span class='fw300'> of your choice (we only use publicly-available data during this demo)​</span></li>
                        <li>We will create any checklist <span class='fw300'> of your choice bespoked to its activities/industry in minutes ( e.g diligence checklist, an internal process checklist, a regulatory compliance checklist etc)</span></li>
                        <li>Explore the checklist module, <span class='fw300'>its risk scoring, recommendations for improvement, reporting and collaborative workflow manager – all bespoked to your target company</span></li>
                        <li><span class='fw300'>Create an account and</span> store your checklist <span class='fw300'>– Its FREE (no credit card required).</span>​</li>
                    </ol>
                    <p class="w100 c15 fw300 mt2rem">For the purposes of this demo we have removed a number of additional features available in the full platform e.g. checklist styles (qualitative, quantitative, subjective), checklist length, manual creation/edits</p>
                </div>
                <div className='df jce w40'>
                    <img className="" src={require(`../../../images/demo-checklist/intro-right-side.png`)} alt="" />
                </div>
            </div>
            <div className='pr df aic w100 jcsb bsbb' style={{ top: '10%' }}>
                <p className='c15 fs1rem' style={{ color: '#15131350' }}>2/8 Add Your Target Company</p>
                <button onClick={() => handleLetsGo()} className='cp shadow c1 fw400 fs1rem pr br38px w200px h3rem df aic jcc bg4 bg3-hover'>
                    Let's go
                    <i className='icon pr ml0-5rem' style={{ top: '3px' }}>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.42954 17.0154H21.1966L16.7035 22.1842C15.413 23.6688 17.3488 25.8957 18.6394 24.4111L22.7314 19.6964L25.463 16.5491C25.9924 15.9365 25.9924 14.9471 25.463 14.3344L18.6394 6.47557C18.3798 6.16869 18.0225 5.99649 17.6502 6.00005C16.4212 6.00023 15.818 7.72225 16.7035 8.70263L21.2073 13.8714H6.35888C4.46611 13.9794 4.60744 17.1238 6.42954 17.0154Z" fill="white" />
                        </svg>
                    </i>
                </button>
            </div>
        </>
    )
}
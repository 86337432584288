import moment from "moment";
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';


export default function Workflows({ company, companyId }) {

    const dispatch = useDispatch();
    let user = useSelector((state) => state.user);
    let modal = useSelector(state => state.modal);

    const checklistModules = (modules) => {
        return <>{modules.length > 0
            ? Object.values(modules).map(module => {
                if (!module['isActive']) return;

                let isRedirect = false;
                if (module?.['stats'].completionPercent > 0) isRedirect = true;

                return <div className={`bo7 w25 keyfeature-wihte-item bg1 new-shadow br15px p2rem mx1rem my1-5rem pr bsbb ${user['role'] == 'Advisor' ? 'advisor-bg' : 'investor-bg'}`}>
                    <Link className="ttc fs1rem fw500 c15 lh2 zi1 w100">
                        <Link to={(module?.['stats'].completionPercent > 0 && module['isActive']) && `/company/${companyId}#${module['type']}`} onClick={() => { (module?.['stats'].completionPercent > 0 && module['isActive']) && localStorage.setItem('isWorkflowOpen', true), localStorage.setItem('isClientFirm', true) }} class="cp w100 h100" style={{ minHeight: '130px' }}>
                            <div className='w100 df aic'>
                                <i className='icon-diligenstia mr1rem'>
                                    <img className="br5px w50px" src={module?.['imageURL'] || require(`../../../../../images/checklist-logo.png`)} alt="" />
                                </i>
                                <button className='cp fs1rem fw500 bg1 c4-hover c15 lh2 title-box-feature tal' style={{ background: 'transparent' }}>{module['type']}</button>
                            </div>
                            <div className="w100 df jcsb">
                                <div class="df fdc ">
                                    <p className="c12 fw300 fs0-75rem mt4rem">{(module?.['createdAt'] || module?.['sections']?.[0]?.['createdAt']) ? moment(module?.['createdAt'] || module?.['sections']?.[0]?.['createdAt']).format('L') : '-'}</p>
                                </div>
                                <div className="df fdc jcc aic">
                                    <div className={`progress-bar workflow-report-progress ${module?.stats?.workflowCompletionPercent > 75 ? 'workflow-progress-green' : module?.stats?.workflowCompletionPercent > 26 ? 'workflow-progress-yellow' : module?.stats?.workflowCompletionPercent > 1 ? ' workflow-progress-pink' : '  workflow-progress-grey'}`}
                                        style={{ width: 50, height: 50, borderRadius: '50%', margin: 0, border: '2px solid #F1F1F1' }}>
                                        <CircularProgressbar
                                            text={module?.stats?.workflowCompletionPercent ? (module?.stats?.workflowCompletionPercent) + "%" : 'N/A'}
                                            styles={buildStyles({
                                                strokeWidth: '0',
                                                textSize: '20px',
                                                trailColor: '#FBFBFB',
                                                stroke: 'transparent',
                                                strokeLinecap: 'butt',
                                                backgroundColor: '#FBFBFB',
                                                pathTransitionDuration: 0.5,
                                                dominantBaseline: 'ideographic',
                                            })}
                                        />
                                    </div>
                                    <span style={{ marginBottom: "0", }} className="tac c15 fs0-75rem fw300">Workflow <br /> Completeness</span>
                                </div>
                            </div>

                        </Link>
                    </Link>
                </div>
            })
            : <p class="w100 tac fs2rem">No Workflow Added!</p>
        }</>
    };

    return (
        <>
            <div class="w100 df jcsb px3rem mb1rem">
                <p class="fs0-75rem fw300 c15"><i class="fa fa-circle mr0-5rem" style={{ fontSize: '20px', color: user['role'] == 'Investor' ? '#aafed680' : '#ffe50063' }} /> Created by {user['role']}</p>
            </div>
            <div className="bg20 bss bo5 bsbb p2rem br25px w100 keyfeature-box keyfeature-box-core-pro df fww mb2rem pr bsbb">
                <h3 className="fs1-25rem c15 w100 mb2rem"><span className="c7">{user?.['role'] && user['role']} </span> - Internal Use Only</h3>

                {/* Checklists - Internal */}
                {company && company?.['modules'] && <>
                    {(company?.['modules'] && Object.values(company['modules']).length > 0) && checklistModules(Object.values(company['modules']).filter(item => (item?.['category'] == "internal" && !item['isHealthBoost'])))}
                </>}


            </div>
        </>
    );
};

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Workflows from './Workflows';
import MainDashboard from './MainDashboard';
import AnyListChecklists from './AnyListChecklists';


export default function YourFirm({ company, companyId }) {

    const spinner = document.getElementById("e1jygW4piZH1");

    let user = useSelector((state) => state.user);

    let [firmTab, setFirmTab] = useState('Main Dashboard');

    return (
        <>
            {spinner.classList.remove("op0")}
            {company && Object.values(company).length > 0 && spinner.classList.add("op0")}
            <div className="dashboard-firm-tab">
                <div className='tabs-navlist tabs-dashboard df aic jcc my2rem'>{
                    ['Main Dashboard', 'AnyList Checklists', 'Workflows'].map(item => {
                        return <div className="df fdc">
                            {item == 'AnyList Checklists' && <span className="c4 fs0-75rem" style={{ marginTop: '-15px' }}>Internal Used Only</span>}
                            <button className={`fs1-25rem fw500 c15 cp ${firmTab == item && ' active'}`} onClick={() => setFirmTab(item)}>{item}</button>
                        </div>
                    })
                }
                </div>
                <div className='dashboard-tools-area'>
                    {/* {firmTab != 'Main Dashboard' &&
                       
                    } */}
                    <div className='box-dashbord-container mla mra mb6rem'>
                        <div className=" bsbb keyfeature-box-core-dashboard">
                            {firmTab == 'Main Dashboard' && <MainDashboard company={company} companyId={companyId} />}
                            {firmTab == 'AnyList Checklists' && <AnyListChecklists company={company} companyId={companyId} />}
                            {firmTab == 'Workflows' && <Workflows company={company} companyId={companyId} />}
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
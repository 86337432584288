import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { KeyboardArrowDown } from '@mui/icons-material';

import Toggler from '../../toggler';
import Button from '../../common/Button';
import ModalWrapper from '../../common/Modal/ModalWrapper';
import { toggleModal } from '../../../reducers/modalReducer';
import { getRequest, postRequest, setUserId, showMessage } from '../../../config';

const AddCompanyModal = ({ modalType, proceedCompany, setIsFireWork, user, isURL, openWindow, setCompany, setConfirmedCompany, confirmedCompany, companyOptions, setCompanyOptions, selectedCompany, setSelectedCompany, closeAddCompany, existingCompany, setExistingCompany, step, setStep, setPreviousCompanyDeleted, previousCompanyDeleted }) => {

    const dispatch = useDispatch()
    const { pathname } = useLocation()
    const isDashboard = pathname.includes('dashboard')

    let reduxUser = useSelector((state) => state.user);

    let [isDisabled, setIsDisabled] = useState(true);
    let [websiteStatus, setWebsiteStatus] = useState('Check');
    let [data, setData] = useState({ companyWebsite: '', company5Words: '', companyDevelopmentStage: '', testType: '', });

    useEffect(() => {
        if (data['companyWebsite'] == '' || data['company5Words'] == '' || data['companyDevelopmentStage'] == '' || data['testType'] == '' || websiteStatus != 'Confirmed') setIsDisabled(true);
        else setIsDisabled(false);
    }, [data, websiteStatus]);


    const verifyWebsite = async () => {
        try {
            let companyWebsite = document.querySelector(`#companyWebsite`).value;
            if (!companyWebsite) return showMessage('Please provide website link');
            if (!isURL(companyWebsite)) return showMessage('Invalid company url');
            if (companyWebsite.indexOf('http') < 0)
                companyWebsite = `http://${companyWebsite}`;
            openWindow(companyWebsite);
            setWebsiteStatus('Confirm');
        } catch (e) {
            return showMessage(e);
        }
    };

    const handleBackToFirstStep = () => {
        setStep(1)
        setExistingCompany('');
        setWebsiteStatus('Check');
        setCompanyOptions([]);
        setSelectedCompany({});
        setConfirmedCompany();
    };

    const submitCompany = async () => {
        let companyObj = { isDirectCompany: false }
        if (user['role'] != 'Advisor' && user['role'] != 'Investor') { companyObj['isDirectCompany'] = true }

        let inputArray = ['companyWebsite', 'testType', 'companyDevelopmentStage', 'company5Words'];
        if (user['role'] == 'Advisor' || user['role'] == 'Investor') {
            inputArray.push('companyOwnerEmail');
            inputArray.push('companyOwnerName');
            confirmedCompany['userType'] = user['role'];
        }
        for (const id of inputArray) {
            const el = document.querySelector(`#${id}, [name='${id}']:checked`);
            if (!el?.['dataset']?.['option'] && el?.['value'] == '') return showMessage(`Please provide all inputs`);
            confirmedCompany[id] = el?.['dataset']?.['option'] || el?.['value'];
        }
        if (websiteStatus != 'Confirmed') return showMessage('Please check and confirm company website');

        if (confirmedCompany['company5Words'].split(' ').length < 5) return showMessage('Please provide 5 words that describe your company business activites');

        dispatch(toggleModal({ open: 'generatingDescription' }));
        const response = await postRequest('/company/new', { company: { ...confirmedCompany, isParent: user['role'] == 'Advisor' ? true : false, ...companyObj }, user: reduxUser });

        showMessage(response['message']);
        if (response?.['code'] !== 200) {
            dispatch(toggleModal({ open: '' }));
            return;
        }

        let { company } = response['body'];
        if (company?.['creatorId'] && company?.['_id']) {
            setWebsiteStatus('Check');
            setUserId(company['creatorId']);
            localStorage.removeItem('userId');
            localStorage.setItem('userId', company['creatorId']);
            document.cookie = `userId=${company['creatorId']};path=/`;
            await setCompany(company);
            if (!company['companyDescription']) proceedCompany(company);
            else {
                setIsFireWork(true);
                setTimeout(() => {
                    dispatch(toggleModal({ open: 'confirmDescription' }));
                    setIsFireWork(false);
                }, 2500);
            }
        } else showMessage('Something went wrong, please try again');
    };

    const confirmCompany = async () => {
        if (!selectedCompany || !selectedCompany['company_number']) return showMessage('Please select company');
        if (!selectedCompany?.['company_status'] || selectedCompany?.['company_status'] != 'active') return showMessage('This company is not active');

        const response = await getRequest('/company/isCompanyAllowed/' + selectedCompany['company_number'] + `?userId=${user?.['_id']}`);
        if (!response || response['code'] != 200) return;
        if (response?.['body']?.['created'] == true) {
            setExistingCompany(response['body']['existingCompany']);
            setStep(4)
            return showMessage(response['message']);
        };
        setConfirmedCompany(selectedCompany);
        setStep(3)
    };

    const requestAccess = async () => {
        if (!existingCompany['_id']) return showMessage('No existing company is selected!');
        let response = await getRequest(`/company/requestAccess/${existingCompany['_id']}`);
        if (response['code'] == 200) {
            showMessage(response['message']);
        }
    };

    let searchTimeout;
    const searchCompanyTimeout = (e) => {
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(() => searchCompany(e.target.value), 1000);
    };

    const searchCompany = async (input) => {
        if (!input) return;
        const response = await getRequest(`/company/search/${input}`);
        if (!response || response['code'] != 200) return;
        setCompanyOptions(response['body']['items'].slice(0, 5));
    };

    const onCloseHandler = () => {
        closeAddCompany()
        setStep(1)
    }

    return (
        <ModalWrapper modalType={modalType} onCloseHandler={onCloseHandler}>
            {
                step === 1 ? <>
                    {previousCompanyDeleted ? <p className='c4 fw500 fs0-75rem mb1em fsi'>Company removed, Let’s try again!</p> : null}
                    <h1
                        className={`c15 fs1-5rem lh1 ${isDashboard ? "tac" : "tas"} mb2rem fw600 mb2rem smfs1.25rem w100`}
                    >
                        Add a UK{' '} {user['role'] == 'Advisor' || user['role'] == 'Investor' ? 'client' : ''}{' '} company
                    </h1>
                    {isDashboard ? <Toggler /> : null}
                    <div className='pr mb1rem df aic jcs'>
                        <img src={require(`../../../../images/searchCompany.png`)} className='pa ml0-5rem miw2rem' alt="searchIcon" />
                        <input
                            data-testid='searchForCompanies'
                            onKeyUp={searchCompanyTimeout}
                            type='text'
                            placeholder='Search for Company Name/Number'
                            className='pl3rem bg1 bs1px brss bo42 w100 br8px pt1rem pb1rem'
                        />
                        <KeyboardArrowDown className='c3 pa r20px' />
                    </div>
                    <div className='shadow bg1 companyOptionCard'>
                        {
                            companyOptions.map((option, idx) => (
                                <div
                                    key={`companyOptionCard-${idx}-${idx}`}
                                    onClick={() => {
                                        setSelectedCompany(option);
                                        setStep(2);
                                    }}
                                    className='cp c36 df fww jcfs aic bg1 py0-5rem px1rem br5px'
                                >
                                    <span className='fw600 c36 mr1rem sm-fs14px'>{option['title']}</span>
                                    <span className='mr1rem c36 sm-fs14px'>{option['company_number']}</span>
                                    <span className='ttu fw600 c36 sm-fs14px'> {option['company_status']} </span>
                                </div>
                            ))
                        }
                    </div>
                </> : step === 2 ? <>
                    {
                        selectedCompany && selectedCompany?.['title'] && (
                            <>
                                <div className='br5px p1rem mb1rem'>
                                    <h2 className='c15 fs1-5rem ttu w100 tas'>Company Information</h2>
                                    <p className='c3 mb1rem sm-fs14px fs1rem'>{selectedCompany?.['title']}</p>
                                    <div className='dg gg0-5rem'>
                                        <span className='c15 fs14px'> Company Status:{' '} <b>{selectedCompany?.['company_status'] && selectedCompany['company_status'].toUpperCase()}{' '}</b> </span>
                                        <span className='c15 fs14px'> Company Number:{' '} <b>{selectedCompany?.['company_number']}{' '} </b> </span>
                                        <span className='c15 fs14px'> Company Title:{' '} <b>{selectedCompany?.['title']}{' '} </b> </span>
                                        <span className='c15 fs14px'> Company Date of Creation:{' '} <b>{selectedCompany?.['date_of_creation']}{' '} </b> </span>
                                        <span className='c15 fs14px'> Company Description:{' '} <b>{selectedCompany?.['description']}{' '} </b> </span>
                                        <span className='c15 fs14px'> Company Address:{' '} <b>{selectedCompany?.['address_snippet']}{' '} </b> </span>
                                    </div>
                                </div>
                            </>
                        )
                    }

                </> : step === 3 && confirmedCompany ? <>
                    <h2 className='c15 fs1-5rem w100 tas'>Add Your Company Details</h2>
                    <p className='c3 mb1rem sm-fs14px fs1rem'>{confirmedCompany['title']} </p>
                    <div className='pr mb1rem df aic jcfs'>
                        <input
                            id='companyWebsite'
                            name='companyWebsite'
                            type='text'
                            placeholder={`Website`}
                            onChange={(e) => { setWebsiteStatus('Check'), setData({ ...data, companyWebsite: e.target.value }) }}
                            className='pl3rem bg1 bs1px brss bo42 w100 br8px pt1rem pb1rem xs-px32px xs-fs14px'
                        />
                        {websiteStatus == 'Check'
                            ? (<input
                                type='button'
                                className='verify-btn shadow bg3 c1 fs1rem sm-fs14px pt1rem pb1rem fw600 cp pl2rem pr2rem sm-px16px'
                                value={websiteStatus}
                                onClick={() => verifyWebsite()}
                            />)
                            : websiteStatus == 'Confirm' ? (
                                <div className='pa r0 df aic'>
                                    <svg width="33" height="34" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M29.9955 19.4373L27.3395 15.6863C26.9005 15.068 26.0437 14.9183 25.4221 15.3587L21.6718 18.0141C21.0527 18.4524 20.9051 19.3106 21.3441 19.9303C21.7831 20.5493 22.6399 20.6977 23.2615 20.2579L24.5176 19.3686C24.2478 20.2821 23.8355 21.151 23.2602 21.9364C21.6595 24.1218 19.1955 25.3746 16.4979 25.3746C14.0493 25.3746 11.7411 24.3091 10.166 22.4513C9.67599 21.874 8.8085 21.8028 8.2277 22.2923C7.64897 22.7837 7.57719 23.6512 8.06861 24.2306C10.1674 26.7053 13.2396 28.1246 16.4979 28.1246C20.0295 28.1246 23.3864 26.4186 25.4785 23.5612C26.2386 22.5237 26.7859 21.3769 27.1449 20.171L27.7504 21.0262C28.0189 21.4048 28.4432 21.607 28.8743 21.607C29.1482 21.607 29.4262 21.525 29.6678 21.3538C30.2868 20.9151 30.4345 20.0569 29.9955 19.4373Z" fill="#4A67FF" stroke="#4A67FF" stroke-width="0.1" />
                                        <path d="M11.6539 14.0702C11.2142 13.4499 10.3561 13.3028 9.73704 13.7419L8.4807 14.6313C8.7502 13.7175 9.16242 12.8489 9.73704 12.0642C11.3377 9.87943 13.8024 8.62598 16.5 8.62598C18.9493 8.62598 21.2575 9.69146 22.8325 11.5492C23.324 12.1273 24.1914 12.1997 24.7702 11.7083C25.3503 11.2169 25.4214 10.3494 24.93 9.76998C22.8312 7.29525 19.7589 5.87598 16.5 5.87598C12.9691 5.87598 9.6115 7.58194 7.51889 10.4393C6.7592 11.4766 6.21209 12.6237 5.85321 13.8302L5.24752 12.9746C4.8078 12.3542 3.95035 12.2072 3.33077 12.6462C2.7112 13.0852 2.56407 13.9434 3.00242 14.563L5.65782 18.314C5.92636 18.6926 6.35069 18.8948 6.78175 18.8948C7.05634 18.8948 7.33367 18.8128 7.57471 18.6423L11.3257 15.9869C11.9453 15.5479 12.0923 14.6899 11.6539 14.0702Z" fill="#4A67FF" stroke="#4A67FF" stroke-width="0.1" />
                                    </svg>
                                    <button className='registerButtonAnimation br8px ml0-5rem shadow bg3 c1 pt1rem pb1rem pl1-5rem pr1-5rem sm-px16px fw600 cp' value={websiteStatus} onClick={() => setWebsiteStatus('Confirmed')}>{websiteStatus}</button>
                                </div>
                            ) : (<div className='pa SlitRight'>
                                <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.2143 0.5C6.84643 0.5 0 7.34643 0 15.7143C0 24.0821 6.84643 30.9286 15.2143 30.9286C23.5821 30.9286 30.4286 24.0821 30.4286 15.7143C30.4286 7.34643 23.5821 0.5 15.2143 0.5ZM21.7564 12.0629L14.7579 21.1914C14.4536 21.4957 13.9971 21.8 13.5407 21.8C13.0843 21.8 12.6279 21.6479 12.3236 21.1914L8.67214 16.475C8.21571 15.8664 8.21571 14.8014 8.97643 14.345C9.73714 13.8886 10.65 13.8886 11.1064 14.6493L13.5407 17.8443L19.3221 10.2371C19.7786 9.62857 20.8436 9.47643 21.4521 9.93286C22.2129 10.3893 22.2129 11.3021 21.7564 12.0629Z" fill="#15D757" />
                                </svg>
                            </div>)}
                        <img className='ml1rem pa xs-mx8px' src={require('../../../../images/browserIcon.svg')} alt="browserIcon" />
                    </div>
                    <div className='pr mb1rem df aic jcfs'>
                        <input id='company5Words' type='text' onChange={(e) => setData({ ...data, company5Words: e.target.value })} placeholder={`Add 5 keywords which explain your ${user['role'] == 'Advisor' || user['role'] == 'Investor' ? 'client' : ''} business activities​`} className='pl3rem bg1 bs1px brss bo42 w100 br8px pt1rem pb1rem xs-px32px xs-fs14px' />
                        <img className='ml1rem pa xs-mx8px' src={require('../../../../images/keywordIcon.svg')} alt="keywordIcon" />
                    </div>
                    <b className='mb1rem mt1rem c15 fs1-25rem'> {user['role'] == 'Advisor' || user['role'] == 'Investor' ? 'Client' : 'My'}{' '} company is </b>
                    <div className='df aic jcfs fww mt0-5rem mb2rem'>
                        {['Pre-Revenue', 'Post-Revenue Early Stage', 'Mature'].map((option, idx) => (
                            <label key={`${idx}-radioCompany-type`} htmlFor={option} className="df cp aic mb0-5rem">
                                <span className="radio pr df">
                                    <input
                                        onClick={() => { localStorage.setItem('cStage', option); setData({ ...data, companyDevelopmentStage: option }) }}
                                        data-testid={option.replace(/\s/g, '')}
                                        data-option={option}
                                        name="companyDevelopmentStage"
                                        id={option}
                                        className="bg5 bo5 bw2px bss cp td250 w1rem h1rem br50pc "
                                        type="radio"
                                    />
                                </span>
                                <span className="w100 fs1rem lh3 ml0-5rem usn mr1rem c15 xs-fs12px">
                                    {option}
                                </span>
                            </label>
                        ))}

                    </div>
                    <b className='c15 mb1rem fs1-25rem'> I want to make{' '} {user['role'] == 'Advisor' || user['role'] == 'Investor' ? 'client' : 'my'}{' '} company </b>
                    <div className='df aic jcfs mt0-5rem fww mb1rem'>
                        {['Investment Ready', 'Growth Ready', 'Exit Ready'].map((option, idx) => (
                            <label key={`my-company-is${idx}`} htmlFor={option} className="df cp aic mb0-5rem">
                                <span className="radio pr df">
                                    <input
                                        data-testid={option.replace(/\s/g, '')}
                                        checked={
                                            localStorage.getItem('testType') === option.toLowerCase().replace(/ ready/, '')
                                        }
                                        data-option={option.toLowerCase().replace(/ ready/, '')}
                                        name="testType"
                                        id={option}
                                        className="bg5 bo5 bw2px bss cp td250 w1rem h1rem br50pc"
                                        type="radio"
                                        onClick={() => {
                                            localStorage.setItem('testType', option.toLowerCase().replace(/ ready/, ''));
                                            setData({ ...data, testType: option })
                                        }}
                                    />
                                </span>
                                <span className="w100 fs1rem lh3 ml0-5rem usn mr1rem c15 xs-fs12px">{option}</span>
                            </label>
                        ))}
                    </div>
                    {(user['role'] == 'Advisor' || user['role'] == 'Investor') && (
                        <>
                            <div className='pr mt1rem'>
                                <input id='companyOwnerName' type='text' placeholder='Please provide Client Contact Name' className='pl4rem shadow bg5 w100 br5px p1rem' />
                                <svg className='center-vertically f3 pa l0 ml1rem w2rem' viewBox='0 0 640 512' >
                                    <path d='M639.77 208.21v32.1c0 8.5-6.7 15.1-15.2 15.8-39.4 3.2-105.4 51-138.4 65.8-34.3 15.4-66.7 30-102.3 30-28.2 0-50.2-8.5-65.5-25.3-22.7-24.9-22.8-55.3-20.6-83.8-56.5 45.1-169 153.6-211.2 195.8A31.63 31.63 0 0 1 64 448c-27 0-36.5-27-29.7-43.9l98.2-245.6c8-19.9-14.3-38.8-32.7-27.1l-58 38.9a15.91 15.91 0 0 1-22.1-4.9l-17.2-27a16.08 16.08 0 0 1 4.9-22.1l54.9-36.9c76.5-48.7 160.1 26.9 129.7 102.8l-41.5 103.7c105.2-101.2 144.4-124.5 169.5-126 54.4-3.1 43.8 68.1 42.7 76.1-4.7 35.7-1.3 51.9 21.3 51.9 21.9 0 47-11.3 76.1-24.4 37.4-16.8 111.3-68 163.1-71.5 9.07-.59 16.8 7.11 16.57 16.21z' className='fa-secondary' ></path>
                                </svg>
                            </div>
                            <div className='pr mt0-5rem'>
                                <input id='companyOwnerEmail' type='text' placeholder='Please provide Client Contact Email' className='pl4rem shadow bg5 w100 br5px p1rem' />
                                <svg className='center-vertically f3 pa l0 ml1rem w2rem' viewBox='0 0 512 512' >
                                    <path d='M256.47 352h-.94c-30.1 0-60.41-23.42-82.54-40.52C169.39 308.7 24.77 202.7 0 183.33V400a48 48 0 0 0 48 48h416a48 48 0 0 0 48-48V183.36c-24.46 19.17-169.4 125.34-173 128.12-22.12 17.1-52.43 40.52-82.53 40.52zM464 64H48a48 48 0 0 0-48 48v19a24.08 24.08 0 0 0 9.2 18.9c30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4 23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7A23.93 23.93 0 0 0 512 131v-19a48 48 0 0 0-48-48z' className='fa-secondary'></path>
                                    <path d='M512 131v52.36c-24.46 19.17-169.4 125.34-173 128.12-22.12 17.1-52.43 40.52-82.53 40.52h-.94c-30.1 0-60.41-23.42-82.54-40.52C169.39 308.7 24.77 202.7 0 183.33V131a24.08 24.08 0 0 0 9.2 18.9c30.6 23.9 40.7 32.4 173.4 128.7 16.69 12.12 49.75 41.4 72.93 41.4h.94c23.18 0 56.24-29.28 72.93-41.4 132.7-96.3 142.8-104.7 173.4-128.7A23.93 23.93 0 0 0 512 131z' className='fa-primary' ></path>
                                </svg>
                            </div>
                        </>
                    )}
                </> : existingCompany ? <>
                    <div>
                        <div className='df jcc aic fdc w100'>
                            <svg width="60" height="60" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M40.9346 76C60.3018 76 76.002 60.2998 76.002 40.9326C76.002 21.5654 60.3018 5.86523 40.9346 5.86523C21.5674 5.86523 5.86719 21.5654 5.86719 40.9326C5.86719 60.2998 21.5674 76 40.9346 76Z" fill="url(#paint0_linear_5984_47190)" />
                                <path d="M75.8262 44.4635L45.6436 14.2808C44.6406 12.9422 42.8694 12.8047 40.8963 12.8047C37.7014 12.8047 35.7969 13.9299 35.7969 16.9691C35.7969 26.2423 36.8879 39.5683 36.8879 48.8416C36.8879 50.0536 36.8291 50.9124 37.672 51.4674L57.8565 71.6519C67.6994 66.2184 74.6533 56.1932 75.8262 44.4635Z" fill="url(#paint1_linear_5984_47190)" />
                                <path d="M57.5222 71.8347L45.3323 59.6447C44.2374 58.4271 42.6732 57.9434 40.9715 57.9434C37.5426 57.9434 35.2656 60.3763 35.2656 63.6493C35.2656 65.3942 35.7417 67.0187 36.9961 68.1378L44.6613 75.803C49.2741 75.3157 53.6198 73.9344 57.5222 71.8347Z" fill="url(#paint2_linear_5984_47190)" />
                                <path d="M46.8997 63.6494C46.8997 66.8446 44.1724 69.65 40.9772 69.65C37.5482 69.65 34.9766 66.8446 34.9766 63.6494C34.9766 60.3765 37.5482 57.6488 40.9772 57.6488C44.1724 57.6488 46.8997 60.3765 46.8997 63.6494ZM45.4192 48.8431C45.4192 51.259 42.7695 52.272 40.8991 52.272C38.4055 52.272 36.3014 51.259 36.3014 48.8431C36.3014 39.5699 35.2103 26.2438 35.2103 16.9706C35.2103 13.9314 37.704 12.2168 40.8991 12.2168C43.9383 12.2168 46.51 13.9311 46.51 16.9706C46.5102 26.2438 45.4192 39.5696 45.4192 48.8431Z" fill="url(#paint3_linear_5984_47190)" />
                                <defs>
                                    <linearGradient id="paint0_linear_5984_47190" x1="24.6242" y1="24.6222" x2="58.4244" y2="58.4225" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#FF637B" />
                                        <stop offset="1" stop-color="#E63950" />
                                    </linearGradient>
                                    <linearGradient id="paint1_linear_5984_47190" x1="62.8505" y1="50.2933" x2="26.647" y2="26.0601" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#E63950" stop-opacity="0" />
                                        <stop offset="0.0467" stop-color="#E4354B" stop-opacity="0.047" />
                                        <stop offset="0.4669" stop-color="#D31822" stop-opacity="0.467" />
                                        <stop offset="0.7991" stop-color="#C90709" stop-opacity="0.799" />
                                        <stop offset="1" stop-color="#C50000" />
                                    </linearGradient>
                                    <linearGradient id="paint2_linear_5984_47190" x1="54.4907" y1="74.2011" x2="36.1085" y2="61.5086" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#E63950" stop-opacity="0" />
                                        <stop offset="0.0467" stop-color="#E4354B" stop-opacity="0.047" />
                                        <stop offset="0.4669" stop-color="#D31822" stop-opacity="0.467" />
                                        <stop offset="0.7991" stop-color="#C90709" stop-opacity="0.799" />
                                        <stop offset="1" stop-color="#C50000" />
                                    </linearGradient>
                                    <linearGradient id="paint3_linear_5984_47190" x1="34.9769" y1="40.9333" x2="46.8997" y2="40.9333" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#EDF5FF" />
                                        <stop offset="1" stop-color="#D5E8FE" />
                                    </linearGradient>
                                </defs>
                            </svg>
                            <h3 className='c15 mt1rem fs20px'>Duplicate Company Detected</h3>
                            <p className='c15 op70 fs12px mt0-5rem'>Oops! This company already exists in your list.</p>
                        </div>
                        <div className='df mt2rem jcc'>
                            <Button btnType="warning" customClassName="bg44 c4 mr1rem bss bs1px bo4" text="Add different company" onClick={handleBackToFirstStep} />
                            <Button btnType="warning" text="Ask company for access" onClick={requestAccess} />
                        </div>
                    </div>
                </>
                    : null
            }

            <div className=' df jcc aic'>
                {[1, 2].includes(step)
                    ? <span
                        disabled={!Object.keys(selectedCompany).length}
                        data-testid='confirmCompany'
                        onClick={() => confirmCompany()}
                        className={`mt1rem ${!Object.keys(selectedCompany).length ? "op60" : "op100"} bg4 bg4-hover c1 td250 br50px py0-75rem fw600 cp pl6rem pr6rem mla mra bsbb tac xs-px32px sm-fs14px`}>
                        Confirm Company
                    </span>
                    : step === 3
                        ? <button
                            disabled={isDisabled}
                            style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
                            data-testid='submitCompany'
                            onClick={() => submitCompany()}
                            className={`mt2rem c1 td250 br50px py0-75rem fw600 pl6rem pr6rem mla mra bsbb tac xs-px48px smfs1rem sm-fs14px ${isDisabled ? ' bg16' : ' bg4 bg4-hover'}`}>
                            Submit
                        </button>
                        : null
                }
            </div>

        </ModalWrapper>
    )
}

export default AddCompanyModal
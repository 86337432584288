import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { createRef, useRef, useState } from 'react'

import Modal from '@mui/material/Modal';
import AnimatedPage from '../../components/AnimatedPage';
import { toggleModal } from '../../reducers/modalReducer';
import { COMPONENT_REF } from '../landingPage/homePage/Companies';
import { updateLinkedIn } from '../../reducers/linkedInReducer';

const NOT_SURE_ARRAY = [
    {
        'not-linkedIn': {
            icon: require('../../../images/landingpage/loan.png'),
            spanText: 'Investment ready',
            btnKey: 1
        },
        'is-linkedIn': {
            icon: require('../../../images/landingpage/building.png'),
            spanText: 'Company',
        },
    },
    {
        'not-linkedIn': {
            icon: require('../../../images/landingpage/profit.png'),
            spanText: 'Growth ready',
            btnKey: 2
        },
        'is-linkedIn': {
            icon: require('../../../images/landingpage/advice.png'),
            spanText: 'Advisor practice/Company',
        },
    },
    {
        'not-linkedIn': {
            icon: require('../../../images/landingpage/open-door.png'),
            spanText: 'Exit ready',
            btnKey: 3
        },
        'is-linkedIn': {
            icon: require('../../../images/landingpage/layer-two.png'),
            spanText: 'Investor company/Fund',
        },
    }
];

const TestPage = () => {

    const dispatch = useDispatch();

    const modal = useSelector((state) => state.modal);
    const { isFromLinkedIn } = useSelector((state) => state.linkedIn);

    const refs = useRef(COMPONENT_REF.map(() => createRef()));

    const [test, setTest] = useState();
    const [isAreYou, setIsAreYou] = useState(true);
    const [isBtnHover, setIsBtnHover] = useState('');
    const [showAnimation, setShowAnimation] = useState(0);


    const handleLetsGo = () => {
        localStorage.setItem('testType', test.toLowerCase());
        dispatch(toggleModal({ open: 'addCompany' }));
    };

    const welcome2MinuteTest = async (test) => {
        await dispatch(toggleModal({ open: 'welcome2MinuteTest' }));
        setTest(test);
    };

    return (
        <div className="poppins-font-style test-page-font *">
            <AnimatedPage>
                <div className='not-sure-register w100 mih100vh'>
                    <div className="pr bg20 not-sure-container mih100vh" style={{ padding: isFromLinkedIn ? '40px 200px' : '40px 100px', backgroundImage: `url(${require('../../../images/bgtest.webp')})`, backgroundSize: '100% 100%' }}>
                        <div className='heading-area pr'>
                            <div className='heading-box'>
                                <div ref={refs?.current?.[8]} className={`tslp op1 tty0`}>
                                    <p className={`${isFromLinkedIn ? 'tas ' : 'tac '} fw600 c15 fs3rem mt5rem`} style={{ lineHeight: '52.2px', }}>
                                        {isFromLinkedIn ? 'FREE 2-Minute  ' : 'Choose Your '} <span className="c23" >{isFromLinkedIn ? 'Test' : 'Test?'}</span>
                                    </p>
                                    <div className="w100 df jcc">
                                        <p className={`${isFromLinkedIn ? 'tas ' : 'tac '} fw400 c15 pt1rem fs1rem mb2rem`} style={{ lineHeight: '24px', width: '100%' }}>
                                            {isFromLinkedIn ? `Let's pick your who you want to register as, and begin our FREE 2-minute test! ` : `Let's pick your objective, and begin our FREE 2-minute test! `}
                                            <br></br>No email or credit card required.
                                        </p>
                                    </div>
                                    {isFromLinkedIn && <img className='mb1rem' src={require('../../../images/landingpage/linkedIn-title-logo.png')} alt='' />}
                                </div>
                            </div>
                            <div className='img-right-test-page'>
                                {isFromLinkedIn
                                    ? <img className='' src={require('../../../images/landingpage/test-ai-default.png')} alt='' />
                                    : <img className='' src={require('../../../images/landingpage/test-ai-img.png')} alt='' />
                                }
                            </div>
                        </div>

                        {(!isFromLinkedIn && isAreYou) &&
                            <div className='df fdc aic'>
                                <div className="fw500 fs2rem c15 py1rem mb0-5rem" style={{ lineHeight: '22.39px' }}>Are you?</div>
                                <div className='df jcc mb1rem xs-fdc'>
                                    <div className="df aic mr2rem xs-my8px">
                                        <input id='company' className='cp w20px h20px' name='are-you' type='radio' value='Company' />
                                        <label className='fw400 fs1-125rem ml0-5rem c15' for='company' style={{ lineHeight: '22.39px' }}>Company</label>
                                    </div>
                                    <div className="df aic mr2rem xs-my8px">
                                        <input id='advisor' className='cp w20px h20px' name='are-you' type='radio' value='Advisor' />
                                        <label className='fw400 fs1-125rem ml0-5rem c15' for='advisor' style={{ lineHeight: '22.39px' }}>Advisor</label>
                                    </div>
                                    <div className="df aic mr2rem xs-my8px">
                                        <input id='investor' className='cp w20px h20px' name='are-you' type='radio' value='Investor' />
                                        <label className='fw400 fs1-125rem ml0-5rem c15' for='investor' style={{ lineHeight: '22.39px' }}>Investor</label>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="df jcc fdc mt4rem bsbb testPageGrid" style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "50px" }}>
                            {NOT_SURE_ARRAY.map((obj, index) => {
                                return (
                                    <div ref={refs?.current?.[8]} onC className={`df aic fdc bsflp bg1 br30px pr tslp py4rem px1rem mb3-5rem`} key={index}>
                                        <div className={`pa df jcc aic p2rem ${isFromLinkedIn ? 'bg4' : 'bg3'}`} style={{ top: '-49px', left: '50%', transform: 'translateX(-50%)', width: '15px', height: '15px', zoom: '0.8', borderRadius: '50%' }}>
                                            <img src={obj?.[isFromLinkedIn ? 'not-linkedIn' : 'is-linkedIn']?.icon} />
                                        </div>
                                        <h1 className="fw500 tac c15 fs1-75rem pr0-5rem pl0-5rem mt0-5rem df fdc">
                                            <div>{isFromLinkedIn ? `I'm a ` : `How `} <span className="fw700 fs1-75rem tac c15 fsi">{obj?.[isFromLinkedIn ? 'is-linkedIn' : 'not-linkedIn']?.spanText}</span></div>
                                            {!isFromLinkedIn && <div>are you?</div>}
                                        </h1>
                                        <div className="df jcc my0-5rem">
                                            {isFromLinkedIn
                                                ? <button onClick={() => { dispatch(updateLinkedIn({ isFromLinkedIn: false })), setIsAreYou(false) }} className={`pa cp df jcc aic bg1 c4 td250 br35px bo4 bss`} style={{ top: '70%', border: '1px solid #FC7785', padding: '10px 26px' }}>
                                                    Next
                                                    <svg className={``} width='21' height='18' viewBox='0 0 21 18' fill='none' xmlns='http:www.w3.org/2000/svg' style={{ marginLeft: '10px', stroke: '#FC7785', height: '18px', width: '21px' }}>
                                                        <path d='M1.39612 10.331H15.818L11.43 14.7191C10.1696 15.9795 12.0602 17.87 13.3206 16.6096L17.3169 12.6071L19.9847 9.93519C20.5017 9.41507 20.5017 8.57507 19.9847 8.05496L13.3206 1.38311C13.0671 1.12258 12.7181 0.976392 12.3545 0.979416C11.1542 0.979567 10.5652 2.4415 11.43 3.2738L15.8284 7.66187H1.32711C-0.521408 7.75359 -0.383387 10.4231 1.39612 10.331Z' fill={isBtnHover === obj['not-linkedIn']?.btnKey ? '#ffff' : '#FC7785'} />
                                                    </svg>
                                                </button>
                                                : <Link to={`#${(obj['not-linkedIn']?.spanText?.split(' '))[0]}`} onClick={() => welcome2MinuteTest((obj?.['not-linkedIn']?.spanText?.split(' '))[0])} onMouseEnter={() => setIsBtnHover(obj['not-linkedIn']?.btnKey)} onMouseLeave={() => setIsBtnHover('')}
                                                    className={`br35px pr cp ${isBtnHover === obj['not-linkedIn']?.btnKey ? "c1" : "c4"} ${showAnimation == index && "arrowBtnAnimation"} df jcc aic mt1rem`} style={{ transition: "background-color 0.3s ease", background: isBtnHover === obj['not-linkedIn']?.btnKey ? "#FC7785" : 'transparent', border: '1px solid #FC7785', padding: '10px 26px' }}>
                                                    2-Minute Test
                                                    <svg className={``} width='21' height='18' viewBox='0 0 21 18' fill='none' xmlns='http:www.w3.org/2000/svg' style={{ marginLeft: '10px', stroke: isBtnHover === obj['not-linkedIn']?.btnKey ? '#ffff' : '#FC7785', height: '18px', width: '21px' }}>
                                                        <path d='M1.39612 10.331H15.818L11.43 14.7191C10.1696 15.9795 12.0602 17.87 13.3206 16.6096L17.3169 12.6071L19.9847 9.93519C20.5017 9.41507 20.5017 8.57507 19.9847 8.05496L13.3206 1.38311C13.0671 1.12258 12.7181 0.976392 12.3545 0.979416C11.1542 0.979567 10.5652 2.4415 11.43 3.2738L15.8284 7.66187H1.32711C-0.521408 7.75359 -0.383387 10.4231 1.39612 10.331Z' fill={isBtnHover === obj['not-linkedIn']?.btnKey ? '#ffff' : '#FC7785'} />
                                                    </svg>
                                                </Link>

                                            }
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>

                {/* Welcome 2 Minute Test New Modal */}
                <Modal id="welcome2MinuteTestModal" open={modal['open'] == 'welcome2MinuteTest'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic" >
                    <div className='df jcc'>
                        <div className={`mah90 oya bg20 shadow w100 pt3rem pl3rem pr3rem pb2rem bsbb df fdc m1rem br20px pr maw80`}>
                            <button className="close-popup" onClick={() => dispatch(toggleModal({ open: '' }))}>
                                {/* <svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                <path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
                            </svg> */}
                            </button>
                            <h1 className="c15 fs2rem fw500 mb2rem">FREE “2-Minute” Test</h1>
                            <div className="df jcsb aic step-box-modal style-two">
                                <img className='w100' src={require('../../../images/landingpage/two-minute-popup.png')} alt='' />
                            </div>
                            <div className='tac w100 db'>
                                <p className='c15 db'>To see how simple the process is, experience it first hand.<br />Take the test and see.</p>
                                <button className='p1rem pl5rem pr5rem shadow c1 bg4 bg3-hover br35px mt1rem mb1rem cp fw600 fs1rem pr zi0 br10px td250 registerButtonAnimation' onClick={() => handleLetsGo()}>Let’s Go!</button>
                            </div>

                        </div>
                        <svg className="pa cp" onClick={() => dispatch(toggleModal({ open: '' }))} style={{ left: '50%', transform: 'translateX(-50%)', bottom: '120px' }} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect opacity="0.8" x="4" y="4" width="52" height="52" rx="26" fill="#151313" />
                            <path d="M48.5606 11.4375C44.8894 7.76665 40.2121 5.26685 35.1201 4.2542C30.0282 3.24156 24.7503 3.76155 19.9539 5.74843C15.1575 7.73531 11.058 11.0998 8.17374 15.4166C5.28947 19.7333 3.75 24.8084 3.75 30C3.75 35.1917 5.28947 40.2667 8.17374 44.5835C11.058 48.9002 15.1575 52.2647 19.9539 54.2516C24.7503 56.2385 30.0282 56.7585 35.1201 55.7458C40.2121 54.7332 44.8894 52.2334 48.5606 48.5625C50.9984 46.1249 52.9322 43.231 54.2516 40.0461C55.5709 36.8611 56.25 33.4474 56.25 30C56.25 26.5526 55.5709 23.1389 54.2516 19.954C52.9322 16.769 50.9984 13.8751 48.5606 11.4375ZM39.3731 36.6375C39.5604 36.8129 39.7098 37.0249 39.8119 37.2603C39.914 37.4958 39.9666 37.7496 39.9666 38.0063C39.9666 38.2629 39.914 38.5168 39.8119 38.7522C39.7098 38.9876 39.5604 39.1996 39.3731 39.375C39.1979 39.5488 38.9901 39.6863 38.7617 39.7796C38.5332 39.8729 38.2886 39.9202 38.0419 39.9188C37.55 39.9167 37.0786 39.7214 36.7294 39.375L29.9981 32.6438L23.3606 39.375C23.0114 39.7214 22.54 39.9167 22.0481 39.9188C21.8013 39.9202 21.5567 39.8729 21.3283 39.7796C21.0998 39.6863 20.8921 39.5488 20.7169 39.375C20.3676 39.0237 20.1716 38.5485 20.1716 38.0531C20.1716 37.5578 20.3676 37.0826 20.7169 36.7313L27.3544 30L20.7169 23.3625C20.4097 23.0038 20.2492 22.5424 20.2674 22.0705C20.2856 21.5986 20.4812 21.151 20.8152 20.8171C21.1491 20.4832 21.5967 20.2875 22.0686 20.2693C22.5405 20.2511 23.0019 20.4116 23.3606 20.7188L29.9981 27.3563L36.6356 20.7188C36.8092 20.539 37.0165 20.3952 37.2457 20.2956C37.4748 20.196 37.7214 20.1424 37.9712 20.1381C38.2211 20.1337 38.4693 20.1786 38.7018 20.2702C38.9343 20.3618 39.1465 20.4983 39.3262 20.6719C39.506 20.8455 39.6498 21.0528 39.7494 21.2819C39.849 21.5111 39.9026 21.7576 39.9069 22.0075C39.9113 22.2573 39.8664 22.5056 39.7748 22.7381C39.6832 22.9706 39.5467 23.1828 39.3731 23.3625L32.6419 30L39.3731 36.6375Z" fill="white" />
                        </svg>
                    </div>
                </Modal>


            </AnimatedPage>
        </div>
    )
}

export default TestPage
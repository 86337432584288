import { createAction, createSlice } from "@reduxjs/toolkit";

export default function getModalReducer(modal = { open: '' }) {
	const modalSlice = createSlice({
		name: "modal",
		initialState: {
			...modal,
			resetTab: false,
			modalHistory: null,
			historyData: null,
			errorData: null,
		},
		reducers: {
			update: (state, action) => {
				return { ...state, ...action.payload };
			},
			resetTab: (state, action) => {
				return { ...state, resetTab: action.payload };
			},
			SET_HISTORY_MODAL: (state, action) => {
				return {
					...state,
					...(action.payload.modalHistory ? { modalHistory: action.payload.modalHistory } : {}),
					...(action.payload.historyData ? { historyData: action.payload.historyData } : {}),
				};
			},
		}
	});

	return modalSlice.reducer;
}

export const toggleModal = createAction("modal/update");
export const resetTabModal = createAction("modal/resetTab");
export const setModalHistory = createAction("modal/SET_HISTORY_MODAL");


import React from 'react'
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import { HashLink } from 'react-router-hash-link';
import { useSelector, useDispatch } from "react-redux";

import TeamMembers from "./TeamMembers";
import TeamPermissions from "./TeamPermissions";

export default function Team({ company, teamMembers, setEditUser, setUserAction, resendInviteMember, handleDeleteMember }) {

    const dispatch = useDispatch();
    let user = useSelector((state) => state.user);

    let [teamTab, setTeamTab] = useState("Team Members");


    return (
        <>
            <div className='dashboard-tools-area'>
                <div className='box-dashbord-container mla mra mb6rem'>
                    <div className='tabs-navlist tabs-dashboard df aic jcc mt2rem mb2rem'>{
                        ['Team Members', 'Team Permissions'].map(item => {
                            return <div className="df fdc">
                                <button className={`fs1-25rem fw500 c15 cp ${teamTab == item && ' active'}`} onClick={() => setTeamTab(item)}>{item}</button>
                            </div>
                        })
                    }
                    </div>
                    {teamTab == 'Team Members' && <TeamMembers company={company} teamMembers={teamMembers} setEditUser={setEditUser} setUserAction={setUserAction} resendInviteMember={resendInviteMember} handleDeleteMember={handleDeleteMember} />}
                    {teamTab == 'Team Permissions' && <TeamPermissions company={company} />}
                </div>
            </div>
        </>
    );
};

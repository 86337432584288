import Switch from '@mui/material/Switch';
import { useEffect, useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';

import { showMessage } from "../../config";

export default function ChecklistSetup({ handleCreateChecklist, isAIChecklist, setIsAIChecklist, checklistObj, setChecklistObj }) {

    let [company, setCompany] = useState({});

    useEffect(() => {
        let id, name;
        if (location?.search) {
            id = (location.search.split('=')[1]).split(';')[0];
            name = (location.search.split('=')[2]).replaceAll('%20', ' ');
            setCompany({ id, name });
        }
    }, []);

    const handleModuleName = (value) => {
        setChecklistObj({ ...checklistObj, moduleName: value });
        if (!checklistObj['genType']) setChecklistObj({ ...checklistObj, genType: 'automatic' });
        if (!checklistObj['companies'] && Object.values(company).length > 0) {
            let companies = { ...checklistObj['companies'], [company['id']]: company['name'] }
            checklistObj = { ...checklistObj, companies };
            setChecklistObj(checklistObj);
        }
    };

    const handleNextStep = async () => {
        if (!checklistObj['moduleName']) return showMessage('Please enter checklist name');
        else handleCreateChecklist();
    };

    return (
        <>
            <div className='checklist-form-area df fww w100 mb5rem'>
                <div className='checklist-form w50'>
                    <div className='pr mt1rem'>
                        <label className='w100 fw500 c15 db' for='checkListName'>Your Checklist Name <span className={!checklistObj['moduleName'] ? 'c40' : 'c34'}>*</span></label>
                        <input defaultValue={checklistObj['moduleName'] || ''} onChange={(e) => handleModuleName(e.target.value)} id="checkListName" placeholder="e.g. Human Resources Checklist 1.0" className='lpins w100 br10px p1rem mt0-5rem' type="text" />
                    </div>
                    <div className='pr mt1rem zi9'>
                        <label className='w100 fw500 c15 db op50' for='checkListName'>Price</label>
                        <div className="tar tooltip-box w100 db">
                            <i className="icon pa cp" style={{ left: '35px', bottom: '10px' }}><img style={{ height: '13px' }} src={require(`../../../images/info-icon.svg`)} alt="info" /></i>
                            <span className="tooltip-area w100" style={{ bottom: "50px", left: "65px" }}>
                                <div className="overlay-box"><p className="fs0-75rem c15">Advisors and Investors can charge for checklists with prior arrangement with Diligentsia</p></div>
                            </span>
                        </div>
                        <Dropdown className="dropdown-add-group">
                            <Dropdown.Toggle variant="success" id="dropdown-basic" className='dropdown-btn cp op50 lpins br10px p1rem pr mt0-5rem'>
                                {checklistObj?.['price'] ? <span>&#163;{checklistObj['price']}</span> : 'FREE'}
                                <span className="icon-right pa r0 mr1rem">
                                    <i className="icon">
                                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.3369 16.67C11.1615 16.671 10.9876 16.6374 10.8251 16.571C10.6627 16.5047 10.515 16.4069 10.3904 16.2834L2.39204 8.285C2.14102 8.03398 2 7.69352 2 7.33852C2 6.98352 2.14102 6.64306 2.39204 6.39204C2.64306 6.14102 2.98352 6 3.33852 6C3.69352 6 4.03398 6.14102 4.285 6.39204L11.3369 13.4573L18.3888 6.40537C18.6438 6.18698 18.9719 6.07286 19.3074 6.08582C19.6429 6.09878 19.9611 6.23786 20.1986 6.47528C20.436 6.71269 20.575 7.03094 20.588 7.36644C20.601 7.70194 20.4868 8.02998 20.2685 8.285L12.2701 16.2834C12.0218 16.5297 11.6866 16.6685 11.3369 16.67Z" fill="#4A67FF" />
                                        </svg>
                                    </i>
                                </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-content cp shadow fww">
                                {[50, 80, 100, 150].map(price => { return <Dropdown.Item onClick={() => setChecklistObj({ ...checklistObj, price })} className='tal'>&#163;{price} <span style={{ color: '#909090', fontSize: '10px' }}>(Plus VAT, if applicable)</span></Dropdown.Item> })}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className='pr mt1rem'>
                        <h4 className='c15 fs1-25rem mb0-5rem'>Manual V AI-generated?</h4>
                        <div className='manual-ai-area w100 pr fww'>
                            <div className='switch-area df aic'>
                                <p className='c15'>Manual</p>
                                <Switch name='manualai' className="success w100" checked={isAIChecklist} onChange={(e) => { setIsAIChecklist(!isAIChecklist), setChecklistObj({ ...checklistObj, genType: e.target.checked ? 'automatic' : 'manual' }) }} />
                                <p className='c15'>AI-generated</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='selection-area-right w50 mt1rem pl5rem bsbb'>
                    <div className='manual-ai-area w100 pr fww'>
                        <div className='right-ai-area'>
                            <div className='img-box pr'>
                                <img className="" src={require(`../../../images/aifemale.png`)} alt="" />
                                <div className='ovelay-box'>
                                    <p className='c15 fs0-75rem' style={{ color: '#4459AC' }}>AI-generated if you would like me to create relevant checklist and questions myself. Or you can create the questions yourself manually</p>
                                </div>
                            </div>
                        </div>
                        <div className='tip-box w100 p2rem bg20 w100' style={{ marginTop: '-6px', borderRadius: '40px' }}>
                            <h5 className='c15 fs0-75rem df aic mb1rem' style={{ fontSize: '14px' }}>
                                <i className='icon mr1rem'>
                                    <img className="db w2rem" src={require(`../../../images/tip-icon.svg`)} alt="" />
                                </i>
                                Top Tip
                            </h5>
                            <p className='c15 fs0-75rem' style={{ fontSize: '12px', color: '#15131380' }}>
                                It is worth you considering the shape and purpose of your checklist at the outset. Who is it for?  What is its purpose? How long do you will the user prepared to spend on it?  This will help you choose the variables to deliver your objective.  Variables you select later will include:​<br />
                                <ul>
                                    <li>Checklist length – 20/50/100 questions
                                        <li>Along with automatic recommendations, do you want the user to have their answers risk-scored?​</li>
                                        <li>Is the checklist to be issued to be used internally or for completion by external parties?</li>
                                        <li>If you are an advisor or investor, do you want the checklist to be made to a single company or made available on all your client/investor dashboards?​</li>
                                        <li>Do you want to restrict access to it?​</li>
                                    </li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bottom-btn-area df aic w100 jcsb mt5rem bsbb'>
                <p className='c15 fs1rem' style={{ color: '#15131350' }}>1/6 Checklist Setup <span class="fs0-75rem">( * = fields requiring mandatory completion)</span></p>
                <button disabled={!checklistObj['moduleName']} onClick={() => handleNextStep()} style={{ cursor: !checklistObj['moduleName'] ? 'not-allowed' : 'pointer' }} className={`shadow c1 fw400 fs1rem pr br38px w200px h3rem df aic jcc ${!checklistObj['moduleName'] ? ' bg16' : ' bg4 bg3-hover'}`}>
                    Ok, next
                    <i className='icon pr ml0-5rem' style={{ top: '3px' }}>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.42954 17.0154H21.1966L16.7035 22.1842C15.413 23.6688 17.3488 25.8957 18.6394 24.4111L22.7314 19.6964L25.463 16.5491C25.9924 15.9365 25.9924 14.9471 25.463 14.3344L18.6394 6.47557C18.3798 6.16869 18.0225 5.99649 17.6502 6.00005C16.4212 6.00023 15.818 7.72225 16.7035 8.70263L21.2073 13.8714H6.35888C4.46611 13.9794 4.60744 17.1238 6.42954 17.0154Z" fill="white" />
                        </svg>
                    </i>
                </button>
            </div>
        </>
    )
}
import moment from "moment";
import React, { Fragment } from "react";

export default function FreeKPI({ selectedCompany }) {

    console.log("********selectedCompany::", selectedCompany);


    return <>
        {selectedCompany && <>
            <table id="pdf" className="shadow w70 p2rem ma td250 dg gg2rem new-report df fdc">
                <div className="w100 df aic jcsb pdfHeaderCustom">
                    <div className="w50">
                        <h2 className="tal c15 lh1 mt1rem fs1-25rem ">KPI Report</h2>
                        <h3 className="fsi tal c15 fs1rem fw400 mb1rem">{selectedCompany['cName']}</h3>
                        <p className="mt3rem c15">Report Date: <span className="fw600">{moment(new Date()).format('MMM DD, YYYY')}</span></p>
                    </div>
                    <div className="w50 df fdc jcsb aife">
                        <img className="w80px h80px mb2rem" src={require("../../../images/bonus.svg")} alt="bonusIcon" />
                        <p className="c15 fs12px fw500 w100 tar">Bespoke KPI report for your company</p>
                    </div>
                </div>
                <>
                    <div className="bg52 br5px py0-5rem df aic jcsb mb1rem mt1rem">
                        <p className="miw8rem c1 ml1rem">#KPI Name</p>
                        <p className="miw8rem c1">KPI Description</p>
                        <p className="miw8rem c1">KPI Calculation</p>
                        <p className="miw8rem c1">KPI Metric</p>
                    </div>
                    {selectedCompany?.['keyPerformanceIndicators'] && selectedCompany['keyPerformanceIndicators'].map((kpi, idx) => (
                        <tr key={idx}>
                            <td className="miw8rem tal c15 fs12px fw500 fsi"><b>{kpi['title'] || '-'}</b></td>
                            <td className="miw8rem tal c15 fs12px">{kpi['name'] || '-'}</td>
                            <td className="miw8rem tal c15 fs12px">{kpi['calculation'] || '-'}</td>
                            <td className="miw8rem tal c15 fs12px">{kpi['metric'] || '-'}</td>
                        </tr>
                    ))}
                </>
            </table>
        </>}
    </>;
} 
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import * as ROUTES from "../constants/routes";
import { LANDING_PAGE } from "../constants/routes";
import { updateUser } from "../reducers/userReducer";
import { toggleModal } from "../reducers/modalReducer";
import { setLogout, getRequest, showMessage } from '../config';


// Menu list
const PAGE_MENU_LIST = [
    { title: 'Companies', link: LANDING_PAGE },
    { title: 'Accountants', link: `${LANDING_PAGE}-accountants` },
    { title: 'Lawyers', link: `${LANDING_PAGE}-lawyers` },
    { title: 'VCs', link: `${LANDING_PAGE}-vcs` },
    { title: 'PEs', link: `${LANDING_PAGE}-pes` },
    { title: 'Angel Networks', link: `${LANDING_PAGE}-angel-networks` },
    { title: 'Angels', link: `${LANDING_PAGE}-angels` }
]

export default function NewHeader() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    let user = useSelector(state => state.user);

    const [activeMenu, setActiveMenu] = useState('');
    const [isActiveBar, setIsActiveBar] = useState(false);
    const [isFloatingBar, setIsFloatingBar] = useState(false);

    useEffect(() => {
        if (localStorage.getItem("token")) {
            getUser();
            checkLogin();
        }
    }, []);

    const getUser = async () => {
        let response = await getRequest(`/user`);
        if (response['code'] == 200 && response['body']) {
            user = response['body']['user']
            dispatch(updateUser(user));
        };
    };

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.scrollY > 850) setIsFloatingBar(true);
            else setIsFloatingBar(false);
        };

        window.addEventListener('scroll', toggleVisibility);
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);

    useEffect(() => {
        let path = window.location.pathname;
        if (isFloatingBar) {
            if (path == LANDING_PAGE) setActiveMenu('menu-0');
            else if (path == `${LANDING_PAGE}-accountants`) setActiveMenu('menu-1');
            else if (path == `${LANDING_PAGE}-lawyers`) setActiveMenu('menu-2');
            else if (path == `${LANDING_PAGE}-vcs`) setActiveMenu('menu-3');
            else if (path == `${LANDING_PAGE}-pes`) setActiveMenu('menu-4');
            else if (path == `${LANDING_PAGE}-angel-networks`) setActiveMenu('menu-5');
            else if (path == `${LANDING_PAGE}-angels`) setActiveMenu('menu-6');
            else setActiveMenu('');
        };

        if (path == LANDING_PAGE || path == `${LANDING_PAGE}-accountants` || path == `${LANDING_PAGE}-lawyers` || path == `${LANDING_PAGE}-vcs`
            || path == `${LANDING_PAGE}-pes` || path == `${LANDING_PAGE}-angel-networks` || path == `${LANDING_PAGE}-angels` || path == "/") setIsActiveBar(true);
        else setIsActiveBar(false);

    }, [isFloatingBar]);

    const openMenu = () => {
        document.getElementById("menu").classList.add("showing");
        document.getElementById("menuOverlay").classList.remove("op0", "pen");
    };

    const closeMenu = () => {
        document.getElementById("menu").classList.remove("showing");
        document.getElementById("menuOverlay").classList.add("op0", "pen");
    };

    const checkLogin = async () => await getRequest("/user/checkLogin");
    const handleLoginRegister = () => {
        // if (window.innerWidth < 768) return dispatch(toggleModal({ open: "holdingModal" }));
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
        const isMobile = mobileRegex.test(userAgent)
        if (isMobile) {
            return showMessage("You can Login/Register on Laptop")
        }
    };


    //TODO: move to redux
    let loggedIn = localStorage.getItem("token") || localStorage.getItem("userId");



    return (
        <header className="bw0px bss pf t0 l0 w100 bg1 pt0-5rem pb0-5rem new-header" style={{ zIndex: 12, paddingTop: "0px" }}>
            <div className="marque-container w50 mla mra p0-5rem">
                <marquee>
                    <div className="marquee-inner df aic">
                        <button className="pr px1rem py0-5rem c1 br30px fs0-75rem" style={{ backgroundColor: '#6075E8' }}>
                            First 50 Companies
                            <i className="icon"><img className='pa t0 r0 w2rem' style={{ right: '-17px' }} src={require('../../images/register-confetti.png')} /></i>
                        </button>
                        <h2 className="fw500 fs1rem c15 tslp ml2rem">2 years <span className="c4">FREE</span> Company  Pro subscription <span className="fw300">(worth £1,200+)</span></h2>
                    </div>
                </marquee>
            </div>
            <nav className="container headerContainer wsn df w100 jcsb" style={{ padding: '0px 150px', maxWidth: '100%' }}>
                <div className="df jcsb w100 pr">
                    <Link className="df logo-header" to={ROUTES.HOME}>
                        <img className="py0-5rem h2rem" src={require(`../../images/logo.svg`)} alt="TODO" />
                    </Link>
                    <img className="beta-logo" src={require("../../images/landingpage/beta-icon.png")} />
                    <svg onClick={() => openMenu()} className="cp p1rem w2rem h2rem dn-xl f3" viewBox="0 0 448 512">
                        <path d="M16 288h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16z" className="pen td250 fa-secondary" />
                        <path className="pen td250 fa-primary" d="M432 384H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-320H16A16 16 0 0 0 0 80v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16z" />
                    </svg>
                    <div onClick={() => closeMenu()} id="menuOverlay" className="backdrop zi4 cp pen op0 pf t0 l0 w100 h100 td500"></div>
                    <div id="menu" className="c1 c6-xl w100 jce-xl td500 df fdc fdr-xl jcc pf t0 pr-xl wsn menu-safari r0">
                        <div className="df fdc fdr-xl mr2rem">
                            <Link onClick={() => closeMenu()} className="pt0-5rem pb0-5rem c1 cp pl1-5rem pr1-5rem pt0-xl pb0-xl c15-xl brw1px-xl brss-xl bo16-xl" to={ROUTES.HOME}>
                                Home
                            </Link>
                            <Link onClick={() => closeMenu()} className="pt0-5rem pb0-5rem c1 cp pl1-5rem pr1-5rem pt0-xl pb0-xl c14-xl brw1px-xl brss-xl bo16-xl" to={ROUTES.PRICING_PLAN}>
                                Pricing Plans
                            </Link>
                            <Link onClick={() => closeMenu()} className="pt0-5rem pb0-5rem c1 cp pl1-5rem pr1-5rem pt0-xl pb0-xl c14-xl brw1px-xl brss-xl bo16-xl" to={ROUTES.ABOUT_US}>
                                About Us
                            </Link>
                            <Link onClick={() => closeMenu()} className="pt0-5rem pb0-5rem c1 cp pl1-5rem pr1-5rem pt0-xl pb0-xl c14-xl " to={ROUTES.BLOGS}>
                                Blog
                            </Link>

                        </div>
                        <div className="df fdc fdr-xl mr2rem">
                            <a href="https://koalendar.com/e/meet-with-mark-bernstein-koaSc6kO?embed=true" target="_blank" className="df jcc aic pt0-5rem pb0-5rem c0 cp pl1-5rem pr1-5rem pt0-xl pb0-xl">
                                <img className="w20px h20px" src={require(`../../images/phone-call.png`)} alt="Call Us" />
                                <span style={{ marginLeft: '5px' }} className="fw400 fs1rem">Call Us</span>
                            </a>
                            {loggedIn
                                ? <>
                                    <Link onClick={() => closeMenu()} to={Object.values(user).length > 0 && (user['role'] == 'Guest' ? ROUTES.REGISTER : user['role'] == 'Advisor' ? ROUTES.ADVISOR : user['role'] == 'Investor' ? ROUTES.INVESTOR : ROUTES.DASHBOARD)}
                                        style={{ textDecoration: 'none', cursor: user['role'] != 'Guest' ? 'pointer' : 'not-allowed' }} className={`bsbb jcc w200px wa-xl df aic shadow fw500 py0-5rem px1rem br5px my1rem my0-xl mr1rem-xl ${user['role'] != 'Guest' ? 'bg3 c1' : 'bg16 c2'}`}>
                                        Dashboard
                                    </Link>
                                    <Link onClick={() => setLogout()} className="bsbb jcc w200px wa-xl df aic shadow fw500 py0-5rem px1rem c1 bg3 br5px my1rem my0-xl mr1rem-xl cp" to='#'>
                                        Logout
                                    </Link>
                                </>
                                : <>
                                    <Link to={ROUTES.LOGIN} onClick={() => handleLoginRegister()} className="bsbb jcc w200px w9rem df aic fw500 py0-5rem px1rem c1 bg3 br30px my1rem my0-xl cp mr1-5rem-xl">
                                        Login
                                    </Link>
                                    <>
                                        <Link to={ROUTES.REGISTER} onClick={() => handleLoginRegister()} className="bsbb jcc w200px w9rem df fdc aic fw500  px1rem c1 bg4 br30px cp registerButtonAnimation" style={{ width: '70%', paddingTop: '3px', paddingBottom: '3px' }}>
                                            <span>Register for FREE</span>
                                            <span className="fw400 c21 df fdc" style={{ marginTop: "-2px", fontSize: '10px', lineHeight: '15px', fontStyle: 'italic' }}>No credit card required</span>
                                        </Link>
                                        <img className='pa t0 r0' src={require('../../images/register-confetti.png')} />
                                    </>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </nav>

            {isActiveBar && isFloatingBar &&
                <div className='df jcc'>
                    <div className={`mt0-5rem pf br br35px df jcsa aic zi1 w60 p0-5rem mb3rem narBarAnimation`}
                        style={{ left: "15%", width: "65%", zIndex: 11, backgroundColor: 'rgba(103, 117, 185, 0.584)', backdropFilter: 'blur(20px)' }}>
                        {PAGE_MENU_LIST.map((obj, idx) =>
                            <div onClick={() => navigate(obj?.link)} key={obj?.title}
                                style={{ lineHeight: "30px" }}
                                className={`cp c1  fs1-2em-screen4 fs1rem-screen1 ${activeMenu == `menu-${idx}` ? 'fw700' : 'fw400'}`}>
                                {obj?.title}
                            </div>
                        )}
                    </div>
                </div>
            }
        </header>
    );
}


import { useEffect, useState } from 'react';

const ProgressBar = ({ isFireWork }) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        setProgress(90);
        if (isFireWork) {
            setProgress(100);
        }
    }, [isFireWork]);

    return (
        <div className="progressBarCustom">
            <div className="progress-value" style={{ width: `${progress}%`, transition: `${isFireWork ? 'width 0.5s ease-out' : 'width 3s ease-out'}` }}></div>
        </div>
    );
};

export default ProgressBar;

import { Link } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import { maskEmail } from 'react-email-mask';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import 'react-email-mask/dist/index.css';
import { toggleModal } from '../../../reducers/modalReducer';
import { getRequest, postRequest, showMessage } from '../../../config';


const investorLookup = ({ refs, isAnimationVisible }) => {

    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const modal = useSelector(state => state.modal);

    let [stepNo, setStepNo] = useState(1);
    let [executable, setExecutable] = useState({});
    let [isConfirmed, setIsConfirmed] = useState(false);
    let [isSearchable, setIsSearchable] = useState(true);
    let [isValidEmail, setIsValidEmail] = useState(false);
    let [companyOptions, setCompanyOptions] = useState([]);
    let [isInvestorBio, setIsInvestorBio] = useState(false);
    let [selectedCompany, setSelectedCompany] = useState({});
    let [formData, setFormData] = useState({ orgName: '', ukRegNo: '', name: '', email: '' });

    let searchTimeout;
    let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let invalidEmails = ['@hotmail.com', '@gmail.com'];
    // let invalidEmails = ['@hotmail.com', '@gmail.com', '@yopmail.com'];
    let otherSections = ['About the Company', 'Fit Metrics', 'Diligence Reports', 'Detailed Answers', 'Investors Bespoked Q/A'];

    useEffect(() => {
        if (!formData?.['orgName'] || formData?.['orgName'] == '') {
            setCompanyOptions([]);
            setSelectedCompany({});
        }
    }, [formData?.['orgName']]);

    useEffect(() => {
        if (selectedCompany && selectedCompany['title']) {
            setIsSearchable(false);
            setFormData({ ...formData, orgName: selectedCompany['title'], ukRegNo: selectedCompany['company_number'] });
        }
    }, [selectedCompany]);

    const resetStates = () => {
        setStepNo(1);
        setIsValidEmail(false);
        setIsConfirmed(false);
        setCompanyOptions([]);
        setSelectedCompany({});
        setFormData({ orgName: '', ukRegNo: '', name: '', email: '' });
        setExecutable({});
    };

    const searchCompany = async (input) => {
        if (!input) return;

        const response = await getRequest(`/company/search/${input}`);
        if (!response || response['code'] != 200) return;
        setCompanyOptions(response['body']['items'].slice(0, 5));
    };

    const searchCompanyTimeout = (e) => {
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(() => searchCompany(e.target.value), 1000);
    };

    const handleSelectCompany = (company) => {
        // if (!company['isInvestorExists']) showMessage(`This company can't be selected`);
        // else {
        setSelectedCompany(company);
        setCompanyOptions([]);
        // }
    };

    const handleEmailBlur = () => {
        if (emailRegex.test(formData?.['email']) == false) showMessage('Please enter valid email');
        else if (invalidEmails.some(invalidEmail => formData?.['email'].endsWith(invalidEmail))) showMessage('This email domain is not allowed');
        else setIsValidEmail(true);
    };

    const requestInvestorAccess = async () => {
        let data = {
            ...executable?.['orgDetails'],
            isInvestorLookup: true,
            userEmail: formData['email'],
            userLastName: (formData['name'].split(' '))?.length > 1 ? (formData['name'].split(' '))?.[1] : '',
            userFirstName: (formData['name'].split(' '))?.length > 1 ? (formData['name'].split(' '))?.[0] : formData['name'],
        };

        let response = await postRequest(`/company/requestInvestorAccess`, data);
        if (response['code'] == 200) {
            showMessage(response['message']);
            setStepNo(3);
        }
    };

    const handleInvestorLookupClose = () => {
        if (stepNo == 1 || stepNo == 2) {
            dispatch(toggleModal({ open: '' }));
            resetStates();
        } else if (stepNo == 3) {
            if (['login', 'emailOwner'].includes(executable?.['action'])) {
                dispatch(toggleModal({ open: '' }));
                resetStates();
            } else setStepNo(2);
        }
    };

    const handleAction = async () => {
        if (stepNo == 1) {
            if (formData?.['orgName'] == '' || formData?.['ukRegNo'] == '' || formData?.['name'] == '' || formData?.['email'] == '') return showMessage('Please fill out all fields');
            if ((formData['name'].split(' '))?.length == 1) return showMessage('Please provide full name');
            if (invalidEmails.some(invalidEmail => formData?.['email'].endsWith(invalidEmail))) return showMessage('This email domain is not allowed');
            if (!isConfirmed) return showMessage('Please check the confirmation');

            const response = await postRequest('/company/investorLookup', { orgName: formData?.['orgName'], ukRegNo: formData?.['ukRegNo'], firstName: formData?.['firstName'], lastName: formData?.['lastName'], email: formData?.['email'] });
            if (response && response['code'] == 200) {
                setExecutable(response['body']);
                setStepNo(2);
                showMessage(response['message']);
            }
        } else if (stepNo == 2) {
            if (executable?.['action'] == 'register') {
                console.log("*********register");
                dispatch(toggleModal({ open: '' }));
                resetStates();
                window.location = '/register'

                // Redirect to login route
            } // else if (executable?.['action'] == 'login') window.location = '/login';
            else if (executable?.['action'] == 'emailOwner') await requestInvestorAccess();
            else if (executable?.['action'] == 'matched' || executable?.['action'] == 'login') {
                dispatch(toggleModal({ open: '' }));
                if (executable?.['isVerified']) window.location = '/login';
                else window.location.href = `${location.origin}/reset_password/${executable?.['temp_password']}`;
                resetStates();
            }
        }
    };

    const handleBack = () => {
        if (stepNo == 1 && selectedCompany && selectedCompany['title']) setSelectedCompany({});
        else if (stepNo == 2) setExecutable({});
        setStepNo(stepNo - 1);
    };

    const aboutTheInvestorContent = () => {
        console.log("********executable::", executable);

        return (
            <div class='h80vh'>
                <div className="" id="About the Investor">
                    <h2 className="df jcc aic mb1rem fs1-5rem">
                        About the Investor
                    </h2>
                </div>
                <div className="report-start invest-reports">
                    <div className={`progress-area-report`}>
                        <div className="pr bg1 p0-5rem shadow df fww aic mb1rem w80 mla mra br8px">
                            <div className={`user-area w30`}>
                                <div className="logo-box df jcc fww w100 mb0-5rem pt1rem blur-disabled">
                                    {executable?.['organisation']?.['company']?.['source']?.['isBVCA'] && <img className="w30 mr0-5rem" src={require(`../../../../images/bvca-logo.png`)} alt="image" />}
                                    {executable?.['organisation']?.['company']?.['source']?.['isCrunchBase'] && <img className="w30 mr0-5rem" src={require(`../../../../images/crunchbase-logo.png`)} alt="image" />}
                                    {executable?.['organisation']?.['company']?.['source']?.['isSeedLegals'] && <img className="w2rem " src={require(`../../../../images/swwdleagals.png`)} alt="image" />}
                                </div>
                            </div>
                            <div className={`user-area w40`}>
                                <div className="logo-box df jcc fww w100 mb0-5rem pt1rem">
                                    <img className="shadow h4rem w4rem br100 mb1rem blur-disabled" src={executable?.['organisation']?.['company']?.['logo'] || require(`../../../../images/profile-photo.jpeg`)} alt="image" />
                                    <a className="user-text-fit db tac w100 jcc lh2 fs1rem my0-5rem">{executable?.['organisation']?.['company']?.['cName']}</a>
                                </div>
                            </div>
                            <div className='progress-box w30'>
                                <div className="progress-bar blur-disabled" style={{ width: 40, height: 40 }}>
                                    <CircularProgressbar
                                        className={`${executable?.['organisation']?.['company']?.['promptScore'] > 67 ? 'green'
                                            : executable?.['organisation']?.['company']?.['promptScore'] > 33 ? 'orange'
                                                : 'red'}`}
                                        value={executable?.['organisation']?.['company']?.['promptScore'] || 0}
                                        text={(executable?.['organisation']?.['company']?.['promptScore'] || 0) + "%"}
                                        styles={buildStyles({
                                            strokeLinecap: 'round',
                                            strokeWidth: '5px',
                                            textSize: '20px',
                                            pathTransitionDuration: 0.5,
                                            trailColor: '#d6d6d6',
                                            backgroundColor: '#fc7785',
                                            dominantBaseline: 'ideographic',
                                        })}
                                    />
                                </div>
                                <span style={{ marginBottom: '0', fontSize: '10px' }} className="text-center-area">Prompt Response Score</span>
                            </div>
                        </div>
                        <div className='progress-area dg gtcr2a gg1rem mb1-3rem'>
                            <div className="h100 df fdc jcsb aic">
                                <ul className="list-company-detail">
                                    <li>
                                        <p className="ass fs0-75rem">Full Company Name: </p>
                                        <h2 className="fs0-75rem fw700 lh1 usn c6 blur-disabled">{executable?.['organisation']?.['company']['cName'] ? executable?.['organisation']?.['company']['cName'] : '-'}</h2>
                                    </li>
                                    <li>
                                        <p className="ass fs0-75rem">Address: </p>
                                        <h2 className="fs0-75rem fw700 lh1 usn c6 blur-disabled">{executable?.['organisation']?.['company']['cAddress'] ? executable?.['organisation']?.['company']['cAddress'] : '-'}</h2>
                                    </li>
                                    <li>
                                        <p className="ass fs0-75rem">Website: </p>
                                        <h2 className="fs0-75rem fw700 lh1 usn c6 blur-disabled">{executable?.['organisation']?.['company']['cWebsite']
                                            ? <a className="tdu wordbreak" href={executable?.['organisation']?.['company']['cWebsite']} target="_blank">{executable?.['organisation']?.['company']['cWebsite']}</a>
                                            : '-'}</h2>
                                    </li>
                                    <li>
                                        <p className="df aic ass fs0-75rem tar">Tel:
                                            <h2 className="fs0-75rem fw700 lh1 usn c6 tar ml0-5rem blur-disabled">
                                                {(executable?.['organisation']?.['company']['cPhone'] && executable?.['organisation']?.['company']['cPhone'] !== '?')
                                                    ? <>{executable?.['organisation']?.['company']?.['cPhone']} <button class="cp bg1" id='copy'><i id={executable?.['organisation']?.['company']?.['cPhone']} class="fa fa-clipboard c4" style={{ fontSize: '12px' }}></i></button></>
                                                    : '-'}
                                            </h2>
                                        </p>
                                    </li>
                                    <li>
                                        <p className="ass fs0-75rem">Business Location: </p>
                                        <h2 className="fs0-75rem fw700 lh1 usn c6 blur-disabled">{executable?.['organisation']?.['company']?.['cCountry'] ? executable?.['organisation']?.['company']?.['cCountry'] : '-'}</h2>
                                    </li>
                                    <li>
                                        <p className="ass fs0-75rem">Funds for Investment: </p>
                                        <h2 className="fs0-75rem fw700 lh1 usn c6 blur-disabled">{executable?.['organisation']?.['company']?.['fundSize'] ? executable?.['organisation']?.['company']?.['fundSize'] : '-'}</h2>
                                    </li>
                                    <li>
                                        <p className="ass fs0-75rem">No. of Investments made: </p>
                                        <h2 className="fs0-75rem fw700 lh1 usn c6 blur-disabled">{executable?.['organisation']?.['company']?.['investment']?.['noOfInvestments'] ? executable?.['organisation']?.['company']?.['investment']?.['noOfInvestments'] : '-'}</h2>
                                    </li>
                                    <li>
                                        <p className="ass fs0-75rem">Date of last Investment: </p>
                                        <h2 className="fs0-75rem fw700 lh1 usn c6 blur-disabled">{executable?.['organisation']?.['company']?.['investment']?.['lastInvestmentDate'] ? executable?.['organisation']?.['company']?.['investment']?.['lastInvestmentDate'] : '-'}</h2>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="description-box mt1rem mb1rem pr bg1 p1rem shadow df fww aic br8px">
                    <h4 className="fs1rem mb0-75rem">Description</h4>
                    <><p className="fs0-75rem blur-disabled">
                        {executable?.['organisation']?.['company']?.['description'] ? (
                            executable?.['organisation']?.['company']?.['description'].length > 800
                                ? <>{executable?.['organisation']?.['company']?.['description'].substring(0, 800)}...</>
                                : executable?.['organisation']?.['company']?.['description']
                        ) : executable?.['organisation']?.['company']?.['companyDescription'] ? (
                            executable?.['organisation']?.['company']?.['companyDescription'].length > 800
                                ? <>{executable?.['organisation']?.['company']?.['companyDescription'].substring(0, 800)}...</>
                                : executable?.['organisation']?.['company']?.['companyDescription']
                        ) : '-'}
                    </p></>
                </div>
                <div className="description-box mt1rem mb1rem pr bg1 p1rem shadow df fww aic br8px">
                    <h4 className="fs1rem mb0-75rem">Investment Criteria</h4>
                    <><p className="fs0-75rem blur-disabled">
                        {executable?.['organisation']?.['company']?.['investmentCriteria']
                            ? executable?.['organisation']?.['company']?.['investmentCriteria'].length > 800
                                ? <>{executable?.['organisation']?.['company']?.['investmentCriteria'].substring(0, 800)}...</>
                                : executable?.['organisation']?.['company']?.['investmentCriteria']
                            : '-'}
                    </p></>
                </div>
                <div className="report-start invest-reports">
                    <div className={`progress-area-report`}>
                        <div className={`pr bg1 p0-5rem shadow df fww aic mb1rem w60 mla mra br8px`}>
                            <div className={`user-area w20 blur-disabled`}>
                                <div className="user-img">
                                    <img className=" h4rem w4rem br100" src={executable?.['organisation']?.['company']?.['creatorId']?.['profile'] || executable?.['organisation']?.['company']?.['creatorId']?.['profilePicture'] || require(`../../../../images/profile-photo.jpeg`)} alt="image" />
                                </div>
                            </div>
                            <div className={`progress-box w50 blur-disabled`}>
                                <div className="text-box tal">
                                    <h4 className="fs0-75rem df">
                                        <p>{executable?.['organisation']?.['company']?.['creatorId']?.['firstName'] ? executable?.['organisation']?.['company']?.['creatorId']?.['firstName'] + ' ' + executable?.['organisation']?.['company']?.['creatorId']?.['lastName'] : 'Name'}</p>
                                        <button id={executable?.['organisation']?.['company']?.['creatorId']?.['linkedIn'] ? 'investorLinkedIn' : 'noLinkedIn'} className="icon ml0-5rem bg1 cp" style={{ width: '22px' }}>
                                            <i id={executable?.['organisation']?.['company']?.['creatorId']?.['linkedIn']}><svg id={executable?.['organisation']?.['company']?.['creatorId']?.['linkedIn']} version="1.1" className="f3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 310">
                                                <g>
                                                    <path d="M72.16,99.73H9.927c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5H72.16c2.762,0,5-2.238,5-5V104.73
													C77.16,101.969,74.922,99.73,72.16,99.73z"/>
                                                    <path d="M41.066,0.341C18.422,0.341,0,18.743,0,41.362C0,63.991,18.422,82.4,41.066,82.4
													c22.626,0,41.033-18.41,41.033-41.038C82.1,18.743,63.692,0.341,41.066,0.341z"/>
                                                    <path d="M230.454,94.761c-24.995,0-43.472,10.745-54.679,22.954V104.73c0-2.761-2.238-5-5-5h-59.599
													c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5h62.097c2.762,0,5-2.238,5-5v-98.918c0-33.333,9.054-46.319,32.29-46.319
													c25.306,0,27.317,20.818,27.317,48.034v97.204c0,2.762,2.238,5,5,5H305c2.762,0,5-2.238,5-5V194.995
													C310,145.43,300.549,94.761,230.454,94.761z"/>
                                                </g>
                                            </svg></i>
                                        </button>
                                    </h4>
                                    <p className="fs0-75rem c6">Phone: {executable?.['organisation']?.['company']?.['creatorId']?.['phone']
                                        ? <>{executable?.['organisation']?.['company']?.['creatorId']?.['phone']} <button class="cp bg1" id='copy'><i id={executable?.['organisation']?.['company']?.['creatorId']?.['phone']} class="fa fa-clipboard c4" style={{ fontSize: '12px' }}></i></button></>
                                        : '-'}</p>
                                </div>
                            </div>
                            <div className="button-list w30">
                                <button
                                    className="shadow bg3 bg4-hover c1 td250 br5px py0-5rem px2rem fw600 usn cp ttu bsbb tac df jcc aic mla mra fs0-75rem"
                                    onClick={() => setIsInvestorBio(true)}
                                >
                                    Bio
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="over-content-btn" className="btn-group fit-btn-group mt2rem">
                    {otherSections.map((section, idx) => {
                        return <button id={`content-btn-${idx}`} style={{ marginBottom: '5px' }} className={`df c6 jcc ttc td250 c3-hover px1rem py0-5rem new-report-btn reports-btn-area`}>
                            <span className="pen usn ass mr0-5rem tac fs1rem">{section}</span>
                        </button>
                    })}
                </div>
            </div>
        );
    };

    return (
        <>

            <div id="investor-lookup" className="pr bg20 lpbb investorLookup-container">
                <div ref={refs?.current?.[8]} className={`tslp pb2rem ${isAnimationVisible[8] ? 'op1 tty0' : 'op0 ttx20'}`}>
                    <p className="fw500 tac c15 fs2rem" style={{ lineHeight: '162.2px', }}>
                        Investor & Applicant Curated <span className="c23" >Lead Matching</span>
                    </p>
                </div>
                <div className='investor-box df fww aifs'>
                    <div className='left-certificate-area df fww w70 pt3rem pb1rem'>
                        <div className='certificate-box tac w20 pr mr1rem'>
                            <img className='pa round-box' src={require('../../../../images/landingpage/ball-certficate.png')} alt='' />
                            <h3 className='fs1rem c1 mb2rem'>Gold Certified</h3>
                            <img className='w8rem' src={require('../../../../images/landingpage/diligentsia-certification.png')} alt='' />
                        </div>
                        <div className='certificate-box tac w20 pr'>
                            <img className='pa round-box' src={require('../../../../images/landingpage/ball-certficate.png')} alt='' />
                            <h3 className='fs1rem c15 mb0-5rem'>Applicant <br /> Company Pack</h3>
                            <img className='w8rem' src={require('../../../../images/landingpage/form-blur.png')} alt='' />
                        </div>
                        <div className='certificate-box tac w35 pr mr1rem'>
                            <img className='pa round-box' src={require('../../../../images/landingpage/ball-certficate.png')} alt='' />
                            <h3 className='fs1rem c15 mb1rem'>“Fit” Report</h3>
                            <img className='w200px' src={require('../../../../images/landingpage/fit-report-blur.png')} alt='' />
                        </div>
                        <div className='certificate-box tac w20 pr'>
                            <img className='pa round-box' src={require('../../../../images/landingpage/ball-certficate.png')} alt='' />
                            <h3 className='fs1rem c15 mb1rem'>Investor Pack</h3>
                            <img className='w8rem' src={require('../../../../images/landingpage/investor-form.png')} alt='' />
                        </div>
                    </div>
                    <div className='w30 list-right-area'>
                        <div className='bg1 br15px shadow p2rem benefits-box'>
                            <h3 className='fs1-5rem c15 mb1rem'>Benefits For Investor</h3>
                            <div class="pt1rem">
                                <ul class="custom-list">
                                    <li style={{ paddingTop: '10px', paddingBottom: '10px' }} className="c14 fs0-75rem fw400 df aic">
                                        FREE leads generation
                                    </li>
                                    <li style={{ paddingTop: '10px', paddingBottom: '10px' }} className="c14 fs0-75rem fw400 df aic">
                                        Only for companies with gold certification
                                    </li>
                                    <li style={{ paddingTop: '10px', paddingBottom: '10px' }} className="c14 fs0-75rem fw400 df aic">
                                        Matched to your investment criteria
                                    </li>
                                    <li style={{ paddingTop: '10px', paddingBottom: '10px' }} className="c14 fs0-75rem fw400 df aic">
                                        Diligentsia Pack supplied with the lead:
                                    </li>
                                </ul>
                                <p className='c14 fs0-75rem fw400 df aic ml2rem'>- Data Repository Report</p>
                                <p className='c14 fs0-75rem fw400 df aic ml2rem'>- 20-section Business Audit</p>
                                <p className='c14 fs0-75rem fw400 df aic ml2rem'>- Directors’ Questionnaires</p>
                                <p className='c14 fs0-75rem fw400 df aic ml2rem'>- Draft Warranty Disclosures</p>
                            </div>
                        </div>
                    </div>
                    <div className="w100 investor-lookup-full-img" style={{ display: 'none' }}>
                        <img className='w100' src={require('../../../../images/landingpage/application-moda-img.png')} alt='' />
                    </div>
                </div>
                <div className="pr tac mt2rem">
                    <p className="fw400 fs1rem w100 tac c15 mb1rem" style={{ lineHeight: '24px', fontSize: '0.96rem' }}>
                        Have we already added your fund/firm and contact details to our database from publicly available information?<br /> You can check here (and later update/amend)
                    </p>
                    <button className='p1rem pl2rem pr2rem shadow c1 bg4 bg3-hover br35px mt1rem mb1rem mr2-5rem cp fw600 fs1rem pr zi0 br10px td250 registerButtonAnimation' onClick={() => dispatch(toggleModal({ open: 'howItWorks' }))}>Investor Database Check</button>
                </div>
            </div>

            {/* How does it work? Modal */}
            <Modal open={modal['open'] == 'howItWorks'} className="backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic" >
                <div className='df jcc'>
                    <div className={`mah90 oya bg20 shadow w100 pt3rem pl3rem pr3rem pb2rem bsbb df fdc m1rem br20px pr maw80`}>
                        {/* <button className='close-popup' onClick={() => { dispatch(toggleModal({ open: '' })), resetStates() }}>
                            <svg style={{ position: "relative", top: "4px" }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                <path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
                            </svg>
                        </button> */}
                        <h1 className="c15 fs2rem fw400 mb2rem">How does it work?</h1>
                        <div className="df jcsb aic step-box-modal">
                            <div className='step-box-box-1 w100'>
                                <img className='w100' src={require('../../../../images/landingpage/how-modal.png')} alt='' />
                            </div>
                            {/* <div className='step-box-box-1'>
                                <img className='w100' src={require('../../../../images/landingpage/how-modal-2.png')} alt='' />
                            </div>
                            <div className='step-box-box-2'>
                                <img className='w100' src={require('../../../../images/landingpage/how-modal-3.png')} alt='' />
                            </div> */}
                        </div>
                        <div className='df jcc mt1rem'>
                            <button className='p1rem pl4rem pr4rem shadow c1 bg4 bg3-hover br35px mt1rem mb1rem mr2-5rem cp fw600 fs1rem pr zi0 br10px td250 registerButtonAnimation' onClick={() => dispatch(toggleModal({ open: 'investorDatabaseCheck' }))}>Let’s Go!</button>
                        </div>
                    </div>
                    <svg className="pa cp" onClick={() => { dispatch(toggleModal({ open: '' })), resetStates() }} style={{ left: '50%', transform: 'translateX(-50%)', bottom: '100px' }} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.8" x="4" y="4" width="52" height="52" rx="26" fill="#151313" />
                        <path d="M48.5606 11.4375C44.8894 7.76665 40.2121 5.26685 35.1201 4.2542C30.0282 3.24156 24.7503 3.76155 19.9539 5.74843C15.1575 7.73531 11.058 11.0998 8.17374 15.4166C5.28947 19.7333 3.75 24.8084 3.75 30C3.75 35.1917 5.28947 40.2667 8.17374 44.5835C11.058 48.9002 15.1575 52.2647 19.9539 54.2516C24.7503 56.2385 30.0282 56.7585 35.1201 55.7458C40.2121 54.7332 44.8894 52.2334 48.5606 48.5625C50.9984 46.1249 52.9322 43.231 54.2516 40.0461C55.5709 36.8611 56.25 33.4474 56.25 30C56.25 26.5526 55.5709 23.1389 54.2516 19.954C52.9322 16.769 50.9984 13.8751 48.5606 11.4375ZM39.3731 36.6375C39.5604 36.8129 39.7098 37.0249 39.8119 37.2603C39.914 37.4958 39.9666 37.7496 39.9666 38.0063C39.9666 38.2629 39.914 38.5168 39.8119 38.7522C39.7098 38.9876 39.5604 39.1996 39.3731 39.375C39.1979 39.5488 38.9901 39.6863 38.7617 39.7796C38.5332 39.8729 38.2886 39.9202 38.0419 39.9188C37.55 39.9167 37.0786 39.7214 36.7294 39.375L29.9981 32.6438L23.3606 39.375C23.0114 39.7214 22.54 39.9167 22.0481 39.9188C21.8013 39.9202 21.5567 39.8729 21.3283 39.7796C21.0998 39.6863 20.8921 39.5488 20.7169 39.375C20.3676 39.0237 20.1716 38.5485 20.1716 38.0531C20.1716 37.5578 20.3676 37.0826 20.7169 36.7313L27.3544 30L20.7169 23.3625C20.4097 23.0038 20.2492 22.5424 20.2674 22.0705C20.2856 21.5986 20.4812 21.151 20.8152 20.8171C21.1491 20.4832 21.5967 20.2875 22.0686 20.2693C22.5405 20.2511 23.0019 20.4116 23.3606 20.7188L29.9981 27.3563L36.6356 20.7188C36.8092 20.539 37.0165 20.3952 37.2457 20.2956C37.4748 20.196 37.7214 20.1424 37.9712 20.1381C38.2211 20.1337 38.4693 20.1786 38.7018 20.2702C38.9343 20.3618 39.1465 20.4983 39.3262 20.6719C39.506 20.8455 39.6498 21.0528 39.7494 21.2819C39.849 21.5111 39.9026 21.7576 39.9069 22.0075C39.9113 22.2573 39.8664 22.5056 39.7748 22.7381C39.6832 22.9706 39.5467 23.1828 39.3731 23.3625L32.6419 30L39.3731 36.6375Z" fill="white" />
                    </svg>
                </div>
            </Modal >

            {/* Investor Lookup */}
            <Modal open={modal['open'] == 'investorDatabaseCheck'} className="backdrop td500 zi4 pf t0 l0 w100vw h100vh df jcc aic investor-modal-database" >
                <div className='df jcc'>
                    <div className={`mah90 oya bg1 shadow w100 pt3rem pl3rem pr3rem pb2rem bsbb df fdc m1rem br5px pr smp1rem form-modal-steps ${!(stepNo == 2 || stepNo == 3) ? 'maw600px' : 'maw700px'}`}>
                        {/* <button className='close-popup' onClick={() => handleInvestorLookupClose()}>
                            <svg style={{ position: "relative", top: "4px" }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                <path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
                            </svg>
                        </button> */}
                        <div className="df fdc gg1rem">
                            {stepNo == 1 ? (
                                <>
                                    <h1 className="c15 fs1-5rem fw400 mb2rem">Investor Lookup</h1>
                                    <div className="pr">
                                        <div className="pr">
                                            <input
                                                type='text'
                                                name='orgName'
                                                id='searchCompany'
                                                onKeyUp={searchCompanyTimeout}
                                                placeholder='Enter your fund/firm'
                                                value={formData?.['orgName'] || ''}
                                                className='bg1 w100 br10px p1rem bss bs1px bo28'
                                                onChange={(e) => { setFormData({ ...formData, orgName: e['target']['value'], ukRegNo: '' }), setIsSearchable(true) }}
                                            />
                                        </div>
                                        {(formData?.['orgName'] != '' && isSearchable && companyOptions.length > 0) ? (
                                            <div className='dropdown-box pa b0 w100'>
                                                {companyOptions.map((option, idx) => (
                                                    <div
                                                        key={idx}
                                                        onClick={() => handleSelectCompany(option)}
                                                        className={`cp df fww jcfs aic shadow br5px p0-5rem bg1`}
                                                    // className={`cp df fww jcfs aic shadow br5px p0-5rem ${option['isInvestorExists'] ? 'bg7' : 'bg1'}`}
                                                    >
                                                        <span className='fs0-75rem fw600 mr1rem'>{option['title']}</span>
                                                        <span className='fs0-75rem mr1rem'>{option['company_number']}</span>
                                                        <span className='fs0-75rem ttu fw600'>{option['company_status']}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="pr">
                                        <input
                                            id='name'
                                            type='text'
                                            placeholder='Enter your name'
                                            value={formData?.['name'] || ''}
                                            className='bg1 w100 br10px p1rem bss bs1px bo28'
                                            onChange={(e) => setFormData({ ...formData, name: e['target']['value'] })}
                                        />
                                    </div>
                                    <div className="pt0-5rem pb0-5rem pr">
                                        <input
                                            id='email'
                                            type='email'
                                            onBlur={() => handleEmailBlur()}
                                            value={formData?.['email'] || ''}
                                            placeholder='Enter your company email address'
                                            className='bg1 w100 br10px p1rem bss bs1px bo28 pl3rem'
                                            onChange={(e) => { setFormData({ ...formData, email: e['target']['value'] }), setIsValidEmail(false) }}
                                        />
                                        <svg style={{ top: '24px', left: '20px' }} className="pa emailIcon" width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.1875 0.375H1.8125C1.36495 0.375 0.935725 0.55279 0.619257 0.869257C0.30279 1.18572 0.125 1.61495 0.125 2.0625V9.9375C0.125 10.3851 0.30279 10.8143 0.619257 11.1307C0.935725 11.4472 1.36495 11.625 1.8125 11.625H14.1875C14.6351 11.625 15.0643 11.4472 15.3807 11.1307C15.6972 10.8143 15.875 10.3851 15.875 9.9375V2.0625C15.875 1.61495 15.6972 1.18572 15.3807 0.869257C15.0643 0.55279 14.6351 0.375 14.1875 0.375ZM13.9456 1.5L8.24187 6.045C8.07875 6.19688 7.92125 6.19688 7.72438 6.045L2.05437 1.5H13.9456ZM14.1875 10.5H1.8125C1.66332 10.5 1.52024 10.4407 1.41475 10.3352C1.30926 10.2298 1.25 10.0867 1.25 9.9375V2.29875L6.99312 6.87187C7.26606 7.13682 7.63087 7.28597 8.01125 7.28813C8.36973 7.28661 8.71398 7.1477 8.97312 6.9L14.75 2.29875V9.9375C14.75 10.0867 14.6907 10.2298 14.5852 10.3352C14.4798 10.4407 14.3367 10.5 14.1875 10.5Z" fill="black" />
                                        </svg>
                                    </div>
                                    <label className="pt1rem pb1rem df cp" for="confirmation" >
                                        <span className="checkbox w2rem h2rem pr">
                                            <input
                                                type='checkbox'
                                                id='confirmation'
                                                checked={isConfirmed}
                                                onChange={() => setIsConfirmed(!isConfirmed)}
                                                className='bo5 bw2px bss cp td250 w2rem h2rem br10px lpins'
                                            />
                                        </span>
                                        <span className="w100 fs1rem lh3 ml1rem usn fw400 c15" style={{ lineHeight: '28px' }}>
                                            I confirm that I am authorized by the organisation above to conduct this search.
                                        </span>
                                    </label>
                                </>
                            ) : stepNo == 2 ? (
                                <>
                                    <h1 className="c15 fs1-5rem fw600 mb2rem">Search Results - Investor Lookup</h1>
                                    <div className='df fdc'>
                                        <div className='df w100'>
                                            <input
                                                type='text'
                                                id='orgResult'
                                                disabled={true}
                                                value={formData?.['orgName'] || ''}
                                                className='pr1rem pl1rem bg1 lpins br10px h40px tal custom-input w90'
                                            />
                                            {executable?.['firmFound'] == true ?
                                                <svg width="21" height="14" style={{ width: '1.3rem' }} className='ml1rem mt0-5rem' viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                                    <path d="M8.24482 13.3358C8.08872 13.339 7.93353 13.3112 7.78826 13.2541C7.64299 13.1969 7.51053 13.1116 7.39855 13.0029L1.2696 6.8569C1.05196 6.63957 0.929687 6.3448 0.929688 6.03744C0.929687 5.73008 1.05196 5.43531 1.2696 5.21797C1.48724 5.00064 1.78242 4.87853 2.09021 4.87853C2.398 4.87853 2.69318 5.00064 2.91082 5.21797L8.24482 10.5189L18.2204 0.531648C18.4476 0.322891 18.7451 0.207031 19.0538 0.207031C19.3626 0.207031 19.6601 0.322891 19.8873 0.531648C20.1081 0.752507 20.2321 1.05183 20.2321 1.36391C20.2321 1.67599 20.1081 1.97533 19.8873 2.19619L9.06543 13.0029C8.95734 13.1096 8.8292 13.194 8.68837 13.2511C8.54755 13.3082 8.39682 13.337 8.24482 13.3358Z" fill="#42D342" />
                                                </svg>
                                                :
                                                <svg className='w1rem ml1rem' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                                    <path className="pen fa-primary" fill='#fd6474' d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
                                                </svg>
                                            }
                                        </div>
                                        <div className='df w100 mt0-5rem'>
                                            <input
                                                type='text'
                                                id='userResult'
                                                disabled={true}
                                                value={formData?.['email'] || ''}
                                                className='pr1rem pl1rem bg1 lpins br10px h40px tal custom-input w90'
                                            />
                                            {executable?.['userFound'] == true
                                                ? <svg width="21" height="14" style={{ width: '1.3rem' }} className='ml1rem mt0-5rem' viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                                    <path d="M8.24482 13.3358C8.08872 13.339 7.93353 13.3112 7.78826 13.2541C7.64299 13.1969 7.51053 13.1116 7.39855 13.0029L1.2696 6.8569C1.05196 6.63957 0.929687 6.3448 0.929688 6.03744C0.929687 5.73008 1.05196 5.43531 1.2696 5.21797C1.48724 5.00064 1.78242 4.87853 2.09021 4.87853C2.398 4.87853 2.69318 5.00064 2.91082 5.21797L8.24482 10.5189L18.2204 0.531648C18.4476 0.322891 18.7451 0.207031 19.0538 0.207031C19.3626 0.207031 19.6601 0.322891 19.8873 0.531648C20.1081 0.752507 20.2321 1.05183 20.2321 1.36391C20.2321 1.67599 20.1081 1.97533 19.8873 2.19619L9.06543 13.0029C8.95734 13.1096 8.8292 13.194 8.68837 13.2511C8.54755 13.3082 8.39682 13.337 8.24482 13.3358Z" fill="#42D342" />
                                                </svg>
                                                : <svg className='w1rem ml1rem' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                                    <path className="pen fa-primary" fill='#fd6474' d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
                                                </svg>
                                            }
                                        </div>
                                    </div>
                                    <p>Further information only made available to authorized and verified users logged into platform</p>
                                    {executable?.['action'] == 'matched' && <button className='bg1 c3 tdu cp my1rem' onClick={() => setStepNo(3)}>Investor Report</button>}
                                </>
                            ) : stepNo == 3 ? (
                                <>
                                    {executable?.['action'] == 'matched' ? (
                                        <>{aboutTheInvestorContent()}</>
                                    ) : ['login', 'emailOwner'].includes(executable?.['action']) ? (
                                        <div>
                                            <h2 className='tac mb1-5rem'>Access Request Sent</h2>
                                            <div>
                                                <p>Hi {formData?.['name']},</p>
                                                {executable?.['userFound'] == true ? <p> We have your email registered on our platform, but it is NOT associated to this investor account.</p> : null}
                                                <p>We have emailed the account owner <b>{maskEmail(executable?.['orgDetails']?.['contactEmail'])}</b> inviting them to add you to their account. Once they grant you access, you will receive a confirmation email to <b>{formData?.['email']}</b> granting you access.</p><br />
                                                {/* {executable?.['userFound'] == true ?
                                                    <>
                                                        <p>In the meantime you can <Link className='bg1 c3 tdu cp my1rem' to='/login'> login </Link> into your account</p>
                                                    </>
                                                    : null} */}
                                            </div>
                                        </div>
                                    ) : null}
                                </>
                            ) : null}
                            <div className={`df jcsb aic w100 ${stepNo == 1 && 'search-center-btn'}`}>
                                {(stepNo == 1 || (stepNo == 3 && ['login', 'emailOwner'].includes(executable?.['action']))) ? null : (
                                    <span className={`df aic td250 br5px p1rem pr2rem pl2rem bsbb tac c3-hover fs0-75rem cp jcc mt1rem ttu bo3 bss br35px investor-back-btn`} onClick={() => handleBack()}>
                                        Back
                                    </span>
                                )}
                                {stepNo == 3 ? null : (
                                    <span style={{ cursor: `${(stepNo == 1 && (!isValidEmail || !isConfirmed)) ? 'not-allowed' : 'pointer'}` }} onClick={() => handleAction()}
                                        className={`df aic td250 p1rem pl4rem pr4rem bsbb tac br35px jcc bg4 bg3-hover c1 fw600 ttu fs1rem mr1rem investor-search-btn ${(stepNo == 1 && (!isValidEmail || !isConfirmed)) && 'button-disabled'} ${!(stepNo == 2 && ['login', 'matched', 'emailOwner'].includes(executable?.['action'])) ? '' : ''}`}>
                                        {stepNo == 1 ? 'Search'
                                            : stepNo == 2 ? (
                                                executable?.['action'] == 'register' ? 'Register for FREE'
                                                    : (executable?.['action'] == 'matched' || executable?.['action'] == 'login') ? (executable?.['isVerified'] ? 'Investor Login' : 'Set Password')
                                                        : executable?.['action'] == 'emailOwner' ? 'Request Firm Access' : null
                                            ) : null
                                        }
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    <svg className="pa cp" onClick={() => handleInvestorLookupClose()} style={{ left: '50%', transform: 'translateX(-50%)', bottom: '100px' }} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.8" x="4" y="4" width="52" height="52" rx="26" fill="#151313" />
                        <path d="M48.5606 11.4375C44.8894 7.76665 40.2121 5.26685 35.1201 4.2542C30.0282 3.24156 24.7503 3.76155 19.9539 5.74843C15.1575 7.73531 11.058 11.0998 8.17374 15.4166C5.28947 19.7333 3.75 24.8084 3.75 30C3.75 35.1917 5.28947 40.2667 8.17374 44.5835C11.058 48.9002 15.1575 52.2647 19.9539 54.2516C24.7503 56.2385 30.0282 56.7585 35.1201 55.7458C40.2121 54.7332 44.8894 52.2334 48.5606 48.5625C50.9984 46.1249 52.9322 43.231 54.2516 40.0461C55.5709 36.8611 56.25 33.4474 56.25 30C56.25 26.5526 55.5709 23.1389 54.2516 19.954C52.9322 16.769 50.9984 13.8751 48.5606 11.4375ZM39.3731 36.6375C39.5604 36.8129 39.7098 37.0249 39.8119 37.2603C39.914 37.4958 39.9666 37.7496 39.9666 38.0063C39.9666 38.2629 39.914 38.5168 39.8119 38.7522C39.7098 38.9876 39.5604 39.1996 39.3731 39.375C39.1979 39.5488 38.9901 39.6863 38.7617 39.7796C38.5332 39.8729 38.2886 39.9202 38.0419 39.9188C37.55 39.9167 37.0786 39.7214 36.7294 39.375L29.9981 32.6438L23.3606 39.375C23.0114 39.7214 22.54 39.9167 22.0481 39.9188C21.8013 39.9202 21.5567 39.8729 21.3283 39.7796C21.0998 39.6863 20.8921 39.5488 20.7169 39.375C20.3676 39.0237 20.1716 38.5485 20.1716 38.0531C20.1716 37.5578 20.3676 37.0826 20.7169 36.7313L27.3544 30L20.7169 23.3625C20.4097 23.0038 20.2492 22.5424 20.2674 22.0705C20.2856 21.5986 20.4812 21.151 20.8152 20.8171C21.1491 20.4832 21.5967 20.2875 22.0686 20.2693C22.5405 20.2511 23.0019 20.4116 23.3606 20.7188L29.9981 27.3563L36.6356 20.7188C36.8092 20.539 37.0165 20.3952 37.2457 20.2956C37.4748 20.196 37.7214 20.1424 37.9712 20.1381C38.2211 20.1337 38.4693 20.1786 38.7018 20.2702C38.9343 20.3618 39.1465 20.4983 39.3262 20.6719C39.506 20.8455 39.6498 21.0528 39.7494 21.2819C39.849 21.5111 39.9026 21.7576 39.9069 22.0075C39.9113 22.2573 39.8664 22.5056 39.7748 22.7381C39.6832 22.9706 39.5467 23.1828 39.3731 23.3625L32.6419 30L39.3731 36.6375Z" fill="white" />
                    </svg>
                </div>
            </Modal >

            {/* Investor Bio */}
            <Modal open={isInvestorBio} onClose={() => setIsInvestorBio(false)} className="backdrop td500 zi4 pf t0 l0 w100vw h110vh df jcc aic" >
                <div className="mah100 oya bg1 shadow w100 px3rem py1rem bsbb df fdc m1rem maw600px br5px pr">
                    <button data-scroll-to='About the Investor' className="close-popup" onClick={() => setIsInvestorBio(false)}>
                        <svg style={{ position: 'relative', top: '4px' }} className="pen fds h1rem f1 mr0-5rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                            <path className="pen fa-primary" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
                        </svg>
                    </button>
                    <div className="pr modal-bio investor-leads-tooltip">
                        <h2 className="tac c6 lh2 fs1-75rem mb2rem">Investor Bio</h2>
                        <div className="user-area blur-disabled">
                            <div className="user-img">
                                <img className="h4rem w4rem br100" src={executable?.['organisation']?.['company']?.['creatorId']?.['profile'] || executable?.['organisation']?.['company']?.['creatorId']?.['profilePicture'] || require(`../../../../images/profile-photo.jpeg`)} alt="image" />
                            </div>
                            <div className="text-box">
                                <h4 className="fs0-75rem df">
                                    <span>{executable?.['organisation']?.['company']?.['creatorId']?.['firstName'] ? executable?.['organisation']?.['company']?.['creatorId']?.['firstName'] + ' ' + executable?.['organisation']?.['company']?.['creatorId']?.['lastName'] : 'Name'}</span>
                                    <button className="icon ml0-5rem bg1 cp" style={{ width: '22px' }}>
                                        <svg version="1.1" className="f3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 310">
                                            <g>
                                                <path d="M72.16,99.73H9.927c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5H72.16c2.762,0,5-2.238,5-5V104.73
                                        C77.16,101.969,74.922,99.73,72.16,99.73z"/>
                                                <path d="M41.066,0.341C18.422,0.341,0,18.743,0,41.362C0,63.991,18.422,82.4,41.066,82.4
                                        c22.626,0,41.033-18.41,41.033-41.038C82.1,18.743,63.692,0.341,41.066,0.341z"/>
                                                <path d="M230.454,94.761c-24.995,0-43.472,10.745-54.679,22.954V104.73c0-2.761-2.238-5-5-5h-59.599
                                        c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5h62.097c2.762,0,5-2.238,5-5v-98.918c0-33.333,9.054-46.319,32.29-46.319
                                        c25.306,0,27.317,20.818,27.317,48.034v97.204c0,2.762,2.238,5,5,5H305c2.762,0,5-2.238,5-5V194.995
                                        C310,145.43,300.549,94.761,230.454,94.761z"/>
                                            </g>
                                        </svg>
                                    </button>
                                </h4>
                                <p className='fs0-75rem df'>{executable?.['organisation']?.['company']?.['creatorId']?.['email']
                                    ? <>{executable?.['organisation']?.['company']?.['creatorId']?.['email']} <button class="cp bg1"><i class="fa fa-clipboard c4" style={{ fontSize: '12px' }}></i></button></>
                                    : '-'}</p>
                                <p className='fs0-75rem df'>{executable?.['organisation']?.['company']?.['creatorId']?.['phone']
                                    ? <>{executable?.['organisation']?.['company']?.['creatorId']?.['phone']} <button class="cp bg1"><i class="fa fa-clipboard c4" style={{ fontSize: '12px' }}></i></button></>
                                    : '-'}</p>
                            </div>
                        </div>
                        <div className="description-box mt1rem pr">
                            <h4 className="fs1rem mb1rem">Bio</h4>
                            <p className="fs0-75rem blur-disabled">{executable?.['organisation']?.['company']?.['creatorId']?.['bio'] ? executable?.['organisation']?.['company']?.['creatorId']?.['bio'] : '-'}</p>
                        </div>
                        <div className="description-box mt1rem pr">
                            <h4 className="fs1rem mb1rem">About</h4>
                            <p className="fs0-75rem blur-disabled">{executable?.['organisation']?.['company']?.['creatorId']?.['linkedinAbout'] ? executable?.['organisation']?.['company']?.['creatorId']?.['linkedinAbout'] : '-'}</p>
                        </div>
                    </div>
                </div>
            </Modal>

        </>
    );

}

export default investorLookup;
import { Info } from '@mui/icons-material'
import React from 'react'

const Toggler = () => {

    const [toggle, setToggle] = React.useState("uk")

    return (
        <>
            <div
                className="br50px df aic jcsb "
                style={{
                    padding: "4px",
                    width: "350px",
                    backgroundColor: '#D2D2D299',
                    margin: "auto",
                    marginBottom: "2rem"
                }}
            >
                <div
                    className={`br50px fs0-75rem pl0-5rem pr0-5rem pt0-5rem pb0-5rem w50 tac cp ${toggle === "uk" && "bg1 shadow"}`}
                    style={{ color: "#151313B2" }}
                    onClick={() => setToggle("uk")}
                >
                    UK Company
                </div>
                <div
                    className={`br50px fs0-75rem pl0-5rem pr0-5rem pt0-5rem pb0-5rem w50 tac cnd df aic ${toggle === "non" && "bg1 shadow"}`}
                    style={{ color: "#1513134C" }}
                    disabled={true}
                // onClick={() => setToggle("non")}
                >
                    Non-UK Company <Info className='infoIcon fs1rem ml0-5rem' />
                </div>
            </div>
        </>
    )
}

export default Toggler
import moment from "moment";
import Modal from '@mui/material/Modal';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { toggleModal } from "../../reducers/modalReducer";
import { getRequest, postRequest, showMessage } from '../../config';


export default function ChecklistActivation({ checklistObj, setChecklistObj, handleCreateChecklist }) {

    const dispatch = useDispatch();
    const modal = useSelector(state => state.modal);
    const user = useSelector(state => state.user);

    let [companyId, setCompanyId] = useState();
    let [deleteModuleName, setDeleteModuleName] = useState('');
    let [isActive, setIsActive] = useState(checklistObj['isActive']);


    useEffect(() => {
        if (location?.search) companyId = (location.search.split('=')[1]).split(';')[0];
        if (companyId) setCompanyId(companyId);
    }, []);

    useEffect(() => {
        if (checklistObj['isActive'] != isActive) handleCreateChecklist();
    }, [checklistObj]);

    const handleActivationChecklist = async () => {
        await setChecklistObj({ ...checklistObj, isActive: !isActive });
        await setIsActive(!isActive);
    };

    const deleteModule = async (moduleName) => {
        let res = await postRequest(`/repository/deleteModule`, { moduleName });
        if (res?.['code'] == 200) {
            showMessage(res['message']);
            if (companyId) {
                if ((user['role'] == 'Advisor' || user['role'] == 'Investor') && companyId == user['companyId']['_id']) {
                    localStorage.setItem('isAnylistBuilder', true)
                    window.location.href = `/advisor-dashboard`;
                }
                else window.location.href = `/company/${companyId}`;
            }
        }
    };

    const handleGoToChecklist = () => {
        if (companyId) {
            if ((user['role'] == 'Advisor' || user['role'] == 'Investor') && companyId == user['companyId']['_id']) {
                localStorage.setItem('isAnylistBuilder', true)
                window.location.href = `/advisor-dashboard`;
            }
            else {
                localStorage.setItem('isAnylistTab', true);
                window.location.href = `/company/${companyId}`;
            }
        }
    };

    return (
        <>
            <div className='checklist-form-area df fww w100 mb5rem'>
                <div className='checklist-form w50'>
                    <div className="mt2rem w100 keyfeature-box keyfeature-box-core df fww keyfeature-box-two keyfeature-box-anylist pr bsbb" style={{ border: 'none', backgroundColor: 'transparent' }}>
                        {isActive
                            ? <div className="w100 df aic mb1rem"><img className="db mr1rem" src={require(`../../../images/checklist-active.png`)} alt="" />Activated!</div>
                            : <h3 className="fs1-25rem c15 w100 fw400 mb1rem">Activate your checklist to add to your dashboard</h3>
                        }
                        <div className="cp w50 keyfeature-wihte-item bg1 br15px p2rem zi9 pr bsbb" style={{ width: '51%', border: '1px solid #B5BFDE' }}>
                            <div className='df fw500 fs1-25rem c15 aic'>
                                <div className='df aic pr w100'>
                                    <i className='icon-diligenstia mr1rem pr'>
                                        {checklistObj?.['imageURL'] && <img className='br10px w50px h50px' src={checklistObj?.['imageURL']} alt='logo image' />}
                                        <span className={`activedeactive-area ${isActive ? ' bg34' : ' bg16'}`}></span>
                                    </i>
                                    <p className='fs1rem fw500 c15 lh2 title-box-feature w70'>
                                        <span className='db ttc' style={{ color: '#15131350', fontSize: '9px' }}>{checklistObj?.['genType'] == 'automatic' ? 'AI-generated' : 'Manually-generated'}</span>
                                        {checklistObj?.['moduleName']}
                                    </p>
                                    <div className='remove-edit-araa'>
                                        {checklistObj?.['createdBy']?.['_id'] == user?.['_id'] &&
                                            <button onClick={() => { setDeleteModuleName(checklistObj?.['moduleName']); dispatch(toggleModal({ open: 'confirmDeleteModule' })) }}
                                                className='edit-remove bg1'><i className="fa fa-trash pl1rem c4 cp" style={{ fontSize: '20px' }} /></button>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='df mt2rem jcsb bottom-details w100 aic pr' style={{ top: '17px' }}>
                                <div className='df mb1rem w70'>
                                    <p className='fs1rem fw400 c15 lh2'>
                                        Creator: {checklistObj?.['createdBy']?.['firstName']}
                                        <br />{moment(checklistObj['createdAt']).format('DD/MMM/YY')}
                                    </p>
                                </div>
                                <button style={{ top: '-4px', right: '11px' }} onClick={() => handleActivationChecklist()} className={`pr df bg1 br50px mr0-5rem bw1px bss fs0-75rem px1rem py0-5rem bsbb aic active-btn cp ${isActive ? ' c12' : ' c7'}`}>
                                    {isActive ? 'Deactivate' : 'Activate'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='selection-area-right w50 mt1rem pl5rem bsbb'>
                    <div className='manual-ai-area w100 pr fww jcfe'>
                        {isActive
                            ? <div className='tip-box p2rem bg20 w50' style={{ borderRadius: '40px' }}>
                                <h5 className='c15 fs0-75rem df aic mb1rem' style={{ fontSize: '14px' }}>
                                    <i className='icon mr1rem'><img className="db w2rem" src={require(`../../../images/award1.svg`)} alt="" /></i>
                                    Pawsitively Perfect!
                                </h5>
                                <p className='c15 fs0-75rem' style={{ fontSize: '12px', color: '#15131380' }}>Time to give yourself a tail-wagging treat!</p>
                                <img className="db w100" src={require(`../../../images/dog-paws.gif`)} alt="" />
                            </div>
                            : <div className='tip-box w100 p2rem bg20 w100' style={{ borderRadius: '40px' }}>
                                <h5 className='c15 fs0-75rem df aic mb1rem' style={{ fontSize: '14px' }}>
                                    <i className='icon mr1rem'><img className="db w2rem" src={require(`../../../images/tip-icon.svg`)} alt="" /></i>
                                    Top Tip
                                </h5>
                                <p className='c15 fs0-75rem' style={{ fontSize: '12px', color: '#15131380' }}>
                                    Activation will make your checklist visible and accessible by users on their AnyList Checklist Builder tab on their dashboard(s)<br /><br />
                                    You can now <span className='active-btn pr df bg1 br50px mr0-5rem bw1px bss fs0-75rem px1rem py0-5rem bsbb aic active-btn cp c7' style={{ display: 'inline-block', padding: '5px 11px', fontSize: '9px', marginRight: '-1px' }}>Activate</span> your new checklist immediately or you can return here to activate it later from your dashboard.<br /><br />
                                    Don’t worry! You can always return here to <span className='pr df bg1 br50px mr0-5rem bw1px bss fs0-75rem px1rem py0-5rem bsbb aic active-btn cp c12' style={{ display: 'inline-block', padding: '5px 11px', fontSize: '9px', marginRight: '-1px' }}>Deactivate</span> and make further edits from your AnyList Checklist Builder tab on your dashboard.
                                </p>
                            </div>
                        }
                    </div>

                </div>
            </div >
            <div className='bottom-btn-area df aic w100 bsbb'>
                <buttom onClick={() => handleGoToChecklist()}
                    className={`shadow c1 cp fw400 fs1rem pr br38px w200px h3rem df aic jcc bg4 bg3-hover`}>
                    Go to checklist
                    <i className='icon pr ml0-5rem' style={{ top: '3px' }}>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.42954 17.0154H21.1966L16.7035 22.1842C15.413 23.6688 17.3488 25.8957 18.6394 24.4111L22.7314 19.6964L25.463 16.5491C25.9924 15.9365 25.9924 14.9471 25.463 14.3344L18.6394 6.47557C18.3798 6.16869 18.0225 5.99649 17.6502 6.00005C16.4212 6.00023 15.818 7.72225 16.7035 8.70263L21.2073 13.8714H6.35888C4.46611 13.9794 4.60744 17.1238 6.42954 17.0154Z" fill="white" />
                        </svg>
                    </i>
                </buttom>
                <p class="fs0-75rem ml1rem" style={{ color: '#15131350' }}>Before (Go to checklist), you need to activate it!</p>
            </div>

            {/* Delete Checklist Popup */}
            <Modal id='confirmDeleteModule' open={modal['open'] === 'confirmDeleteModule'} onClose={() => dispatch(toggleModal({ open: '' }))} className="backdrop td500 zi4 pf t0 l0 w100vw  df jcc aic advisor-dashboard-seemore">
                <div className="poppins-font-style mah100 bg1 shadow w100 py2rem px4rem df fdc m1rem pr br10px pr" style={{ outline: 'none', paddingBottom: '2rem', maxWidth: '750px' }}>
                    <div className='top-logo pa t0 r0 mr1rem mt1rem'><img className='w4rem' src={require('../../../images/favicon.png')} alt="" /></div>
                    <div className='content-area creaing-modal pr my3rem'>
                        <h1 className='text-gradient fs2rem tac lh1 fw600 mb1rem'>CONFIRMATION</h1>
                        <p className='c15 fs1rem tac mb2rem'>Are you sure you want to delete this module?</p>
                        <div className='df jcsa gg1rem'>
                            <span onClick={() => dispatch(toggleModal({ open: '' }))} className='new-shadow cp c14 bg1 fw400 fs1rem pr br38px bw1px bss bsbb w200px h3rem df aic jcc'>Cancel</span>
                            <span onClick={() => { deleteModule(deleteModuleName); dispatch(toggleModal({ open: '' })) }} className='shadow c1 bg4 cp fw400 fs1rem pr br38px px0-5rem w200px h3rem df aic jcc'>Confirm</span>
                        </div>
                    </div>
                    <svg className="pa cp" onClick={() => dispatch(toggleModal({ open: '' }))} style={{ left: '50%', transform: 'translateX(-50%)', bottom: '-80px' }} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.8" x="4" y="4" width="52" height="52" rx="26" fill="#151313" />
                        <path d="M48.5626 11.4375C44.8913 7.76665 40.214 5.26685 35.1221 4.2542C30.0302 3.24156 24.7523 3.76155 19.9559 5.74843C15.1595 7.73531 11.06 11.0998 8.17569 15.4166C5.29142 19.7333 3.75195 24.8084 3.75195 30C3.75195 35.1917 5.29142 40.2667 8.17569 44.5835C11.06 48.9002 15.1595 52.2647 19.9559 54.2516C24.7523 56.2385 30.0302 56.7585 35.1221 55.7458C40.214 54.7332 44.8913 52.2334 48.5626 48.5625C51.0004 46.1249 52.9342 43.231 54.2535 40.0461C55.5729 36.8611 56.252 33.4474 56.252 30C56.252 26.5526 55.5729 23.1389 54.2535 19.954C52.9342 16.769 51.0004 13.8751 48.5626 11.4375ZM39.3751 36.6375C39.5624 36.8129 39.7117 37.0249 39.8138 37.2603C39.9159 37.4958 39.9686 37.7496 39.9686 38.0063C39.9686 38.2629 39.9159 38.5168 39.8138 38.7522C39.7117 38.9876 39.5624 39.1996 39.3751 39.375C39.1999 39.5488 38.9921 39.6863 38.7636 39.7796C38.5352 39.8729 38.2906 39.9202 38.0438 39.9188C37.5519 39.9167 37.0805 39.7214 36.7313 39.375L30.0001 32.6438L23.3626 39.375C23.0133 39.7214 22.5419 39.9167 22.0501 39.9188C21.8033 39.9202 21.5587 39.8729 21.3302 39.7796C21.1018 39.6863 20.894 39.5488 20.7188 39.375C20.3696 39.0237 20.1736 38.5485 20.1736 38.0531C20.1736 37.5578 20.3696 37.0826 20.7188 36.7313L27.3563 30L20.7188 23.3625C20.4116 23.0038 20.2511 22.5424 20.2694 22.0705C20.2876 21.5986 20.4832 21.151 20.8171 20.8171C21.1511 20.4832 21.5987 20.2875 22.0706 20.2693C22.5425 20.2511 23.0039 20.4116 23.3626 20.7188L30.0001 27.3563L36.6376 20.7188C36.8111 20.539 37.0184 20.3952 37.2476 20.2956C37.4768 20.196 37.7233 20.1424 37.9732 20.1381C38.223 20.1337 38.4713 20.1786 38.7038 20.2702C38.9363 20.3618 39.1484 20.4983 39.3282 20.6719C39.5079 20.8455 39.6517 21.0528 39.7514 21.2819C39.851 21.5111 39.9045 21.7576 39.9089 22.0075C39.9132 22.2573 39.8683 22.5056 39.7767 22.7381C39.6851 22.9706 39.5486 23.1828 39.3751 23.3625L32.6438 30L39.3751 36.6375Z" fill="white" />
                    </svg>
                </div>
            </Modal>
        </>
    )
}
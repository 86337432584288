import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import ModalWrapper from '../../common/Modal/ModalWrapper';
import Button from '../../common/Button';
import { toggleModal } from '../../../reducers/modalReducer';
import { useDispatch } from 'react-redux';
import Heading from '../../common/Heading';
import Select from 'react-select';
import { addCompanyCustomStyles } from '../../../utils';

const options = [
    { value: 'Subsidiary', label: 'Subsidiary' },
    { value: 'Associate', label: 'Associate' },
];
const CreateGroupModal = ({
    modalType,
    handleCreateGroup,
    companiesGroup,
    companies,
    parentCompany,
    setParentCompany,
    handleChildCompany,
    user,
    profileClass,
}) => {

    const [selectedCompany, setSelectedCompany] = React.useState([]);

    const dispatch = useDispatch();
    const onCloseHandler = () => {
        dispatch(toggleModal({ open: "" }))
    };

    return (
        <ModalWrapper modalType={modalType} onCloseHandler={onCloseHandler}>
            <div className=''>
                <Heading text="Add a Group" />
                <div className="mra mla fs1rem w100">
                    <div className="mla mra">
                        <div className="df fdc w100">
                            <div className="pr mt1rem">
                                <Dropdown className="dropdown-add-group">
                                    <Dropdown.Toggle
                                        variant="success"
                                        id="dropdown-basic"
                                        className='dropdown-btn cp pl4rem w95 br5px p1rem pr bg1 bs1px brss bo42'
                                    >
                                        <p className={`c15 op70 fw500 fsi`}>
                                            {
                                                parentCompany?.['cName'] ? <>Parent: <b className='fw600 fsi'>{`${parentCompany?.['cName']}`}</b></> : "Please select Parent company"
                                            }
                                        </p>
                                        <span className="icon-right pa r0 mr1rem">
                                            <i className="icon">
                                                <svg className="ml0-5rem w1rem h1rem f6 f6-xl" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                    <path className="fa-secondary" d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z" />
                                                </svg>
                                            </i>
                                        </span>
                                        <svg className="center-vertically f3 pa l0 ml1rem w2rem" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.5 14.5V16.5921C4.91925 16.7992 4.5 17.349 4.5 18C4.5 18.8271 5.17285 19.5 6 19.5C6.82715 19.5 7.5 18.8271 7.5 18C7.5 17.349 7.08075 16.7992 6.5 16.5921V15H7.5V14H6C5.72363 14 5.5 14.2236 5.5 14.5ZM6 18.5C5.72412 18.5 5.5 18.2759 5.5 18C5.5 17.7241 5.72412 17.5 6 17.5C6.27588 17.5 6.5 17.7241 6.5 18C6.5 18.2759 6.27588 18.5 6 18.5Z" fill="#151313" />
                                            <path d="M5.5 10V7.90787C4.91925 7.70081 4.5 7.15097 4.5 6.5C4.5 5.67285 5.17285 5 6 5C6.82715 5 7.5 5.67285 7.5 6.5C7.5 7.15097 7.08075 7.70081 6.5 7.90787V9.5H9V10.5L6 10.5C5.72363 10.5 5.5 10.2764 5.5 10ZM6 6C5.72412 6 5.5 6.22412 5.5 6.5C5.5 6.77588 5.72412 7 6 7C6.27588 7 6.5 6.77588 6.5 6.5C6.5 6.22412 6.27588 6 6 6Z" fill="#151313" />
                                            <path d="M18.5 14.5V16.5921C19.0807 16.7992 19.5 17.349 19.5 18C19.5 18.8271 18.8271 19.5 18 19.5C17.1729 19.5 16.5 18.8271 16.5 18C16.5 17.349 16.9193 16.7992 17.5 16.5921V15H16.5V14H18C18.2764 14 18.5 14.2236 18.5 14.5ZM18 18.5C18.2759 18.5 18.5 18.2759 18.5 18C18.5 17.7241 18.2759 17.5 18 17.5C17.7241 17.5 17.5 17.7241 17.5 18C17.5 18.2759 17.7241 18.5 18 18.5Z" fill="#151313" />
                                            <path d="M18.5 10V7.90787C19.0807 7.70081 19.5 7.15097 19.5 6.5C19.5 5.67285 18.8271 5 18 5C17.1729 5 16.5 5.67285 16.5 6.5C16.5 7.15097 16.9193 7.70081 17.5 7.90787V9.5H15V10.5L18 10.5C18.2764 10.5 18.5 10.2764 18.5 10ZM18 6C18.2759 6 18.5 6.22412 18.5 6.5C18.5 6.77588 18.2759 7 18 7C17.7241 7 17.5 6.77588 17.5 6.5C17.5 6.22412 17.7241 6 18 6Z" fill="#151313" />
                                            <g clip-path="url(#clip0_5984_43387)">
                                                <path d="M15.5156 10.125H15.125V8.17188C15.125 7.5257 14.5993 7 13.9531 7H10.0469C9.4007 7 8.875 7.5257 8.875 8.17188V10.125H8.48438C7.8382 10.125 7.3125 10.6507 7.3125 11.2969V17H16.6875V11.2969C16.6875 10.6507 16.1618 10.125 15.5156 10.125ZM8.875 16.2188H8.09375V11.2969C8.09375 11.0815 8.26898 10.9062 8.48438 10.9062H8.875V16.2188ZM12.7812 16.2188H11.2188V15.5938H12.7812V16.2188ZM14.3438 16.2188H13.5625V14.8125H10.4375V16.2188H9.65625V8.17188C9.65625 7.95648 9.83148 7.78125 10.0469 7.78125H13.9531C14.1685 7.78125 14.3438 7.95648 14.3438 8.17188V16.2188ZM15.9062 16.2188H15.125V10.9062H15.5156C15.731 10.9062 15.9062 11.0815 15.9062 11.2969V16.2188ZM10.4375 9.34375H13.5625V8.5625H10.4375V9.34375ZM10.4375 10.9062H13.5625V10.125H10.4375V10.9062ZM10.4375 12.4688H13.5625V11.6875H10.4375V12.4688ZM10.4375 14.0312H13.5625V13.25H10.4375V14.0312Z" fill="#4A67FF" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_5984_43387">
                                                    <rect width="10" height="10" fill="white" transform="translate(7 7)" />
                                                </clipPath>
                                            </defs>
                                        </svg>

                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-content cp shadow fww br10px btrr0px btlr0px pr pt1rem pb1rem">
                                        {companies && companies.map((company, idx) => {
                                            if (!company?.['childrenCompanies']) {
                                                return (
                                                    <Dropdown.Item
                                                        key={`dropdown-company-selection-group${idx}`}
                                                        onClick={() => setParentCompany(company)}
                                                    >
                                                        <div className="item fs1rem p0rem mb1rem cp c36">
                                                            <div className="deta-dropdown-company df jcsb aic">
                                                                <h4 className='fs1-25rem'>{company['cName']}</h4>
                                                            </div>
                                                        </div>
                                                    </Dropdown.Item>
                                                )
                                            }
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>
                {parentCompany != null &&
                    <div
                        className='bottom-company-area'
                        style={{
                            overflow: 'visible'
                        }}
                    >
                        {companies && companies.map((company, idx) => {
                            if (company['_id'] !== parentCompany['_id'] && company['group'] !== "Parent") {
                                return (
                                    <div key={`${idx}-createGroup`}
                                        style={{ backgroundColor: '#F0F2FD80' }}
                                        className='company-detail-box df aic w95 bg1 bs1px brss bo45 br20px px0-75rem mb1rem'>
                                        <span className="checkbox active w2rem h2rem pr mr0-75rem">
                                            <input onChange={(event) => {
                                                handleChildCompany(
                                                    {
                                                        event,
                                                        companyId: company['_id'],
                                                        group: companiesGroup[company['_id']]?.['group'],
                                                        selected: 'true'
                                                    })
                                                if (event.target.checked) {
                                                    setSelectedCompany((prev) => [...prev, company['_id']])
                                                } else {
                                                    const filtered = selectedCompany.filter((comp) => comp !== company['_id'])
                                                    setSelectedCompany(filtered)
                                                }
                                            }}
                                                id={`childCompnay-${company['_id']}`}
                                                className=" bo5 bw2px bss cp td250 w2rem h2rem br5px"
                                                type="checkbox"
                                            />
                                        </span>
                                        <div className="company-detail df fww br10px fs1rem mb1rem mt1rem w100 aic jcsb">
                                            <div className='df fdc jcfs aifs'>
                                                <h4 className='fs1rem mr1rem w11rem c15'>
                                                    {company['cName']}
                                                </h4>
                                                {company['subscription']
                                                    ? <h2 className="fs11px c45 mra tal w2rem">{company['subscription']?.toUpperCase()}</h2>
                                                    : <h2 className="fs11px c45 mra tal mra w3rem">INACTIVE</h2>
                                                }
                                            </div>
                                            <span className='mr0-5rem df jsc w4rem'>
                                                <div className='df w4rem jcc'>
                                                    <span className='df username-area'>
                                                        <div class="df aic" style={{ flexDirection: "column", alignItems: "end" }}>
                                                            {user['profile']
                                                                ? <img className={`w2rem h2rem df jcc aic br100 mr0-5rem bs4px brss bo34 ${profileClass}`} src={user['profile']} data-tooltip={user.firstName ? (user.firstName + " " + user?.lastName) : "Register to show your name here"} />
                                                                : <span onClick={() => dispatch(toggleModal({ open: 'userDetail' }))} data-tooltip={user.firstName ? (user.firstName + " " + user?.lastName) : "Register to show your name here"} className={`w2rem h2rem df jcc aic br100 mr0-5rem fs0-75rem ${profileClass}`}>{user.firstName ? (user.firstName[0] + user?.lastName?.[0]) : "?"}</span>
                                                            }
                                                            <span class="ass fs0-75rem mt0-5rem c15">{company['role']}</span>
                                                        </div>
                                                    </span>
                                                </div>
                                            </span>
                                            <div className='dropdown-company w8rem px0-5rem py0-5rem custom-dropdown-wrapper'>
                                                <Select
                                                    defaultValue={options.find((opt) => opt.value === companiesGroup[company['_id']]?.['group'])}
                                                    options={options}
                                                    classNamePrefix="custom-dropdown"
                                                    placeholder="Group"
                                                    styles={addCompanyCustomStyles}
                                                    onChange={(selected) => handleChildCompany({ companyId: company['_id'], group: selected.value })}
                                                    id={`group-${company['_id']}`}
                                                />
                                            </div>
                                            <div className='percentage-area ml1rem w5rem'>
                                                <span className='input-area'>
                                                    <input
                                                        defaultValue={companiesGroup[company['_id']]?.['percentage']}
                                                        onChange={(e) => handleChildCompany({ companyId: company['_id'], group: companiesGroup[company['_id']]?.['group'], percentage: e.target.value })}
                                                        placeholder='0%'
                                                        id={`percentage-${company['_id']}`}
                                                        type="text"
                                                        className="fw600 bg1 bs1px brss bo31 br10px py0-5rem px1rem percentage"
                                                        min="1"
                                                        max="100"
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })}
                    </div>
                }

                <div className='mb2rem mt3rem df jcc'>
                    <Button
                        btnType="warning"
                        text={"Create Group"}
                        disabled={!selectedCompany.length}
                        onClick={handleCreateGroup}
                    />
                </div>
            </div>
        </ModalWrapper>
    );
};

export default CreateGroupModal;

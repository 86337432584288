import React, { forwardRef, useEffect, useImperativeHandle, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { EmailOutlined, Person2Outlined, PhoneOutlined, WorkOutline } from '@mui/icons-material';
import InputField from '../Input';
import { postRequest, showMessage } from '../../../config';
import ChangePassword from '../ChangePassword';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from '../../../reducers/modalReducer';
import EnterCode from '../../modals/enterCode';
import { updateUser } from '../../../reducers/userReducer';

// Yup validation schema
const validationSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  email: yup.string().email('Invalid email').required('Email is required')
}).required();


const ProfileForm = forwardRef(({ userData, Button, handleSave }, ref) => {
  // Hooks and vars 
  const dispatch = useDispatch()
  const { smsEnabled, twoFaEnabled, emailEnabled } = useSelector((state) => state.loginType);
  const { open } = useSelector((state) => state.modal);
  const [loading, setLoading] = useState(false);
  const [verify, setVerify] = useState(null);
  const [counter, setCounter] = useState(0); // Countdown for 60s

  const buttonText = useMemo(() => {
    return counter > 0 ? `Resend in ${counter}s` : 'Resend Code';
  }, [counter]);

  const isButtonDisabled = useMemo(() => {
    return counter > 0 || loading;
  }, [counter, loading]);

  useEffect(() => {
    let timer;
    if (counter > 0) {
      timer = setTimeout(() => setCounter(counter - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [counter]);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useImperativeHandle(ref, () => ({
    handleSubmit: handleSubmit(onSubmit),
  }));

  useEffect(() => {
    if (userData) {
      reset(userData);
    }
  }, [userData, open])

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      const { firstName, lastName, email, phone, linkedIn, title } = data
      const res = await postRequest(`/user/updateProfile`, { firstName, lastName, email, phone, linkedIn, title });

      if (!res || res["code"] != 200) return;
      showMessage(res["message"]);
      dispatch(updateUser({ ...userData, firstName, lastName, email, phone, linkedIn, title, updatedAt: new Date() }))
    } catch (e) {
      showMessage('An error occurred while submitting the form');
    } finally {
      setLoading(false)
    }
  };

  const handleVerification = async () => {
    try {
      if (!watch('phone')) {
        return showMessage('Please enter a phone number')
      }
      setLoading(true)
      const response = await postRequest("/user/sendSMS", { phone: watch('phone') });
      if (!response) return;
      setCounter(60);
      setVerify("Phone");
      dispatch(toggleModal({ open: "verify" }));
      showMessage(response["message"]);
    } catch (e) {
      showMessage(e["message"]);
    } finally {
      setLoading(false)
    }
  }

  const verifySMSCode = async (e) => {
    const authKey = e.target.value;
    if (authKey.length < 6) return;
    setLoading(true)
    try {
      const response = await postRequest("/user/enableSMS", { authKey });
      console.log(response, "response")
      if (response.code !== 200) {
        reset(userData);
      }
      showMessage(response["message"]);
      dispatch(toggleModal({ open: "" }));

    } catch (e) {
      console.log(e, "e")
      showMessage(e["message"]);
    } finally {
      setLoading(false)
    }
  };

  console.log("*********userData::", userData);
  

  return (
    <div className="w50 pr3rem lg-w100 lg-px1rem">
      <form >
        <h2 className='c15 fw600 fs1rem mt1rem'>Personal Information</h2>
        <div className='df aic jcsb'>
          <div className="w50 mr0-5rem pr">
            <InputField
              key={`firstName`}
              id="firstName"
              placeholder="First Name"
              type="text"
              {...register('firstName')}
              error={errors.firstName}
              prefixIcon={<Person2Outlined className='pa c2' style={{ left: '15px', marginTop: '0.8rem', color: `${watch('firstName') ? '#000' : '#505050'}` }} />}
            />
          </div>

          <div className="w50">
            <InputField
              id="lastName"
              key={`lastName`}
              placeholder="Last Name"
              type="text"
              {...register('lastName')}
              error={errors.lastName}
            />
          </div>
        </div>

        <div className="w100 pr">
          <InputField
            id="email"
            key={`email`}
            type="email"
            placeholder="Email"
            {...register('email')}
            error={errors.email}
            prefixIcon={<EmailOutlined className='pa c2' style={{ left: '15px', marginTop: '0.8rem', color: `${watch('email') ? '#000' : '#505050'}` }} />}
            afterIcon={<svg className='pa c2' style={{ right: '15px', marginTop: '0.8rem', display: `${userData?.emailVerified ? 'block' : 'none'}` }} width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.2143 0C6.84643 0 0 6.84643 0 15.2143C0 23.5821 6.84643 30.4286 15.2143 30.4286C23.5821 30.4286 30.4286 23.5821 30.4286 15.2143C30.4286 6.84643 23.5821 0 15.2143 0ZM21.7564 11.5629L14.7579 20.6914C14.4536 20.9957 13.9971 21.3 13.5407 21.3C13.0843 21.3 12.6279 21.1479 12.3236 20.6914L8.67214 15.975C8.21571 15.3664 8.21571 14.3014 8.97643 13.845C9.73714 13.3886 10.65 13.3886 11.1064 14.1493L13.5407 17.3443L19.3221 9.73714C19.7786 9.12857 20.8436 8.97643 21.4521 9.43286C22.2129 9.88929 22.2129 10.8021 21.7564 11.5629Z" fill="#02BC7D" />
            </svg>
            }
          />
        </div>

        <div className="w100 pr">
          <InputField
            id="phone"
            key={`phone`}
            placeholder="Phone"
            type="text"
            {...register('phone')}
            error={errors.phone}
            prefixIcon={<PhoneOutlined className='pa c2' style={{ left: '15px', marginTop: '0.8rem', color: `${watch('phone') ? '#000' : '#505050'}` }} />}
            afterIcon={<Button
              btnType='primary'
              onClick={handleVerification}
              text={userData?.smsEnabled ? "Verified" : 'Verify'}
              style={{ right: "0", marginTop: "0.8rem", borderRadius: "8px", height: "calc(100% - 0.9rem)" }}
              customClassName="c1 pa"
            />
            }
          />
        </div>

        <div className="w100 pr">

          <InputField
            id="linkedIn"
            placeholder="LinkedIn"
            type="url"
            {...register('linkedIn')}
            error={errors.linkedIn}
            prefixIcon={<svg style={{ left: '15px', position: 'absolute', marginTop: '0.8rem', zIndex: 9 }} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_5984_38509)">
                <path d="M5 17H1V6H5V17ZM1.66663 16.2904H4.33337V6.70964H1.66663V16.2904Z" fill={`${watch('linkedIn') ? "#000" : "#505050"}`} stroke={`${watch('linkedIn') ? "#000" : "#505050"}`} stroke-width="0.5" />
                <path d="M17 17H13V10.9677C13 10.3799 12.5522 9.90321 12 9.90321C11.4476 9.90321 11 10.3799 11 10.9677V17H7V6H11V6.71406C11.6038 6.24937 12.33 5.99948 13.075 6C15.2374 6 17 7.86734 17 10.1649V17ZM13.6666 16.2904H16.3333V10.1649C16.3287 8.25341 14.8706 6.70717 13.075 6.70964C12.2542 6.70964 11.4833 7.07778 10.9083 7.74311L10.3333 8.41285V6.70964H7.66663V16.2904H10.3333V10.9677C10.3333 9.98781 11.0795 9.19357 12 9.19357C12.9204 9.19357 13.6666 9.98781 13.6666 10.9677V16.2904Z" fill={`${watch('linkedIn') ? "#000" : "#505050"}`} stroke={`${watch('linkedIn') ? "#000" : "#505050"}`} stroke-width="0.5" />
                <path d="M3 5C1.89539 5 1 4.10461 1 3C1 1.89551 1.89539 1 3 1C4.10461 1 5 1.89551 5 3C4.99768 4.10364 4.10364 4.9978 3 5ZM3 1.66675C2.26367 1.66675 1.66663 2.26367 1.66663 3C1.66663 3.73645 2.26367 4.33337 3 4.33337C3.73633 4.33337 4.33337 3.73645 4.33337 3C4.33337 2.26367 3.73633 1.66675 3 1.66675Z" fill={`${watch('linkedIn') ? "#000" : "#505050"}`} stroke={`${watch('linkedIn') ? "#000" : "#505050"}`} stroke-width="0.5" />
              </g>
              <defs>
                <clipPath id="clip0_5984_38509">
                  <rect width="18" height="18" fill="white" />
                </clipPath>
              </defs>
            </svg>}
          />
        </div>

        <div className="w100 pr">
          <InputField
            id="title"
            placeholder="Title"
            key={`title`}
            type="text"
            {...register('title')}
            error={errors.title}
            prefixIcon={<WorkOutline className='pa c2' style={{ left: '15px', marginTop: '0.8rem', color: `${watch('title') ? '#000' : '#505050'}` }} />}
          />
        </div>


      </form>

      {/* Changes Password Component */}
      <ChangePassword handleSave={handleSave} userData={userData} />

      <EnterCode
        modalType={"verify"}
        authMethod={verify}
        codeEntered={verifySMSCode}
        smsEnabled={smsEnabled}
        twoFaEnabled={twoFaEnabled}
        emailEnabled={emailEnabled}
        resendCode={handleVerification}
        loading={loading}
        buttonText={buttonText}
        isButtonDisabled={isButtonDisabled}
        counter={counter}
      />
    </div>
  );
});

export default ProfileForm;

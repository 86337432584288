import { motion } from "framer-motion";
import { useEffect } from "react";
import { PAGE_TRANSITION } from "../constants/animations";
import { LOGGED_IN_ROUTES } from "../constants/routes";
import { useLocation } from "react-router-dom";

export default function AnimatedPage({ children, isHomePage }) {
	const location = useLocation();
	const isLoggedInPage = LOGGED_IN_ROUTES.find(r => r.split("/").every((s, i) => s === location.pathname.split("/")[i] || s[0] === ":"));

	useEffect(() => {
		scrollTo({ top: 0, behavior: "smooth" });

		const handleResize = () => {
			const header = document.querySelector("header");
			if (!header) {
				console.error("Header element not found");
				return;
			}

			const offerBanner = document.getElementById("offerBanner");
			const offerBannerHeight = offerBanner ? offerBanner.offsetHeight : 0;
			const footer = document.querySelector("footer");
			const root = document.getElementById("root");

			if (isLoggedInPage && header && header.style) {
				root.style.marginTop = "";
				root.style.marginLeft = "4rem";
			} else {
				header.style.paddingTop = offerBannerHeight + "px";
				root.style.marginLeft = "";

				if (!location.pathname.includes("login")) {
					root.style.marginTop = header.offsetHeight + "px";
				} else {
					root.style.marginTop = "0px";
				}
			}

			if (footer?.offsetHeight > innerHeight) {
				root.style.marginBottom = "";
				footer?.classList.remove("pf");
			} else {
				root.style.marginBottom = footer?.offsetHeight + "px";
				footer?.classList.add("pf");
			}

			const hero = document.getElementById("hero");
			if (hero) {
				hero.style.minHeight =
					(innerHeight - (header?.offsetHeight || 0)) + "px";
			}
		};

		window.onresize = handleResize;
		handleResize(); // Call initially
	}, [isLoggedInPage, location.pathname]);

	const motionClass = isLoggedInPage ? "bg1 mih100vh poppins-font-style *" : !isHomePage ? "bg1 bw0px bbw5px bss bo3" : "bg1";
	return (
		<motion.div className={motionClass} variants={PAGE_TRANSITION} transition={PAGE_TRANSITION.transition} initial="animate" animate="animate" exit="exit">
			{children}
		</motion.div>
	);
}

export default function HeadingSubheadingContent({
  heading,
  subheading,
  paragraph,
  list,
  wrapperClass,
  listClass,
  id,
}) {

  return (
    <div id={id} className={wrapperClass + " df fdc jcc aic"}>
      {/* <h1 className="w100 text-gradient fs1-75rem lh2">{heading}</h1>
      <h2 className="w100 fs1-75rem lh2 c4">{subheading}</h2> */}
      <h1 className="w100 c15 fs1-75rem lh2 fw600">{heading}</h1>
      <h2 className="w100 fs1-5rem lh2 c4 fw500">{subheading}</h2>
      {paragraph && <p className="mt0-5rem fs0-75rem c3 maw300px tac fsi">{paragraph}</p>}
      {list && list.map((item, idx) => (
        <div key={idx} className={listClass + " mt0-5rem fs1rem ass df w100 aifs"}>
          <svg
            className="f9 w2rem h2rem mr1rem mr0-lg ml1rem-lg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path
              className="fa-primary"
              d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
            />
          </svg>
          <span className="w100">{item}</span>
        </div>
      ))}
    </div>
  );
}
